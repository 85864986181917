<template>
  <div id="logout">{" "}</div>
</template>
<script>
import Swal from 'sweetalert2';

const getLang = localStorage.getItem("setLang");

export default {
  name: "Logout",
  mounted(){
    localStorage.clear("userData");
    localStorage.clear("userData");
    window.location.href = "/";
    if(getLang == 'indo'){
      Swal.fire("Berhasil Keluar!", "Anda berhasil Logout.", "success");
    }else{
      Swal.fire("Success Logout!", "You have successfully logged out.", "success");
    }
    localStorage.setItem("isLogin",false);

    localStorage.clear(); // clear localStorage
    sessionStorage.clear(); // clear sessionStorage
    location.reload(true); // force reload from server
  },

};
</script>