<template>
  <div id="partnership" class="page">
    <!-- navbar -->
    <Navbar />
    <div class="container">
      <h1
        class="txt-title fs-md-3 fs-4 text-center mb-4"
        v-if="getLang == 'indo'"
      >
        Kemitraan
      </h1>
      <h1
        class="txt-title fs-md-3 fs-4 text-center mb-4"
        v-if="getLang == 'uk'"
      >
        Partnership
      </h1>

      <div class="row">
        <!-- partnership section -->
        <div
          class="
            col-md-4
            content-partnership
            p-md-5 p-4
            mx-auto
            mb-5
            text-center
          "
        >
          <div class="ib-name mb-3">
            <p class="mb-0 fs-md-6 fs-7" v-if="getLang == 'indo'">Nama IB</p>
            <p class="mb-0 fs-md-6 fs-7" v-if="getLang == 'uk'">IB Name</p>
            <p class="txt-bold fs-md-5 fs-6 m-0">
              {{ name }}
            </p>
            <!-- <p
              class="txt-bold fs-md-5 fs-6 m-0"
              v-if="rebateAccount.length != 0"
            >
              {{ rebateAccount.login_id }}
            </p> -->
          </div>
          <div class="referral-link">
            <p class="fs-md-6 mb-2" v-if="getLang == 'indo'">Tautan:</p>
            <p class="fs-md-6 mb-2" v-if="getLang == 'uk'">Referral Link:</p>
            <div class="d-flex justify-content-center">
              <input
                class="txt-bold fs-md-5 fs-7 m-0 ref-link w-100 px-3"
                ref="referralCode"
                readonly
                type="textarea"
                v-bind:value="BASE_URL_REFERRAL + referralCode"
              />
              <button class="ml-2 btn-copy" @click="copyText">
                <img src="/assets/img/copy-white.svg" alt="" class="copy" />
              </button>
            </div>
          </div>
        </div>

        <!-- rebates partnership -->
        <div class="col-md-7 rebates">
          <!-- <h3 class="txt-title txt-bold mb-2">
            Rebates Period
          </h3> -->
          <p v-if="getLang == 'indo'">
            *Laporan rabat Anda akan diperbarui setiap hari pada pukul 05:00 GMT +2.
            <br>
            *Laporan yang anda lihat adalah data rebate mingguan
            <br>
            *Untuk balance akun rebate silahkan di cek pada dashboard utama.
          </p>
          <p v-if="getLang == 'uk'">
            *Your rebate report would be updated every day at 05:00 GMT +2.
            <br>
            *The report you see is weekly rebate data
            <br>
            *For a rebate account balance, please check on the main dashboard.
          </p>
          <div class="input-rebates d-flex flex-wrap align-items-end" v-if="false">
            <div class="input">
              <label for="quick-pick" v-if="getLang == 'indo'">
                Pilihan Cepat:
              </label>
              <label for="quick-pick" v-if="getLang == 'uk'">
                Quick Pick:
              </label>
              <div class="select-custom">
                <select
                  v-model="quickPick"
                  name="quick-pick"
                  id="quick-pick"
                  class="w-100"
                  style="background: none"
                  @change="QuickPick"
                >
                  <option
                    value=""
                    disabled
                    selected
                    hidden
                    v-if="getLang == 'indo'"
                  >
                    Pilih Opsi Anda
                  </option>
                  <option
                    value=""
                    disabled
                    selected
                    hidden
                    v-if="getLang == 'uk'"
                  >
                    Select your option
                  </option>
                  <option value="last-week" v-if="getLang == 'indo'">
                    Mingggu Terakhir
                  </option>
                  <option value="last-week" v-if="getLang == 'uk'">
                    Last Week
                  </option>
                  <option value="last-month" v-if="getLang == 'indo'">
                    Bulan Terakhir
                  </option>
                  <option value="last-month" v-if="getLang == 'uk'">
                    Last Month
                  </option>
                  <option value="last-year" v-if="getLang == 'indo'">
                    Tahun Terakhir
                  </option>
                  <option value="last-year" v-if="getLang == 'uk'">
                    Last Year
                  </option>
                </select>
                <span></span>
              </div>
            </div>
            <div class="dates d-flex flex-wrap" style="gap: 24px">
              <!-- start date -->
              <div class="mr-0 date col-md-5 p-0">
                <label for="start-date" v-if="getLang == 'indo'"
                  >Mulai Tanggal:</label
                >
                <label for="start-date" v-if="getLang == 'uk'"
                  >Start Date:</label
                >
                <div class="d-flex align-items-center">
                  <input
                    v-if="selectDate"
                    type="text"
                    name="start-date"
                    id="start-date"
                    disabled
                    :placeholder="formatTime(start_date)"
                  />
                  <input
                    v-if="!selectDate"
                    type="date"
                    name="start-date"
                    id="start-date"
                    @change="onChangeStartDate"
                  />
                  <button @click="onresetselectDate" class="reset-button">
                    <img src="/assets/img/trash.svg" alt="reset icon" />
                  </button>
                </div>
              </div>
              <!-- end date -->
              <div class="mr-0 mr-md-5 date col-md-5 p-0">
                <label for="end-date" v-if="getLang == 'indo'"
                  >Tanggal Akhir:</label
                >
                <label for="end-date" v-if="getLang == 'uk'">End Date:</label>
                <div class="d-flex align-items-center">
                  <input
                    v-if="selectDate"
                    type="text"
                    name="end-date"
                    id="end-date"
                    disabled
                    :placeholder="formatTime(end_date)"
                  />
                  <input
                    v-if="!selectDate"
                    type="date"
                    name="end-date"
                    id="end-date"
                    @change="onChangeEndDate"
                  />
                  <button @click="onresetselectDate" class="reset-button">
                    <img src="/assets/img/trash.svg" alt="reset icon" />
                  </button>
                </div>
              </div>
            </div>
            <button
              @click="selectRebates"
              class="btn btn-blue text-white align-self-end"
            >
              Search
            </button>
          </div>
          <div class="container-content-rebate">
            <div class="d-flex summary">
              <div class="box">
                <p class="m-0 fs-md-4 fs-5 txt-bold">{{ totalUser }}</p>
                <p class="mb-0" v-if="getLang == 'indo'">Jumlah User</p>
                <p class="mb-0" v-if="getLang == 'uk'">Total User</p>
              </div>
              <div class="box">
                <p class="m-0 fs-md-4 fs-5 txt-bold">{{ Math.round(totalVolume * 100) /100 }} Lot</p>
                <p class="mb-0" v-if="getLang == 'indo'">Jumlah Volume</p>
                <p class="mb-0" v-if="getLang == 'uk'">Total Volume</p>
              </div>
              <div class="box">
                <p class="m-0 fs-md-4 fs-5 txt-bold">$ {{ Math.round(totalRebate * 100) / 100 }}</p>
                <p class="mb-0" v-if="getLang == 'indo'">Jumlah Rebate</p>
                <p class="mb-0" v-if="getLang == 'uk'">Total Rebate</p>
              </div>
            </div>
          </div>
          <!-- user -->
          <div
            class="box mt-4 container-content-rebate"
            v-if="dataRebates.length > 0"
          >
            <!-- itemm -->
            <div
              class="container-content-rebate"
              v-for="item in dataRebates"
              :key="item.uid"
            >
              <div class="row">
                <div
                  class="
                    col-12 col-md-6
                    d-flex
                    flex-column
                    justify-content-center
                  "
                >
                  <p class="" v-if="getLang == 'indo'">nama user</p>
                  <p class="" v-if="getLang == 'uk'">Username</p>
                  <p class="txt-bold fs-5">{{ item.user.name }}</p>
                  <!-- <a class="btn btn-blue" :href="'./partnership/'+item.user.email">Detail</a> -->
                </div>
                <div class="box col-12 col-md-6 d-flex justify-content-between mt-4">
                  <!-- <div
                    class="
                      text-center
                      d-flex
                      flex-column
                      justify-content-center
                    "
                  >
                    <p v-if="getLang == 'indo'">ID Msuk</p>
                    <p v-if="getLang == 'uk'">Login ID</p>
                    <p class="txt-bold fs-5">{{ item.rebate_account }}</p>
                  </div> -->
                  <div
                    class="
                      text-center
                      d-flex
                      flex-column
                      justify-content-center
                    "
                  >
                    <p v-if="getLang == 'indo'">Volume</p>
                    <p v-if="getLang == 'uk'">Volume</p>
                    <p class="txt-bold fs-5">{{ Math.round(item.volume * 100) / 100 }}</p>
                  </div>
                  <div
                    class="
                      text-center
                      d-flex
                      flex-column
                      justify-content-center
                    "
                  >
                    <p v-if="getLang == 'indo'">Rebate</p>
                    <p v-if="getLang == 'uk'">Rebate</p>
                    <p class="txt-bold fs-5">$ {{ Math.round(item.rebate * 100) / 100 }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- itemm -->
          </div>
          <div
            class="box mt-4 container-content-rebate"
            v-if="dataRebates.length == 0"
          >
            <!-- itemm -->
            <p class="text-center" v-if="getLang == 'indo'">
              Data tidak tersedia.
            </p>
            <p class="text-center" v-if="getLang == 'uk'">
              Data not available.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import Notiflix, {  } from 'notiflix';

const userData = JSON.parse(localStorage.getItem("userData"));

// const url_commision = process.env.VUE_APP_API_URL_COMMISION;
const url_commision = process.env.VUE_APP_FOREXCM;
const url = process.env.VUE_APP_API_URL;
const rebate_token = process.env.VUE_APP_API_URL_REBATE;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "partnership",
  components: {
    Navbar,
  },
  data() {
    return {
      BASE_URL_REFERRAL: "https://socrateslegacy.com/register.php?referral=",
      name: userData.user.name,
      referralCode: userData.user.referral_code,
      start_date: "",
      end_date: "",
      quickPick: "",
      uid: userData.user._id,
      userRole: userData.user.role,
      timeNow: "",
      lastWeek: "",
      lastmonth: "",
      lastyear: "",
      dataRebates: [],
      search: false,
      totalUser: 0,
      totalVolume: 0,
      totalRebate: 0,
      uidsebelumnya: "",
      selectDate: false,
      rebateAccount: "",
      getLang: localStorage.getItem("setLang"),
    };
  },
  created() {
    localStorage.setItem("pageNow", "partnership");
  },
  mounted() {
    window.scrollTo(0, 0);
    if (userData.user.role === "USER_TRADER" || userData.user.role === "USER_SUB_TRADER") {
      window.location.href = "/dashboard";
    }
    // time now
    const getTimeNow = new Date().getTime();
    const timestampNow = new Date(getTimeNow);
    this.timeNow = timestampNow.toISOString();
    // last week
    const getLastweek = getTimeNow - 604800000;
    const timestamplastweek = new Date(getLastweek);
    this.lastWeek = timestamplastweek.toISOString();
    // last month
    const getLastmonth = getTimeNow - 2629743000;
    const timestamplastmonth = new Date(getLastmonth);
    this.lastmonth = timestamplastmonth.toISOString();
    // last year
    const getLastyear = getTimeNow - 31556926000;
    const timestamplastyear = new Date(getLastyear);
    this.lastyear = timestamplastyear.toISOString();
    this.end_date = this.timeNow;
    const loadSatrtdate = getTimeNow - 315569260000;
    const loadtimestamp = new Date(loadSatrtdate);
    this.start_date = loadtimestamp.toISOString();

    let self = this;
    // old rebates
    // var config = {
    //   method: "get",
    //   url: `${url_commision}/v1/rebates?uid=${this.uid}&from=${this.start_date}&to=${this.end_date}&level[min]=1&level[max]=3`,
    //   headers: {
    //     "content-type": "application/json",
    //     "rebate-x-app-token": rebate_token,
    //     "monolith-x-app-token": token,
    //     "x-user-token": userData.token,
    //   },
    // };
    // axios(config)
    //   .then(function (res) {
    //     const respon = res.data.data;
    //     let _totalVolume = 0;
    //     let _totalRebate = 0;
    //     console.log("respon", res);
    //     if (respon != null) {
    //       console.log("respon data", respon);
    //       self.dataRebates = respon;
    //       let uid_sebelumnya = respon.map((item) => {
    //         return item.uid;
    //       });
    //       const findUid = [...new Set(uid_sebelumnya)];
    //       console.log("findUid", findUid);
    //       respon.forEach((item) => {
    //         _totalRebate += item.rebate;
    //         _totalVolume += item.volume;
    //       });

    //       self.totalUser = findUid.length;
    //       self.totalVolume = _totalVolume;
    //       self.totalRebate = _totalRebate;
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error.message);
    //   });

    // new rebates

    // graphql vulture
    // var config = {
    //   method: "post",
    //   url: `${url_commision}/api/graphql`,
    //   headers: {
    //     "content-type": "application/json",
    //     "x-app-token": token,
    //     // "x-user-token": userData.token,
    //   },
    //   data: JSON.stringify({query:`{
    //       RebateUser (filter:{user:{email:"${userData.user.email}"}}) {
    //         user {
    //           name
    //           email
    //         }
    //         rebate_account
    //         total_rebate
    //         total_volume
    //         levels {
    //           level
    //           total_volume
    //           total_rebate
    //           requirement_desc
    //           unlocked
    //         }
    //         childrens {
    //           user {
    //             name
    //             email
    //           }
              
    //           account
    //           level
    //           volume
    //           rebate
    //           commission
    //         }
    //       }
    //   }`})
    // };

    //forexcm
    var config = {
      method: "post",
      url: `${url_commision}/v1/graphql`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        // "x-user-token": userData.token,
      },
      data: JSON.stringify({query:`{
            RebateUser(input:{
              params: {
              date_from: "2022-04-11"
              date_to: "2022-04-15"
              email: "${userData.user.email}"
            }
          }) {
              user {
                name
                email
            }
            rebate_account
            total_rebate
            total_volume
            levels {
              level
              total_volume
              total_rebate
              requirement_desc
              unlocked
            }
            childrens {
                user {
                  name
                  email
              }

              account
              level
              volume
              rebate
              commission
            }
          }
        }`})
    };

  
    Notiflix.Loading.circle()
    axios(config)
      .then(function (res) {
        // console.log("partnership",res)
        const respon = res.data.data.RebateUser;
        let _totalVolume = 0;
        let _totalRebate = 0;
        console.log("respon", res);
        if (respon != null) {
          console.log("respon data", respon);
          self.dataRebates = respon[0].childrens;
          let uid_sebelumnya = respon[0].childrens.map((item) => {
            return item.user.email;
          });
          const findUid = [...new Set(uid_sebelumnya)];
          console.log("findUid", findUid);
          respon.forEach((item) => {
            _totalRebate += item.total_rebate;
            _totalVolume += item.total_volume;
          });

          self.totalUser = findUid.length;
          self.totalVolume = _totalVolume;
          self.totalRebate = _totalRebate;
        }
      })
      .catch(function (error) {
        console.log(error.message);
      })
      .then(function(){
        Notiflix.Loading.remove()
      })

    var configAccount = {
      method: "get",
      url: `${url}/me/accounts`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };

    axios(configAccount)
      .then(function (res) {
        let obj = res.data.data.account.reverse();
        self.rebateAccount = obj.forEach((item) => {
          if (
            item.type == "REBATE" &&
            item.spec.name == "ACCOUNT-REBATE" &&
            item.status != "PENDING"
          ) {
            // if (item.type == "REAL" && item.status != 'PENDING') {
            console.log("item", item);
            return item;
          }
          console.log("obj", obj);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    onresetselectDate() {
      this.selectDate = false;
    },
    formatTime(time) {
      const gettimestamp = new Date(time).getTime();
      const convertTime = new Date(gettimestamp).toLocaleDateString();
      return convertTime;
    },
    QuickPick(e) {
      this.selectDate = true;
      const value = e.target.value;
      console.log(value);
      console.log(this.quickPick);
      this.end_date = this.timeNow;
      if (this.quickPick == "last-week") {
        this.start_date = this.lastWeek;
      } else if (this.quickPick == "last-month") {
        this.start_date = this.lastmonth;
      } else if (this.quickPick == "last-year") {
        this.start_date = this.lastyear;
      }
    },
    onChangeEndDate(e) {
      const res = e.target.value;
      this.end_date = new Date(res).toISOString();
    },
    onChangeStartDate(e) {
      const res = e.target.value;
      this.start_date = new Date(res).toISOString();
    },
    selectRebates() {
      this.search = true;
      let self = this;
      var config = {
        method: "get",
        url: `${url_commision}/v1/rebates?uid=${this.uid}&from=${this.start_date}&to=${this.end_date}&level[min]=1&level[max]=3`,
        headers: {
          "content-type": "application/json",
          "rebate-x-app-token": rebate_token,
          "monolith-x-app-token": token,
          "x-user-token": userData.token,
        },
      };
      axios(config)
        .then(function (res) {
          const respon = res.data.data;
          let _totalVolume = 0;
          let _totalRebate = 0;
          console.log("respon", res);
          if (respon != null) {
            console.log("respon data", respon);
            self.dataRebates = respon;
            let uid_sebelumnya = respon.map((item) => {
              return item.uid;
            });
            const findUid = [...new Set(uid_sebelumnya)];
            console.log("findUid", findUid);
            respon.forEach((item) => {
              _totalRebate += item.rebate;
              _totalVolume += item.volume;
            });

            self.totalUser = findUid.length;
            self.totalVolume = _totalVolume;
            self.totalRebate = _totalRebate;
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    },
    copyText() {
      var copyText = this.$refs.referralCode;
      copyText.select();
      document.execCommand("copy");
      Swal.fire({
        icon: "success",
        title: "Link Berhasil Disalin",
        showConfirmButton: false,
        timer: 2000,
      });
    },
  },
};
</script>