<template>
  <div id="promotion" class="page">
    <!-- navbar -->
    <Navbar />
    <div class="d-flex justify-content-center align-items-center">
        <div class="container text-center">
          <img
            src="/assets/img/logo.png"
            alt=""
            class="mb--lg-5 mb-4 logo-login"
          />
          <img src="/assets/img/EIDOS_WEB_UPDATE_2.jpg" alt="" class="w-100">
          <!-- <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4" v-if="getLang == 'indo'">
            Promosi
          </h1>
          <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4" v-if="getLang == 'uk'">
            Promotion
          </h1>
          <p class="fs-sm-5 fs-6" v-if="getLang == 'indo'">Nantikan Event-Event Kami.</p>
          <p class="fs-sm-5 fs-6" v-if="getLang == 'uk'">Look forward to our events.</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
export default {
  components: {
    Navbar,
  },
  data(){
    return {
      getLang: localStorage.getItem("setLang"),
    }
  },
  created(){
    localStorage.setItem("pageNow", "promotion");
  },
};
</script>