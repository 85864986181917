<template>
  <tr>
    <td class="fs-7">
      {{ item.account.login_id ? item.account.login_id : "-" }}
    </td>
    <td class="fs-7">{{ item.type }}</td>
    <td class="fs-7">{{ item.status }}</td>
    <td class="fs-7">{{ item.method }}</td>
    <td class="fs-7">${{ item.amount }}</td>
    <td class="fs-7">
      {{ item.comment ? item.comment : "-" }}
    </td>
    <td class="fs-7" v-if="item.type == 'DEPOSIT'" style="min-width: 100px">
      {{
        item.status == "PENDING" ? setTimerPayment(item.created_at) : "Expired"
      }}
    </td>
    <td class="fs-7" v-if="item.type == 'WITHDRAW'">-</td>
    <td class="m-o justify-content-center fs-7">
      <button
        v-if="
          item.type == 'DEPOSIT' &&
          setTimerPayment(item.created_at) != 'Expired' &&
          item.status == 'PENDING' &&
          getLang == 'indo'
        "
        class="btn btn-blue"
        @click="onVerificationPayment(item)"
      >
        Verifikasi
      </button>
      <button
        v-if="
          item.type == 'DEPOSIT' &&
          setTimerPayment(item.created_at) != 'Expired' &&
          item.status == 'PENDING' &&
          getLang == 'uk'
        "
        class="btn btn-blue"
        @click="onVerificationPayment(item)"
      >
        Verification
      </button>
      <button
        v-if="
          item.type == 'DEPOSIT' &&
          item.status == 'PENDING' &&
          setTimerPayment(item.created_at) == 'Expired'
        "
        class="btn-expired"
      >
        Expired
      </button>
      <!-- cancel deposit -->
      <button
        v-if="item.type == 'DEPOSIT' && item.status == 'PENDING'"
        @click="cancelDeposit(item)"
        class="btn btn-sm btn-danger text-white fs-8 mt-1"
      >
        Cancel
      </button>
      <div v-if="item.status == 'SUCCESS'" class="btn-success">Success</div>
      <div
        v-if="item.status == 'FAILED' || item.status == 'REJECTED'"
        class="btn-failed"
      >
        Rejected
      </div>
    </td>
  </tr>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import Notiflix from "notiflix";
const userData = JSON.parse(localStorage.getItem("userData"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;
export default {
  props: ["item"],
  data() {
    return {
      historyPayment: [],
      historyPaymentShow: [],
      timeUpdate: "",
      timeVerication: "",
      timeNow: "",
      getLang: localStorage.getItem("setLang"),
    };
  },
  created() {
    this.setTimerPayment();
    this.timeUpdate = setInterval(this.setTimerPayment, 1000);
  },
  methods: {
    onVerificationPayment(item) {
      localStorage.setItem("historyPaymentID", JSON.stringify(item));
      localStorage.setItem("login_id", JSON.stringify(item.account.login_id));
      window.location.href = "/verify-time";
    },
    setTimerPayment: function (item) {
      // waktu dibuat
      const waktuDibuat = new Date(item).getTime() + 10800000;

      // waktu sekarang
      this.timeNow = new Date().getTime();
      const selisih = waktuDibuat - this.timeNow;
      var jam = Math.floor(
        (selisih % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var menit = Math.floor((selisih % (1000 * 60 * 60)) / (1000 * 60));
      var detik = Math.floor((selisih % (1000 * 60)) / 1000);

      if (selisih > 60000) {
        return `0${jam} : ${menit} : ${detik}`;
      } else {
        return "Expired";
      }
    },
    cancelDeposit: function () {
      Swal.fire({
        title: `Do you want to cancel the deposit?`,
        showCancelButton: true,
        confirmButtonColor: `#3085d6`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Notiflix.Loading.circle();
          axios
            .put(
              url + `/payment/` + this.item._id + `/status`,
              {
                status: `CANCEL`,
                comment: `Canceled by user`,
              },
              {
                headers: {
                  "x-app-token": token,
                  "x-user-token": userData.token,
                },
              }
            )
            .then(function () {
              Swal.fire(
                `Success!`,
                `Your deposit has been canceled.`,
                `success`
              ).then(() => {
                window.location.reload();
              });
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire(`Error!`, error.response.data.message);
            })
            .then(function () {
              Notiflix.Loading.remove();
            });
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timeUpdate);
  },
};
</script>