export const bankOptions = [
  {
    text: "BANK BRI",
    value: "BANK BRI",
    code: "002",
  },
  {
    text: "BANK EKSPOR INDONESIA",
    value: "BANK EKSPOR INDONESIA",
    code: "003",
  },
  {
    text: "BANK MANDIRI",
    value: "BANK MANDIRI",
    code: "008",
  },
  {
    text: "BANK BNI",
    value: "BANK BNI",
    code: "009",
  },
  {
    text: "BANK BNI SYARIAH",
    value: "BANK BNI SYARIAH",
    code: "427",
  },
  {
    text: "BANK DANAMON",
    value: "BANK DANAMON",
    code: "011",
  },
  {
    text: "PERMATA BANK",
    value: "PERMATA BANK",
    code: "013",
  },
  {
    text: "BANK BCA",
    value: "BANK BCA",
    code: "014",
  },
  {
    text: "BANK BII",
    value: "BANK BII",
    code: "016",
  },
  {
    text: "BANK PANIN",
    value: "BANK PANIN",
    code: "019",
  },
  {
    text: "BANK ARTA NIAGA KENCANA",
    value: "BANK ARTA NIAGA KENCANA",
    code: "020",
  },
  {
    text: "BANK NIAGA",
    value: "BANK NIAGA",
    code: "022",
  },
  {
    text: "BANK BUANA IND",
    value: "BANK BUANA IND",
    code: "023",
  },
  {
    text: "BANK LIPPO",
    value: "BANK LIPPO",
    code: "026",
  },
  {
    text: "BANK NISP",
    value: "BANK NISP",
    code: "028",
  },
  {
    text: "AMERICAN EXPRESS BANK LTD",
    value: "AMERICAN EXPRESS BANK LTD",
    code: "030",
  },
  {
    text: "CITIBANK N.A.",
    value: "CITIBANK N.A.",
    code: "031",
  },
  {
    text: "JP. MORGAN CHASE BANK, N.A.",
    value: "JP. MORGAN CHASE BANK, N.A.",
    code: "032",
  },
  {
    text: "BANK OF AMERICA, N.A",
    value: "BANK OF AMERICA, N.A",
    code: "033",
  },
  {
    text: "ING INDONESIA BANK",
    value: "ING INDONESIA BANK",
    code: "034",
  },
  {
    text: "BANK MULTICOR TBK.",
    value: "BANK MULTICOR TBK.",
    code: "036",
  },
  {
    text: "BANK ARTHA GRAHA",
    value: "BANK ARTHA GRAHA",
    code: "037",
  },
  {
    text: "BANK CREDIT AGRICOLE INDOSUEZ",
    value: "BANK CREDIT AGRICOLE INDOSUEZ",
    code: "039",
  },
  {
    text: "THE BANGKOK BANK COMP. LTD",
    value: "THE BANGKOK BANK COMP. LTD",
    code: "040",
  },
  {
    text: "THE HONGKONG & SHANGHAI B.C.",
    value: "THE HONGKONG & SHANGHAI B.C.",
    code: "041",
  },
  {
    text: "THE BANK OF TOKYO MITSUBISHI UFJ LTD",
    value: "THE BANK OF TOKYO MITSUBISHI UFJ LTD",
    code: "042",
  },
  {
    text: "BANK SUMITOMO MITSUI INDONESIA",
    value: "BANK SUMITOMO MITSUI INDONESIA",
    code: "045",
  },
  {
    text: "BANK DBS INDONESIA",
    value: "BANK DBS INDONESIA",
    code: "046",
  },
  {
    text: "BANK RESONA PERDANIA",
    value: "BANK RESONA PERDANIA",
    code: "047",
  },
  {
    text: "BANK MIZUHO INDONESIA",
    value: "BANK MIZUHO INDONESIA",
    code: "048",
  },
  {
    text: "STANDARD CHARTERED BANK",
    value: "STANDARD CHARTERED BANK",
    code: "050",
  },
  {
    text: "BANK ABN AMRO",
    value: "BANK ABN AMRO",
    code: "052",
  },
  {
    text: "BANK KEPPEL TATLEE BUANA",
    value: "BANK KEPPEL TATLEE BUANA",
    code: "053",
  },
  {
    text: "BANK CAPITAL INDONESIA, TBK.",
    value: "BANK CAPITAL INDONESIA, TBK.",
    code: "054",
  },
  {
    text: "BANK BNP PARIBAS INDONESIA",
    value: "BANK BNP PARIBAS INDONESIA",
    code: "057",
  },
  {
    text: "BANK UOB INDONESIA",
    value: "BANK UOB INDONESIA",
    code: "058",
  },
  {
    text: "KOREA EXCHANGE BANK DANAMON",
    value: "KOREA EXCHANGE BANK DANAMON",
    code: "059",
  },
  {
    text: "RABOBANK INTERNASIONAL INDONESIA",
    value: "RABOBANK INTERNASIONAL INDONESIA",
    code: "060",
  },
  {
    text: "ANZ PANIN BANK",
    value: "ANZ PANIN BANK",
    code: "061",
  },
  {
    text: "DEUTSCHE BANK AG.",
    value: "DEUTSCHE BANK AG.",
    code: "067",
  },
  {
    text: "BANK WOORI INDONESIA",
    value: "BANK WOORI INDONESIA",
    code: "068",
  },
  {
    text: "BANK OF CHINA LIMITED",
    value: "BANK OF CHINA LIMITED",
    code: "069",
  },
  {
    text: "BANK BUMI ARTA",
    value: "BANK BUMI ARTA",
    code: "076",
  },
  {
    text: "BANK EKONOMI",
    value: "BANK EKONOMI",
    code: "087",
  },
  {
    text: "BANK ANTARDAERAH",
    value: "BANK ANTARDAERAH",
    code: "088",
  },
  {
    text: "BANK HAGA",
    value: "BANK HAGA",
    code: "089",
  },
  {
    text: "BANK IFI",
    value: "BANK IFI",
    code: "093",
  },
  {
    text: "BANK CENTURY, TBK.",
    value: "BANK CENTURY, TBK.",
    code: "095",
  },
  {
    text: "BANK MAYAPADA",
    value: "BANK MAYAPADA",
    code: "097",
  },
  {
    text: "BANK JABAR",
    value: "BANK JABAR",
    code: "110",
  },
  {
    text: "BANK DKI",
    value: "BANK DKI",
    code: "111",
  },
  {
    text: "BPD DIY",
    value: "BPD DIY",
    code: "112",
  },
  {
    text: "BANK JATENG",
    value: "BANK JATENG",
    code: "113",
  },
  {
    text: "BANK JATIM",
    value: "BANK JATIM",
    code: "114",
  },
  {
    text: "BPD JAMBI",
    value: "BPD JAMBI",
    code: "115",
  },
  {
    text: "BPD ACEH",
    value: "BPD ACEH",
    code: "116",
  },
  {
    text: "BANK SUMUT",
    value: "BANK SUMUT",
    code: "117",
  },
  {
    text: "BANK NAGARI",
    value: "BANK NAGARI",
    code: "118",
  },
  {
    text: "BANK RIAU",
    value: "BANK RIAU",
    code: "119",
  },
  {
    text: "BANK SUMSEL",
    value: "BANK SUMSEL",
    code: "120",
  },
  {
    text: "BANK LAMPUNG",
    value: "BANK LAMPUNG",
    code: "121",
  },
  {
    text: "BPD KALSEL",
    value: "BPD KALSEL",
    code: "122",
  },
  {
    text: "BPD KALIMANTAN BARAT",
    value: "BPD KALIMANTAN BARAT",
    code: "123",
  },
  {
    text: "BPD KALTIM",
    value: "BPD KALTIM",
    code: "124",
  },
  {
    text: "BPD KALTENG",
    value: "BPD KALTENG",
    code: "125",
  },
  {
    text: "BPD SULSEL",
    value: "BPD SULSEL",
    code: "126",
  },
  {
    text: "BANK SULUT",
    value: "BANK SULUT",
    code: "127",
  },
  {
    text: "BPD NTB",
    value: "BPD NTB",
    code: "128",
  },
  {
    text: "BPD BALI",
    value: "BPD BALI",
    code: "129",
  },
  {
    text: "BANK NTT",
    value: "BANK NTT",
    code: "130",
  },
  {
    text: "BANK MALUKU",
    value: "BANK MALUKU",
    code: "131",
  },
  {
    text: "BPD PAPUA",
    value: "BPD PAPUA",
    code: "132",
  },
  {
    text: "BANK BENGKULU",
    value: "BANK BENGKULU",
    code: "133",
  },
  {
    text: "BPD SULAWESI TENGAH",
    value: "BPD SULAWESI TENGAH",
    code: "134",
  },
  {
    text: "BANK SULTRA",
    value: "BANK SULTRA",
    code: "135",
  },
  {
    text: "BANK NUSANTARA PARAHYANGAN",
    value: "BANK NUSANTARA PARAHYANGAN",
    code: "145",
  },
  {
    text: "BANK SWADESI",
    value: "BANK SWADESI",
    code: "146",
  },
  {
    text: "BANK MUAMALAT",
    value: "BANK MUAMALAT",
    code: "147",
  },
  {
    text: "BANK MESTIKA",
    value: "BANK MESTIKA",
    code: "151",
  },
  {
    text: "BANK METRO EXPRESS",
    value: "BANK METRO EXPRESS",
    code: "152",
  },
  {
    text: "BANK SHINTA INDONESIA",
    value: "BANK SHINTA INDONESIA",
    code: "153",
  },
  {
    text: "BANK MASPION",
    value: "BANK MASPION",
    code: "157",
  },
  {
    text: "BANK HAGAKITA",
    value: "BANK HAGAKITA",
    code: "159",
  },
  {
    text: "BANK GANESHA",
    value: "BANK GANESHA",
    code: "161",
  },
  {
    text: "BANK WINDU KENTJANA",
    value: "BANK WINDU KENTJANA",
    code: "162",
  },
  {
    text: "HALIM INDONESIA BANK",
    value: "HALIM INDONESIA BANK",
    code: "164",
  },
  {
    text: "BANK HARMONI INTERNATIONAL",
    value: "BANK HARMONI INTERNATIONAL",
    code: "166",
  },
  {
    text: "BANK KESAWAN",
    value: "BANK KESAWAN",
    code: "167",
  },
  {
    text: "BANK TABUNGAN NEGARA (PERSERO)",
    value: "BANK TABUNGAN NEGARA (PERSERO)",
    code: "200",
  },
  {
    text: "BANK HIMPUNAN SAUDARA 1906, TBK .",
    value: "BANK HIMPUNAN SAUDARA 1906, TBK .",
    code: "212",
  },
  {
    text: "BANK TABUNGAN PENSIUNAN NASIONAL",
    value: "BANK TABUNGAN PENSIUNAN NASIONAL",
    code: "213",
  },
  {
    text: "BANK SWAGUNA",
    value: "BANK SWAGUNA",
    code: "405",
  },
  {
    text: "BANK JASA ARTA",
    value: "BANK JASA ARTA",
    code: "422",
  },
  {
    text: "BANK MEGA",
    value: "BANK MEGA",
    code: "426",
  },
  {
    text: "BANK BUKOPIN",
    value: "BANK BUKOPIN",
    code: "441",
  },
  {
    text: "BANK SYARIAH MANDIRI",
    value: "BANK SYARIAH MANDIRI",
    code: "451",
  },
  {
    text: "BANK BISNIS INTERNASIONAL",
    value: "BANK BISNIS INTERNASIONAL",
    code: "459",
  },
  {
    text: "BANK SRI PARTHA",
    value: "BANK SRI PARTHA",
    code: "466",
  },
  {
    text: "BANK JASA JAKARTA",
    value: "BANK JASA JAKARTA",
    code: "472",
  },
  {
    text: "BANK BINTANG MANUNGGAL",
    value: "BANK BINTANG MANUNGGAL",
    code: "484",
  },
  {
    text: "BANK BUMIPUTERA",
    value: "BANK BUMIPUTERA",
    code: "485",
  },
  {
    text: "BANK YUDHA BHAKTI",
    value: "BANK YUDHA BHAKTI",
    code: "490",
  },
  {
    text: "BANK MITRANIAGA",
    value: "BANK MITRANIAGA",
    code: "491",
  },
  {
    text: "BANK AGRO NIAGA",
    value: "BANK AGRO NIAGA",
    code: "494",
  },
  {
    text: "BANK INDOMONEX",
    value: "BANK INDOMONEX",
    code: "498",
  },
  {
    text: "BANK ROYAL INDONESIA",
    value: "BANK ROYAL INDONESIA",
    code: "501",
  },
  {
    text: "BANK ALFINDO",
    value: "BANK ALFINDO",
    code: "503",
  },
  {
    text: "BANK SYARIAH MEGA",
    value: "BANK SYARIAH MEGA",
    code: "506",
  },
  {
    text: "BANK INA PERDANA",
    value: "BANK INA PERDANA",
    code: "513",
  },
  {
    text: "BANK HARFA",
    value: "BANK HARFA",
    code: "517",
  },
  {
    text: "PRIMA MASTER BANK",
    value: "PRIMA MASTER BANK",
    code: "520",
  },
  {
    text: "BANK PERSYARIKATAN INDONESIA",
    value: "BANK PERSYARIKATAN INDONESIA",
    code: "521",
  },
  {
    text: "BANK AKITA",
    value: "BANK AKITA",
    code: "525",
  },
  {
    text: "LIMAN INTERNATIONAL BANK",
    value: "LIMAN INTERNATIONAL BANK",
    code: "526",
  },
  {
    text: "ANGLOMAS INTERNASIONAL BANK",
    value: "ANGLOMAS INTERNASIONAL BANK",
    code: "531",
  },
  {
    text: "BANK DIPO INTERNATIONAL",
    value: "BANK DIPO INTERNATIONAL",
    code: "523",
  },
  {
    text: "BANK KESEJAHTERAAN EKONOMI",
    value: "BANK KESEJAHTERAAN EKONOMI",
    code: "535",
  },
  {
    text: "BANK UIB",
    value: "BANK UIB",
    code: "536",
  },
  {
    text: "BANK ARTOS IND",
    value: "BANK ARTOS IND",
    code: "542",
  },
  {
    text: "BANK PURBA DANARTA",
    value: "BANK PURBA DANARTA",
    code: "547",
  },
  {
    text: "BANK MULTI ARTA SENTOSA",
    value: "BANK MULTI ARTA SENTOSA",
    code: "548",
  },
  {
    text: "BANK MAYORA",
    value: "BANK MAYORA",
    code: "553",
  },
  {
    text: "BANK INDEX SELINDO",
    value: "BANK INDEX SELINDO",
    code: "555",
  },
  {
    text: "BANK VICTORIA INTERNATIONAL",
    value: "BANK VICTORIA INTERNATIONAL",
    code: "566",
  },
  {
    text: "BANK EKSEKUTIF",
    value: "BANK EKSEKUTIF",
    code: "558",
  },
  {
    text: "CENTRATAMA NASIONAL BANK",
    value: "CENTRATAMA NASIONAL BANK",
    code: "559",
  },
  {
    text: "BANK FAMA INTERNASIONAL",
    value: "BANK FAMA INTERNASIONAL",
    code: "562",
  },
  {
    text: "BANK SINAR HARAPAN BALI",
    value: "BANK SINAR HARAPAN BALI",
    code: "564",
  },
  {
    text: "BANK HARDA",
    value: "BANK HARDA",
    code: "567",
  },
  {
    text: "BANK FINCONESIA",
    value: "BANK FINCONESIA",
    code: "945",
  },
  {
    text: "BANK MERINCORP",
    value: "BANK MERINCORP",
    code: "946",
  },
  {
    text: "BANK MAYBANK INDOCORP",
    value: "BANK MAYBANK INDOCORP",
    code: "947",
  },
  {
    text: "BANK OCBC – INDONESIA",
    value: "BANK OCBC – INDONESIA",
    code: "948",
  },
  {
    text: "BANK CHINA TRUST INDONESIA",
    value: "BANK CHINA TRUST INDONESIA",
    code: "949",
  },
  {
    text: "BANK COMMONWEALTH",
    value: "BANK COMMONWEALTH",
    code: "950",
  },
  {
    text: "BANK BJB SYARIAH",
    value: "BANK BJB SYARIAH",
    code: "425",
  },
  {
    text: "BPR KS (KARYAJATNIKA SEDAYA)",
    value: "BPR KS (KARYAJATNIKA SEDAYA)",
    code: "688",
  },
  {
    text: "INDOSAT DOMPETKU",
    value: "INDOSAT DOMPETKU",
    code: "789",
  },
  {
    text: "TELKOMSEL TCASH",
    value: "TELKOMSEL TCASH",
    code: "911",
  },
  {
    text: "LINKAJA",
    value: "LINKAJA",
    code: "911",
  },
];
