import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import 'vue-search-select/dist/VueSearchSelect.css'
// import pages
import Login from "./Login.vue";
import Signup from "./Signup.vue";
import Dashboard from "./Dashboard.vue";
import Deposit from "./Deposit.vue";
import DepositCrypto from "./DepositCrypto.vue";
import VerifyTimer from "./VerifyTimer.vue";
import Withdraw from "./Withdraw.vue";
import Partnership from "./Partnership.vue";
import joinIB from "./joinIB.vue";
import Identity from "./Identity.vue";
import VerifyBank from "./VerifyBank.vue";
import VerifyPoA from "./VerifyPoA.vue"
import Profile from "./Profile.vue";
import CreateAccount from "./CreateAccount";
import CreateAccountDemo from "./CreateAccountDemo";
import Promotion from "./Promotion";
import infoAccount from "./infoAccount";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import verifyPending from "./verifyPending";
import Logout from "./Logout";
import Activate from "./Activate";
import WithdrawCrypto from "./WithdrawCrypto";
import PartnershipDetail from "./PartnershipDetail";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.config.productionTip = false;

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.use(VueRouter);
export const router = new VueRouter({
  base: "/",
  mode: "history",
  routes: [
    { path: "/", name: "Login", component: Login },
    { path: "/login", name: "Login", component: Login },
    { path: "/signup", name: "Signup", component: Signup },
    { path: "/dashboard", name: "Dashboard", component: Dashboard },
    { path: "/deposit", name: "Deposit", component: Deposit },
    { path: "/auth/activate", name: "Activate", component: Activate },
    {
      path: "/deposit-crypto",
      name: "DepositCrypto",
      component: DepositCrypto,
    },
    { path: "/verify-time", name: "VerifyTimer", component: VerifyTimer },
    { path: "/withdraw", name: "Withdraw", component: Withdraw },
    { path: "/withdraw-crypto", name: "WithdrawCrypto", component: WithdrawCrypto },
    { path: "/partnership", name: "Partnership", component: Partnership },
    { path: "/partnership/:email", name: "PartnershipDetail", component: PartnershipDetail },
    { path: "/join-ib", name: "joinIB", component: joinIB },
    { path: "/logout", name: "Logout", component: Logout },
    { path: "/identity", name: "Identity", component: Identity },
    { path: "/verify-bank", name: "VerifyBank", component: VerifyBank },
    { path: "/verify-poa", name: "VerifyPoA", component: VerifyPoA },
    { path: "/profile", name: "Profile", component: Profile },
    {
      path: "/create-account",
      name: "CreateAccount",
      component: CreateAccount,
    },
    {
      path: "/create-account-demo",
      name: "CreateAccountDemo",
      component: CreateAccountDemo,
    },
    { path: "/promotion", name: "Promotion", component: Promotion },
    { path: "/info-account", name: "infoAccount", component: infoAccount },
    { path: "/forgot-password", name: "ForgotPassword", component: ForgotPassword },
    { path: "/reset-password/:utoken", name: "ResetPassword", component: ResetPassword },
    { path: "/verify-pending", name: "verifyPending", component: verifyPending },
  ],
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");