<template>
  <div id="verify-pending" class="d-flex justify-content-center align-items-center">
    <div class="container-login text-center">
      <img src="/assets/img/logo.png" alt="" class="mb--lg-5 mb-4 logo-login" />
      <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4" v-if="getLang == 'indo'">Verifikasi Tertunda</h1>
      <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4" v-if="getLang == 'uk'">Pending Verification</h1>
      <p class="fs-sm-5 fs-6" v-if="getLang == 'indo'">Verifikasi sedang diproses.</p>
      <p class="fs-sm-5 fs-6" v-if="getLang == 'uk'">Verification in progress.</p>
      <div class="form mb-4">
        <button type="submit" class="btn btn-blue txt-bold" @click="gotoDashboard">
          Dashboard
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "verifyPending",
  data(){
    return{
      getLang: localStorage.getItem("setLang"),
    }
  },
  methods: {
    gotoDashboard(){
      window.location.href = "/dashboard";
    }
  }
};
</script>