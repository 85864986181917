var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"info-account"}},[_c('Navbar'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"akun-mt5"},[_c('div',{staticClass:"card-info"},[_c('h1',{staticClass:"txt-title text-center fs-md-4 fs-5 mb-4"},[_vm._v(" Informasi Akun MT5 ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"d-flex justify-content-center"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('button',{staticClass:"btn btn-blue txt-bold mt-4"},[_vm._v(" Ke Dashboard ")])])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-akun-mt5"},[_c('h4',{staticClass:"txt-title fs-md-6 fs-7"},[_vm._v("ID AKUN")]),_c('p',{staticClass:"fs-md-4 fs-5 txt-bold"},[_vm._v("14566")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-akun-mt5"},[_c('div',{staticClass:"left"},[_c('h4',{staticClass:"fs-md-5 fs-6 txt-bold"},[_vm._v(" Login MetaTrader ")]),_c('p',{staticClass:"fs-5"},[_vm._v(" 2908959 ")])]),_c('button',{staticClass:"btn"},[_vm._v(" salin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-akun-mt5"},[_c('div',{staticClass:"left"},[_c('h4',{staticClass:"fs-md-5 fs-6 txt-bold"},[_vm._v(" Kata Sandi MetaTrader ")]),_c('p',{staticClass:"fs-5"},[_vm._v(" oApdsfEd ")])]),_c('button',{staticClass:"btn"},[_vm._v(" salin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-akun-mt5"},[_c('div',{staticClass:"left"},[_c('h4',{staticClass:"fs-md-5 fs-6 txt-bold"},[_vm._v(" Server MetaTrader ")]),_c('p',{staticClass:"fs-5"},[_vm._v(" Akun Real ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-akun-mt5"},[_c('div',{staticClass:"left"},[_c('h4',{staticClass:"fs-md-5 fs-6 txt-bold"},[_vm._v(" IP Server MetaTrader ")]),_c('p',{staticClass:"fs-5"},[_vm._v(" 34.3.33.432:343 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-akun-mt5"},[_c('div',{staticClass:"left"},[_c('h4',{staticClass:"fs-md-5 fs-6 txt-bold"},[_vm._v(" Kata Sandi Investor MT ")]),_c('p',{staticClass:"fs-5"},[_vm._v(" 34.3.33.432:343 ")])]),_c('button',{staticClass:"btn"},[_vm._v(" salin ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-akun-mt5"},[_c('div',{staticClass:"left"},[_c('h4',{staticClass:"fs-md-5 fs-6 txt-bold"},[_vm._v(" kata Sandi Investor ")]),_c('p',{staticClass:"fs-5"},[_vm._v(" d2fdsfd ")])]),_c('button',{staticClass:"btn"},[_vm._v(" salin ")])])
}]

export { render, staticRenderFns }