<template>
  <div id="Login" class="d-flex justify-content-center align-items-center">
    <div class="container-login text-center">
      <img src="/assets/img/logo.png" alt="" class="mb--lg-5 mb-4 logo-login" />
      <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4">Login Account</h1>
      <p class="fs-sm-5 fs-6" v-if="getLang == 'indo' ">Masukan email dan password anda</p>
      <p class="fs-sm-5 fs-6" v-if="getLang == 'uk' ">Enter your email and password</p>
      <form @submit.prevent="onSubmitLogin" class="form mb-4">
        <div class="email mb-4 d-flex flex-column align-items-start">
          <label for="email">Email</label>
          <input
            v-model="email"
            type="text"
            placeholder="Email"
            id="email"
            required
          />
        </div>
        <div class="password mb-4 d-flex flex-column align-items-start">
          <label for="password">Password</label>
          <div class="password-hide-wrapper">
          <input
            v-model="password"
            :type="passwordType"
            placeholder="password"
            id="password"
            required
          />
          <i v-if="passwordType === 'password'" class="fas fa-eye-slash " @click="passwordType = 'text'"></i>
          <i v-if="passwordType === 'text'" class="fas fa-eye" @click="passwordType = 'password'"></i>
          </div>
        </div>
        <!-- <router-link to="/dashboard"> -->
        <button type="submit" class="btn btn-blue txt-bold px-5">Login</button>
        <!-- </router-link> -->
      </form>
      <div class="extra">
        <router-link to="/forgot-password" class="text-white" v-if="getLang == 'indo' ">
          Lupa Password
        </router-link>
        <router-link to="/forgot-password" class="text-white" v-if="getLang == 'uk' ">
          Forgot Password
        </router-link>
        <p v-if="getLang == 'indo' ">atau</p>
        <p v-if="getLang == 'uk' ">or</p>
        <p v-if="getLang == 'indo' ">
          Belum punya akun?
          <a
            href="/signup"
            class="txt-bold text-white"
            >Daftar</a
          >
        </p>
        <p v-if="getLang == 'uk' ">
          Don't have an account yet?
          <a
            href="/signup"
            class="txt-bold text-white"
            >Sign Up</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

console.log("set env: ", process.env.VUE_APP_API_URL);

const userData = JSON.parse(localStorage.getItem("userData"));
// const isLogin = JSON.parse(localStorage.getItem("isLogin"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      isLogin: false,
      getLang: 'uk',
      passwordType: 'password',
    };
  },
  mounted() {
    if (userData != "") {
      var config = {
        method: "get",
        url: `${url}/me/accounts`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };

      axios(config)
        .then(function (res) {
          console.log("success get data: ", res.data.data);
          if (res.data.statusCode == 200) {
            window.location.href = "/dashboard";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  methods: {
    onSubmitLogin() {
      var dataUser = JSON.stringify({
        email: this.email,
        password: this.password,
      });

      var config = {
        method: "post",
        url: `${url}/auth/login`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
        },
        data: dataUser,
      };

      let self = this;

      axios(config)
        .then(function (res) {
          self.isLogin = true;
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          localStorage.setItem(
            "isLogin", true);
          console.log(res.data);
          window.location.href = "/dashboard";
          localStorage.setItem("setLang", "uk");
        })
        .catch(function (error) {
          // const message = error.response.data.message.toLowerCase();
          const errMessage = error.message;
          console.log(error.response.data);
          if (errMessage == "Network Error") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errMessage,
            });
          } else if (
            error.response.data.message.toLowerCase() ==
            "wrong password. please check your password then try again!"
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
          } else if (
            error.response.data.message.toLowerCase() ==
            "user not found. please register or try again"
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errMessage,
            });
          }
          self.isLogin = false;
          localStorage.setItem("isLogin",false);
        });
    },
  },
};
</script>