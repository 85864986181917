<template>
  <div id="SignUp" class="d-flex justify-content-center align-items-center">
    <div class="container-login text-center">
      <img src="/assets/img/logo-white.png" alt="" class="mb--lg-5 mb-4 logo-login" />
      <h1 class="txt-title mb-sm-3 mb-2 fs-sm-4 fs-4">Only one step! Join with us and enjoy the benefit.</h1>
      <!-- <p class="fs-sm-5 fs-6" v-if="getLang == 'indo' ">Hanya dengan satu langkah lagi! Kamu bisa bergabung dengan kami!</p> -->
      <!-- <p class="fs-sm-5 fs-6" v-if="getLang == 'uk' ">Only one step! Join with us and enjoy the benefit.</p> -->
      <form @submit.prevent="onSubmitSignUp" class="form mb-4">
        <div class="form-row">
          <div class="col-md-6 py-2">
            <input v-model="data.name" type="text" name="full-name" class="form-control bg-white" placeholder="Enter full name" required>
          </div>
          <div class="col-md-6 py-2">
            <input v-model="data.email" type="email" name="email" class="form-control bg-white" placeholder="Enter your email" required>
          </div>
        </div>
        <div class="form-group pt-2">
          <div>
            <vue-phone-number-input v-model="vuePhone.phone" v-bind="vuePhone.props" @update="onUpdate"></vue-phone-number-input>
          </div>
        </div>
        <div class="form-group">
          <input v-model="data.referral_code" type="text" name="referral" class="form-control bg-white" placeholder="Referral code">
        </div>
        <div class="form-row">
          <div class="position-relative col-md-6 form-group d-flex align-items-center">
            <input v-model="data.password" :type="passwordType" name="password" class="form-control bg-white" placeholder="Your password" required>
            <div class="position-absolute p-2" style="right:4px">
              <i v-if="passwordType === 'password'" class="fas fa-eye-slash text-secondary" @click="passwordType = 'text'"></i>
              <i v-if="passwordType === 'text'" class="fas fa-eye text-secondary" @click="passwordType = 'password'"></i>
            </div>
          </div>
          <div class="position-relative col-md-6 form-group d-flex align-items-center">
            <input v-model="data.confirm_password" :type="confirmPassType" name="confirm-password" class="form-control bg-white" placeholder="Confirm password" required>
            <div class="position-absolute p-2" style="right:4px">
              <i v-if="confirmPassType === 'password'" class="fas fa-eye-slash text-small text-secondary" @click="confirmPassType = 'text'"></i>
              <i v-if="confirmPassType === 'text'" class="fas fa-eye text-small text-secondary" @click="confirmPassType = 'password'"></i>
            </div>
          </div>
        </div>
        <div class="form-group">
          <select v-model="data.leverage" name="leverage" class="form-control" id="leverage" required>
            <option value="" selected disabled>Select leverage</option>
            <option value="1">1:1</option>
            <option value="50">1:50</option>
            <option value="100">1:100</option>
            <option value="200">1:200</option>
            <option value="300">1:300</option>
            <option value="500">1:500</option>
          </select>
        </div>
        <div class="form-group">
          <select v-model="data.account_type" name="account-type" class="form-control" id="account-type" required>
            <option value="" selected disabled>Select account type</option>
            <option :value="specAccount.philosoper">Philosopher Account</option>
            <option :value="specAccount.stoic">Stoic Account</option>
            <option :value="specAccount.thinker">Thinker Account</option>
          </select>
        </div>
        <div class="form-check">
          <input name="terms" class="form-check-input" type="checkbox" value="" id="inlineCheckbox1" required style="width:auto">
          <label class="form-check-label" for="terms">
            <a target="_blank" href='https://secure.socrateslegacy.com/assets/legal/Socrates-Legal-Documents.pdf'>
              I Have read & agree Terms and Condition
            </a>
          </label>
        </div>
        <!-- <router-link to="/dashboard"> -->
        <button type="submit" class="btn btn-blue txt-bold px-5 mt-3 mx-auto text-center d-flex align-items-center">
          Register &nbsp;
          <div v-if="isLoading" class="pl-2 spinner-border spinner-border-sm text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        <!-- </router-link> -->
      </form>
      <div class="extra">
        <p v-if="getLang == 'indo' ">
          Sudah punya akun?
          <a
            href="/"
            target="_blank"
            class="txt-bold text-white"
            >Login</a
          >
        </p>
        <p v-if="getLang == 'uk' ">
          Have an account?
          <a
            href="/"
            class="txt-bold text-white"
            >Login</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

// console.log("set env: ", process.env.VUE_APP_API_URL);

const userData = JSON.parse(localStorage.getItem("userData"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;
const SPEC_STOIC = process.env.VUE_APP_API_STOIC
const SPEC_PHILOSOPER = process.env.VUE_APP_API_PHILOSOPER
const SPEC_THINKER = process.env.VUE_APP_API_THINKER

export default {
  name: "SignUp",
  data() {
    return {
      isLoading: false,
      getLang: 'uk',
      passwordType: 'password',
      confirmPassType: `password`,
      intPhone: ``,
      countryCode: ``,
      specAccount: {
        stoic: SPEC_STOIC,
        philosoper: SPEC_PHILOSOPER,
        thinker: SPEC_THINKER
      },
      data: {
        name: ``,
        email: ``,
        referral_code: ``,
        password: ``,
        confirm_password: ``,
        leverage: ``,
        account_type: ``
      },
      vuePhone: {
        phone: "",
        props: {
          clearable: true,
          fetchCountry: true,
          preferredCountries: ["ID", "US"],
          noExample: false,
          required: true,
          class: `input-phone`,
          translations: {
            countrySelectorLabel: "Code",
            countrySelectorError: "Error",
            phoneNumberLabel: "Your phone",
            example: "Ex.:"
          }
        }
      }
    };
  },
  mounted() {
    if (userData != "") {
      var config = {
        method: "get",
        url: `${url}/me/accounts`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };

      axios(config)
        .then(function (res) {
          console.log("success get data: ", res.data.data);
          if (res.data.statusCode == 200) {
            window.location.href = "/dashboard";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  methods: {
    onUpdate(payload) {
      let data = payload
      this.intPhone = data?.formatInternational
      this.countryCode = data?.countryCode
    },
    onSubmitSignUp() {
      let val = this.data

      const dataRequest = {
        name: val.name,
        email: val.email,
        password: val.password,
        account_spec_id: val.account_type,
        account_type: `REAL`,
        confirm_password: val.confirm_password,
        phone: this.intPhone.split(' ').join(),
        referral_code: val.referral_code,
      }

      this.isLoading = true

      if(val.password !== val.confirm_password) {
        Swal.fire({
          title: 'Oops!',
          text: 'Please make sure your password and confirm password is match!',
          icon: 'warning'
        })

        this.isLoading = false

        return
      }

      var config = {
        method: "post",
        url: `${url}/auth/register`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
        },
        params: dataRequest,
      };

      axios(config)
        .then((result) => {
          let data = result.data

          if(data.status == `SUCCESS`) {
            this.isLoading = false

            Swal.fire({
              title: `Congratulations!`,
              text: 'Your registration has been submitted successfully! Please check your email to verify your account.',
              icon: 'success'
            }).then(() => {
              this.$router.push('/')
            })
          }
        })
        .catch(function (error) {
          // const message = error.response.data.message.toLowerCase();
          const errMessage = error.message;
          this.isLoading = false

          Swal.fire({
            title: 'Oops!',
            text: errMessage,
            icon: 'warning'
          })
        });
    },
  },
};
</script>