<template>
  <div id="Login" class="d-flex justify-content-center align-items-center">
    <div class="container-login text-center">
      <img src="/assets/img/logo.png" alt="" class="mb--lg-5 mb-4 logo-login" />
      <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4">Forgot Password</h1>
      <p class="fs-sm-5 fs-6" v-if="getLang == 'indo'">Masukkan email Anda dan kami akan mengirimkan tautan untuk mengulang kata sandi Anda.</p>
      <p class="fs-sm-5 fs-6" v-if="getLang == 'uk'">Enter your email and we'll send you a link to repeat your password.</p>
      <form @submit.prevent="onSubmitEmail" class="form mb-4">
        <div class="email mb-4 d-flex flex-column align-items-start">
          <label for="email">Email</label>
          <input v-model="email" type="email" placeholder="Email" id="email" required/>
        </div>
        <button type="submit" class="btn btn-blue txt-bold">Reset my Password</button>
        </form>
        <router-link to="/" class="text-white">
          Go Back
        </router-link>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      getLang: localStorage.getItem("setLang"),
    };
  },
  mounted(){
    if(this.getLang == null){
      this.getLang = "uk"
    }
  },
  methods: {
    onSubmitEmail() {
      var dataUser = JSON.stringify({
        email: this.email,
      });
      console.log(this.email)
      var config = {
        method: "post",
        url: `${url}/auth/forgot-pass`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
        },
        data : dataUser
      };
          let self = this;

      axios(config)
        .then(function (res) {
          console.log("success get data: ", res.data.data);
            if(self.getLang == "indo"){
              Swal.fire({
              icon: "success",
              title: "Sukses Mengirim reset pasword",
              text: "Cek email anda untuk reset pasword",
              confirmButton: false,
            });
            }else{
              Swal.fire({
              icon: "success",
              title: "Successfully Resend password",
              text: "Check your email to reset password",
              confirmButton: false,
            });
            }
        })
        .catch(function (error) {
          const message = error.response.data.message;
          if(self.getLang == "indo"){
            if(message == "user not found"){
              Swal.fire({
                  icon: "error",
                  title: "Opss...",
                  text: "User tidak di temukan",
                  confirmButtonText: "Coba Lagi",
                });
            }else{
              Swal.fire({
                icon: "error",
                title: "Opss...",
                text: message,
                confirmButtonText: "Coba Lagi",
              });
            }
          }else{
            Swal.fire({
                icon: "error",
                title: "Opss...",
                text: message,
                confirmButtonText: "Try Again",
              });
          }
          console.log(error);
        });
    },
  },
};
</script>