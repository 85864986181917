<template>
  <div id="create-account-demo" class="page">
    <!-- navbar component -->
    <Navbar />
    <div class="container">
      <div class="row">
        <!-- trading account info -->
        <div class="col-xl-3 col-lg-4">
          <div class="account-info mb-lg-0 mb-3">
            <div class="w-100 header-info">
              <h4 class="text-center m-0 txt-title fs-md-5 fs-6" v-if="getLang == 'indo'">
                Informasi Akun Trading
              </h4>
              <h4 class="text-center m-0 txt-title fs-md-5 fs-6" v-if="getLang == 'uk'">
                Trading Account Information
              </h4>
            </div>
            <div
              class="content-info fs-md-5"
              v-for="item in dataAccount"
              :key="item._id"
            >
              <p v-if="getLang == 'indo'">Nama : {{ item.name }}</p>
              <p v-if="getLang == 'uk'">Name : {{ item.name }}</p>
              <p v-if="getLang == 'indo'">Email : {{ item.email }}</p>
              <p v-if="getLang == 'uk'">Email : {{ item.email }}</p>
              <p v-if="getLang == 'indo'">Tipe : REAL</p>
              <p v-if="getLang == 'uk'">Type : REAL</p>
              <p v-if="getLang == 'indo'">Posisi : {{ item.role }}</p>
              <p v-if="getLang == 'uk'">Role : {{ item.role }}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-lg-8 text-lg-left text-center">
          <h1 class="txt-title fs-md-3 fs-4 mb-3" v-if="getLang == 'indo'">
            Buat Akun Trading Baru
          </h1>
          <h1 class="txt-title fs-md-3 fs-4 mb-3" v-if="getLang == 'uk'">
            Create New Trading Account
          </h1>
          <p class="fs-md-5 fs-6 mb-4" v-if="getLang == 'indo'">
            Membuat akun Trading Asli Baru. Pilih akun di bawah ini!
          </p>
          <p class="fs-md-5 fs-6 mb-4" v-if="getLang == 'uk'">
            Creating new real trading account. Choose account below!
          </p>
          <!-- choose card -->
          <div class="choose-card">
            <h3 class="txt-bold fs-md-5 fs-6" v-if="getLang == 'indo'">Spesifikasi</h3>
            <h3 class="txt-bold fs-md-5 fs-6" v-if="getLang == 'uk'">Specs</h3>
            <p class="fs-md-5 fs-6" v-if="getLang == 'indo'">Silahkan pilih salah satu kartu.</p>
            <p class="fs-md-5 fs-6" v-if="getLang == 'uk'">Please choose a card.</p>
            <!-- cards spec -->
            <div class="cards-specs">
              <!-- <VueSlickCarousel v-bind="setting" class="vueSlick"> -->
                <!-- thinker -->
                  <div>
                    <div
                      for="account-demo"
                      v-bind:class="{
                        card_demo: true,
                        pointer: true,
                        selected: accDemo,
                      }"
                    >
                      <h4 class="card-title txt-bold fs-md-4 fs-5" v-if="getLang == 'indo'">Akun Demo</h4>
                      <h4 class="card-title txt-bold fs-md-4 fs-5" v-if="getLang == 'uk'">Demo Account</h4>
                      <div class="list-spec">
                        <p>Spread under 10</p>
                        <p>Commission start from $10</p>
                        <p>Up to 500 Leverage</p>
                      </div>
                    </div>
                  </div>
                <!-- philosopher -->
                  <!-- <div>
                    <div
                      for="account-philosopher"
                      v-bind:class="{
                        card: true,
                        pointer: true,
                        selected: philosoperSelected,
                      }"
                      v-on:click="updateCard('philosoper')"
                    >
                      <h4 class="card-title txt-bold fs-md-4 fs-5">
                        Philosopher
                      </h4>
                      <div class="list-spec">
                        <p>Min. Deposit $100</p>
                        <p>Spread 1.2 - 1.5 pips</p>
                        <p>$10 Commission</p>
                        <p>Swap YES</p>
                      </div>
                    </div>
                  </div> -->
                <!-- stoic -->
                  <!-- <div>
                    <div
                      for="account-stoic"
                      v-bind:class="{
                        card: true,
                        pointer: true,
                        selected: stoicSelected,
                      }"
                      v-on:click="updateCard('stoic')"
                    >
                      <h4 class="card-title txt-bold fs-md-4 fs-5">Stoic</h4>
                      <div class="list-spec">
                        <p>Min. Deposit $100</p>
                        <p>Spread 1.2 - 1.5 pips</p>
                        <p>$10 Commission</p>
                        <p>Swap YES</p>
                      </div>
                    </div>
                  </div> -->
              <!-- </VueSlickCarousel> -->
            </div>
            <div class="mx-lg-0 mx-auto mt-xl-4 mt-5 col-md-4 col-12 p-0">
              <label for="select-account"> Leverage </label>
              <div class="select-custom mb-3">
                <select
                  class="w-100"
                  v-model="leverage"
                  name="select-account"
                  id="select-account"
                  @change="onChangeAccount($event)"
                  required
                >
                  <option value="10"  > 1:10 </option>
                  <option value="50"  > 1:50 </option>
                  <option value="100"  > 1:100 </option>
                  <option value="200"  > 1:200 </option>
                  <option value="500"  > 1:500 </option>
                </select>
                <span></span>
              </div>
            </div>
            <!-- input_deposit -->
            <div class="right-side col-md-4 col-12 p-0">
              <label for="amount"> Deposit (Optional) </label>
              <div class="input">
              <div class="input-amount w-100">
                <img src="/assets/img/dollar.svg" alt="" />
                <input
                  class="w-100"
                  type="text"
                  name="amount"
                  id="amount"
                  placeholder="$1000"
                  v-model="input_deposit"
                />
              </div>
            </div>
            </div>
            <!-- button create account -->
            <button class="btn btn-blue txt-bold mt-5 mt-lg-4" @click="onCreateAccount" v-if="getLang == 'indo'">
              Buat Akun
            </button>
            <button class="btn btn-blue txt-bold mt-5 mt-lg-4" @click="onCreateAccount" v-if="getLang == 'uk'">
              Create Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import Notiflix from 'notiflix';
// import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

const userData = JSON.parse(localStorage.getItem("userData"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;
// const thinker = process.env.VUE_APP_API_THINKER_DEMO;
// const philosoper = process.env.VUE_APP_API_PHILOSOPER_DEMO;
// const stoic = process.env.VUE_APP_API_STOIC_DEMO;
const token_acc_demo = process.env.VUE_APP_API_ACC_DEMO;


export default {
  name: "createAccount",
  components: {
    Navbar,
    // VueSlickCarousel
  },
  data() {
    return {
      dataAccount: [],
      accDemo: true,
      spec_id: token_acc_demo,
      getLang: localStorage.getItem("setLang"),
      leverage: 10,
      input_deposit: null,
      setting: {
        dots: true,
        slidesToShow: 3,
        infinite: false,
        speed: 500,
        initialSlide: 0,
        variableWidth:true,
        responsive: [
          {
            breakpoint: 1200,
            settings:{
              slidesToShow: 2 
            }
          },
          {
            breakpoint: 992,
            settings:{
              slidesToShow: 1,
            }
          },
        ]
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    Notiflix.Loading.init({ className:'notiflix-loading', zindex:4000, backgroundColor:'rgba(0,0,0,0.8)', rtl:false, useGoogleFont:false, fontFamily:'Quicksand', cssAnimation:true, cssAnimationDuration:400, clickToClose:false, customSvgUrl:null, svgSize:'80px', svgColor:'#c09a07', messageID:'NotiflixLoadingMessage', messageFontSize:'15px', messageMaxLength:34, messageColor:'#dcdcdc', }); 
    let self = this;
    var configget = {
      method: "get",
      url: `${url}/me`,
      headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
    };

    axios(configget)
      .then(function (res) {
        let obj = res.data.data;
        self.dataAccount = [obj];
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    // updateCard: function (param) {
    //   this.thinkerSelected = false;
    //   this.philosoperSelected = false;
    //   this.stoicSelected = false;
    //   if (param == "thinker") {
    //     this.thinkerSelected = true;
    //     this.spec_id = thinker;
    //   }
    //   if (param == "philosoper") {
    //     this.philosoperSelected = true;
    //     this.spec_id = philosoper;
    //   }
    //   if (param == "stoic") {
    //     this.stoicSelected = true;
    //     this.spec_id = stoic;
    //   }
    // },
    onCreateAccount: function () {
      Notiflix.Loading.circle({
         className:'notiflix-loading', 
         svgColor:'#c09a07',
        });
      var data = {
        user_id: userData.user._id,
        spec_id: this.spec_id,
        leverage: this.leverage,
        type: "DEMO",
        deposit: this.input_deposit,
      };
      var config = {
        method: "post",
        url: `${url}/account`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
        data: data,
      };

      if(this.getLang == "indo"){
        axios(config)
        .then(function () {
          Notiflix.Loading.remove();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda Berhasil membuat akun.",
            showConfirmButton: false,
            timer: 3000,
          }).then(()=>{
            window.location.href = "/dashboard";
          });
        })
        .catch(function (error) {
          Notiflix.Loading.remove();
          const message = error.response.data.message;
          if( message == 'Cast to ObjectId failed for value "null" at path "_id" for model "Account_spec"'){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Layanan Belum Tersedia`,
            });
          }else{
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${message}`,
            });
          }
        });
      }else{
        axios(config)
        .then(function () {
          Notiflix.Loading.remove();
          Swal.fire({
            icon: "success",
            title: "Succeed",
            text: "You have successfully created an account.",
            showConfirmButton: false,
            timer: 3000,
          }).then(()=>{
            window.location.href = "/dashboard";
          });
        })
        .catch(function (error) {
          Notiflix.Loading.remove();
          const message = error.response.data.message;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${message}`,
            });
        });
      }
    },
  },
};
</script>