<template>
  <div id="verify-bank" class="page">
    <!-- navbar component -->
    <Navbar />
    <div class="container">
      <!-- main content -->
      <div class="row">
        <div class="col-lg-7 col-md-6 mb-4">
          <!-- information -->
          <div class="container-gray warning d-flex">
            <img src="/assets/img/warning.svg" alt="" class="mr-4" />
            <div>
              <h4 class="txt-bold fs-6 mb-3" v-if="getLang == 'indo'">
                Dokumen yang Anda berikan harus valid setidaknya 30 hari dan
                berisi semua detail berikut:
              </h4>
              <h4 class="txt-bold fs-6 mb-3" v-if="getLang == 'uk'">
                The document you are providing must be valid at least 30 days
                and contain all of the following details:
              </h4>
              <p class="fs-6 mb-2" v-if="getLang == 'indo'">
                Berikan informasi yang benar termasuk nama dan akun Anda nomor.
              </p>
              <p class="fs-6 mb-2" v-if="getLang == 'uk'">
                Provide correct information include your name and your account
                number.
              </p>
              <p class="fs-6" v-if="getLang == 'indo'">
                Setiap kegagalan transaksi yang disebabkan oleh informasi yang
                salah tidak dapat dianggap sebagai tanggung jawab Socrates
                Legacy.
              </p>
              <p class="fs-6" v-if="getLang == 'uk'">
                Any failures on transactions caused by wrong information cannot
                be held on Socrates Legacy responbility.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <!-- form verify -->
          <h1
            class="fs-4 text-md-left text-center txt-title mb-4"
            v-if="getLang == 'indo'"
          >
            Permintaan Verifikasi Bank
          </h1>
          <h1
            class="fs-4 text-md-left text-center txt-title mb-4"
            v-if="getLang == 'uk'"
          >
            Bank Verification Request
          </h1>
          <div class="step bank-name d-flex flex-column">
            <label for="bank-name" class="txt-bold" v-if="getLang == 'indo'">
              1. Nama Bank
            </label>
            <label for="bank-name" class="txt-bold" v-if="getLang == 'uk'">
              1. Bank Name
            </label>
            <!-- <div class="select-custom w-100">
              <select
                name="bank-name"
                id="bank-name"
                class="w-100"
                v-model="select_bank"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  v-if="getLang == 'indo'"
                >
                  Pilih opsi Anda
                </option>
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  v-if="getLang == 'uk'"
                >
                  Select your option
                </option>
                <option
                  v-for="item in bank"
                  :key="item.name"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
              <span></span>
            </div> -->

            <!-- string value -->
            <!-- select bank name -->
            <model-select
              class="select-bank w-100"
              :options="bankOptions"
              v-model="select_bank"
              placeholder="select bank"
            >
            </model-select>
          </div>
          <div class="step bank-number d-flex flex-column">
            <label for="bank-number" class="txt-bold" v-if="getLang == 'indo'">
              2. Rekening Bank
            </label>
            <label for="bank-number" class="txt-bold" v-if="getLang == 'uk'">
              2. Bank Number
            </label>
            <input
              v-model="input_bank_num"
              type="text"
              class="input-text"
              name="bank-number"
              id="bank-number"
              placeholder="123123"
            />
          </div>
          <div class="step account-name d-flex flex-column">
            <label for="account-name" class="txt-bold" v-if="getLang == 'indo'">
              3. Nama Akun
            </label>
            <label for="account-name" class="txt-bold" v-if="getLang == 'uk'">
              3. Account Name
            </label>
            <input
              v-model="input_account_name"
              type="text"
              class="input-text"
              name="account-name"
              id="account-name"
              placeholder="Elon Musk"
            />
          </div>
          <div class="step upload-file d-flex flex-column">
            <label for="upload-file" class="txt-bold" v-if="getLang == 'indo'">
              4. Unggah Laporan Bank Anda
            </label>
            <label for="upload-file" class="txt-bold" v-if="getLang == 'uk'">
              4. Upload Your Bank Statement
            </label>
            <!-- old code -->
            <!-- <div class="upload-here mb-md-3 mb-2">
                            <img src="/assets/img/image-upload.svg" alt="upload here" />
                            <h4 class="txt-bold fs-lg-4 fs-5 choose-file">
                            Drop your image here or <label for="input-file">Browse</label>
                            <input
                                id="input-file"
                                style="display: none"
                                type="file"
                                @change="onFileSelected"
                            />
                            </h4>
                            <p>Provide files in JPG/JPEG/PNG format, 10 MB maximum.</p>
                        </div> -->
            <!-- new one -->
            <!-- <div class="drop-zone"  @change="onFileSelected">
                            <span class="drop-zone__prompt d-flex flex-column">
                                <img src="/assets/img/image-upload.svg" alt="upload here" />
                                <p class="mb-0"><b>Drop</b> file here or <b>click</b> to upload</p>
                            </span>
                            <input id="dropkaka" type="file" name="Bank" class="drop-zone__input">
                            <div class="drop-zone_thumb" data-label="Indetity.txt"></div>
                        </div> -->
            <UploadImages
              :max="1"
              maxError="Unggah file maksimal 1 gambar"
              @changed="handleImages"
              v-if="getLang == 'indo'"
            />
            <UploadImages
              :max="1"
              maxError="Maximal Upload file 1 gambar"
              @changed="handleImages"
              v-if="getLang == 'uk'"
            />
            <p class="mt-2" v-if="getLang == 'indo'">
              Sediakan file dalam format JPG/JPEG/PNG, maksimum 10 MB.
            </p>
            <p class="mt-2" v-if="getLang == 'uk'">
              Provide files in JPG/JPEG/PNG format, 10 MB maximum.
            </p>
          </div>
          <div class="d-flex justify-content-center w-100">
            <button
              class="btn btn-blue txt-bold"
              @click="onSelectRequest"
              v-if="getLang == 'indo'"
            >
              Kirim
            </button>
            <button
              class="btn btn-blue txt-bold"
              @click="onSelectRequest"
              v-if="getLang == 'uk'"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { bank } from "./bank";
import { ModelSelect } from "vue-search-select";
import UploadImages from "vue-upload-drop-images";
import Notiflix from "notiflix";
import { bankOptions } from "./bankOptions";
const userData = JSON.parse(localStorage.getItem("userData"));
const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "verifyBank",
  components: {
    Navbar,
    UploadImages,
    ModelSelect,
  },
  data() {
    return {
      select_bank: "",
      input_bank_num: "",
      input_account_name: "",
      input_file: null,
      bank: bank,
      bankOptions: bankOptions,
      getLang: localStorage.getItem("setLang"),
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    // let arr = [];
    // // const fetch_bank = () => {
    //   bank.map((item)=>{
    //     arr.push(
    //       {
    //       text: item.name,
    //       value: item.name,
    //     }
    //     )
    //   })
    // // }
    // this.bankOptions = arr;
    // console.log(arr)
  },
  created() {
    localStorage.setItem("pageNow", "verifbank");
  },
  methods: {
    handleImages(files) {
      console.log(files);
      this.input_file = files[0];
    },
    onFileSelected() {
      //   this.input_file = e.target.files[0];
      //   console.log(e.target.files[0]);
      console.log(alert("uyyy"));
    },
    onSelectRequest() {
      var data = new FormData();
      data.append("user_id", userData.user._id);
      data.append("idNumber", null);
      data.append("type", "BANK");
      data.append("file", this.input_file);
      data.append("category", "PAYMENT");
      data.append("bank_name", this.select_bank);
      data.append("bank_account_number", this.input_bank_num);
      data.append("bank_account_name", this.input_account_name);

      var config = {
        method: "post",
        url: `${url}/verification`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
        data: data,
      };
      if (
        this.getLang == "indo" &&
        (!this.input_file ||
          !this.input_account_name ||
          !this.input_bank_num ||
          !this.select_bank)
      ) {
        Swal.fire({
          icon: "question",
          title: "Data Belum Lengkap!",
          text: "Lengkapi data Anda untuk melakukan verifikasi bank!",
          confirmButtonText: "Oke",
        });
      } else if (
        this.getLang == "uk" &&
        (!this.input_file ||
          !this.input_account_name ||
          !this.input_bank_num ||
          !this.select_bank)
      ) {
        Swal.fire({
          icon: "question",
          title: "Incomplete Data!",
          text: "Complete your data to perform bank verification!",
          confirmButtonText: "Oke",
        });
      } else {
        if (this.getLang == "indo") {
          Swal.fire({
            title: "Verifikasi Bank",
            text: "Apakah data sudah benar?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Benar",
          }).then((result) => {
            Notiflix.Loading.circle({
              className: "notiflix-loading",
              svgColor: "#c09a07",
            });
            if (result.isConfirmed) {
              axios(config)
                .then(function (response) {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: "success",
                    title: "Anda Berhasil verifikasi Bank",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  window.location.href = "/verify-pending";
                  console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                  Notiflix.Loading.remove();
                  if (error.response.status == 500) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.response.data.message,
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.message,
                    });
                  }
                  console.log(error);
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Notiflix.Loading.remove();
            }
          });
        } else {
          Swal.fire({
            title: "Verify Bank",
            text: "Is the data correct?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((result) => {
            Notiflix.Loading.circle({
              className: "notiflix-loading",
              svgColor: "#c09a07",
            });
            if (result.isConfirmed) {
              axios(config)
                .then(function (response) {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: "success",
                    title: "You Successfully Verify Bank",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  window.location.href = "/verify-pending";
                  console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                  Notiflix.Loading.remove();
                  if (error.response.status == 500) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.response.data.message,
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.message,
                    });
                  }
                  console.log(error);
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Notiflix.Loading.remove();
            }
          });
        }
      }
    },
  },
};
</script>