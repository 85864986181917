<template>
  <div id="verify-timer">
    <!-- navbar component -->
    <Navbar />
    <div class="container mt-5 mt-md-2">
      <h3 class="txt-title fs-md-5 fs-6 text-center" v-if="getLang == 'indo'">
        Waktu Verifikasi Deposit
      </h3>
      <h3 class="txt-title fs-md-5 fs-6 text-center" v-if="getLang == 'uk'">
        Deposit Verification Time
      </h3>
      <h1 class="txt-title fs-md-1 fs-1 txt-blue text-center">
        {{ setTimerPayment(timeVerication) }}
      </h1>
      <div class="row mt-5">
        <!-- left side -->
        <!-- if it's not crypto -->
        <div class="col-md-6" v-if="!methodtype">
          <!-- tujuan transfer bank -->
          <div class="mb-3 ml-0">
            <p class="fs-5 fs-md-4 txt-bold m-0" v-if="getLang == 'indo'">
              Silahkan transfer ke:
            </p>
            <p class="fs-5 fs-md-4 txt-bold m-0" v-if="getLang == 'uk'">
              Please transfer to:
            </p>
          </div>
          <div class="info-bank p-3 mb-2">
            <p class="fs-md-7 m-0" v-if="getLang == 'indo'">Jumlah Transfer:</p>
            <p class="fs-md-7 m-0" v-if="getLang == 'uk'">Transfer Amount:</p>
            <p class="txt-bold fs-md-5 p-0 m-0">
              {{ numberWithCommas(`Rp.${paymentID.amount * rateUsd}`) }}
            </p>
          </div>
          <div class="info-bank mb-2">
            <div class="mb-3 mt-2">
              <p class="fs-md-7 m-0">Bank</p>
              <p class="txt-bold fs-md-5">BCA</p>
            </div>
            <div class="mb-3">
              <p class="fs-md-7 m-0" v-if="getLang == 'indo'">Nomor Akun</p>
              <p class="fs-md-7 m-0" v-if="getLang == 'uk'">Account Number</p>
              <p class="txt-bold fs-md-5">010-2075461</p>
            </div>
            <div class="mb-3">
              <p class="fs-md-7 m-0" v-if="getLang == 'indo'">Nama Akun</p>
              <p class="fs-md-7 m-0" v-if="getLang == 'uk'">Account Name</p>
              <p class="txt-bold fs-md-5">PT SURYABARA INDOTEK PERKASA</p>
            </div>
          </div>
        </div>
        <!-- if its crypto -->
        <div class="col-md-6" v-if="methodtype">
          <div class="bg-light p-3 rounded-primary">
            <p class="txt-bold fs-md-5 mb-1">Important!</p>
            <p class="fs-md-7 m-0">
              There is a 1.5% charge if you do the deposit via USDT wallet under
              25,000USDT. Example : If you deposit 1,000 USDT, then you have to
              deposit 1,015 USDT.
              <br />
              <br />
              1% charge if you do the deposit via USDT wallet between 25,000USDT
              - 50,000USDT. Example : If you deposit 25,000 USDT, then you have
              to deposit 25,250 USDT.
              <br />
              <br />
              0.8% charge if you do the deposit via USDT wallet more than
              50,000USDT. Example : If you deposit 50,000 USDT, then you have to
              deposit 50,400 USDT.
            </p>
          </div>
          <div class="bg_secondary p-3 rounded-primary mt-3">
            <p class="fs-md-7 m-0 mt-2">
              Scan this code below to complete your payment. We'll send a
              notification once your transaction is successful
            </p>
            <!-- bep 2 -->
            <!-- <div>
              <p class="txt-bold txt-red fs-md-5 mt-4 mb-0">
                Send USDT (BEP-2) only to this address! <br />
                MEMO IS REQUIRED!
              </p>
              <div>
                <img
                  src="/assets/img/wallet-socrates/BEP2.png"
                  alt="qr-code"
                  style="max-width: 220px"
                />
              </div>
              <textarea
                name=""
                ref="bep2"
                id=""
                cols="30"
                rows="2"
                v-model="bep2"
                class="w-100 form-control"
                readonly
              ></textarea>

              <div>
                <p class="txt-bold fs-md-6 m-0">
                  Total Transfer: ${{ paymentID.amount }} (deposit + transfer
                  fees)
                </p>
                <p class="txt-bold fs-md-6">MEMO : 495533510</p>
                <button
                  class="btn btn-blue txt-bold"
                  @click="copyText($refs.bep2)"
                >
                  Copy Wallet Address
                </button>
              </div>
            </div> -->

            <!-- erc 20 -->
            <div>
              <p class="txt-bold txt-red fs-md-5 mt-4 mb-0">
                Or send USDT (ERC-20) only to this address!
              </p>
              <div>
                <img
                  src="/assets/img/wallet-socrates/ERC20.png"
                  alt="qr-code"
                  style="max-width: 220px"
                />
              </div>
              <textarea
                name=""
                ref="erc20"
                id=""
                cols="30"
                rows="2"
                v-model="erc20"
                class="w-100 form-control"
                readonly
              ></textarea>
              <p class="txt-bold fs-md-6">
                Total Transfer: ${{ paymentID.amount }} (deposit + transfer
                fees)
              </p>
              <button
                class="btn btn-blue txt-bold"
                @click="copyText($refs.erc20)"
              >
                Copy Wallet Address
              </button>
            </div>

            <!-- trx 20 -->
            <p class="txt-bold txt-red fs-md-5 mt-4 mb-0">
              Or send USDT (TRC-20) only to this address!
            </p>
            <div>
              <img
                src="/assets/img/wallet-socrates/TRC20.png"
                alt="qr-code"
                style="max-width: 220px"
              />
            </div>
            <textarea
              name=""
              ref="trc20"
              id=""
              cols="30"
              rows="2"
              v-model="trc20"
              class="w-100 form-control"
              readonly
            ></textarea>
            <p class="txt-bold fs-md-6">
              Total Transfer: ${{ paymentID.amount }} (deposit + transfer fees)
            </p>
            <button
              class="btn btn-blue txt-bold"
              @click="copyText($refs.trc20)"
            >
              Copy Wallet Address
            </button>

            <!-- bep 20 -->
            <!-- <div id="bep20">
              <p class="txt-bold txt-red fs-md-5 mt-4 mb-0">
                Or send USDT (BEP-20) only to this address!
              </p>
              <div>
                <img
                  src="/assets/img/wallet-socrates/BEP20.png"
                  alt="qr-code"
                  style="max-width: 220px"
                />
              </div>
              <textarea
                name=""
                ref="bep20"
                id=""
                cols="30"
                rows="2"
                v-model="bep20"
                class="w-100 form-control"
                readonly
              ></textarea>
              <p class="txt-bold fs-md-6">
                Total Transfer: ${{ paymentID.amount }} (deposit + transfer fees)
              </p>
              <button
                class="btn btn-blue txt-bold"
                @click="copyText($refs.bep20)"
              >
                Copy Wallet Address
              </button>
            </div> -->
          </div>
          <div class="d-none d-md-flex justify-content-between mt-4">
            <router-link to="/dashboard">
              <button class="btn btn_border_primary txt-bold">Back</button>
            </router-link>
          </div>
        </div>
        <!-- right side -->
        <div class="col-md-6">
          <div class="bg-light p-3 rounded-primary">
            <p class="txt-bold fs-md-7 mb-1">DISCLAIMER</p>
            <p class="fs-md-7 m-0">
              1. If you transfer to the wrong address, it will result in
              permanent loss of balance
            </p>
            <p class="fs-md-7 m-0">
              2. Sending coins other than USDT to this address will result in
              permanent balance loss.
            </p>
          </div>
          <!-- upload bukti pembayaran -->
          <h2
            class="txt-title fs-md-4 fs-5 mb-md-4 mb-3 mt-3"
            v-if="getLang == 'indo'"
          >
            Upload Bukti Pembayaran
          </h2>
          <h2
            class="txt-title fs-md-4 fs-5 mb-md-4 mb-3 mt-3"
            v-if="getLang == 'uk'"
          >
            Upload Payment Proof
          </h2>
          <!-- old code -->
          <!-- <div class="upload-here mb-md-3 mb-2">
                  <img src="/assets/img/image-upload.svg" alt="upload here">
                  <h4 class="txt-bold fs-lg-4 fs-5 choose-file">
                      Drop your image here or <label for="input-file">Browse</label>
                      <input id="input-file" style="display: none;" type="file" @change="onFileSelected">
                  </h4>
                  <p>
                      Provide files in JPG/JPEG/PNG format, 10 MB maximum.
                  </p>
              </div> -->
          <!-- new code -->
          <div class="drop-zone" v-show="false">
            <span class="drop-zone__prompt d-flex flex-column">
              <img src="/assets/img/image-upload.svg" alt="upload here" />
              <p class="mb-0" v-if="getLang == 'indo'">
                <b>Letakan</b> file disini atau <b>klik</b> untuk diunggah
              </p>
              <p class="mb-0" v-if="getLang == 'uk'">
                <b>Drop</b> file here or <b>click</b> to upload
              </p>
            </span>
            <!-- <div class="drop-zone_thumb" data-label="Indetity.txt"></div> -->
            <input
              type="file"
              name="Bank"
              class="drop-zone__input"
              @change="onFileSelected"
              required
            />
          </div>
          <UploadImages
            class="upload-images"
            :max="1"
            maxError="Maximal Upload file 1 gambar"
            @changed="handleImages"
          />
          <p class="mt-2" v-if="getLang == 'indo'">
            Sediakan file dalam format JPG/JPEG/PNG, maksimum 10 MB.
          </p>
          <p class="mt-2" v-if="getLang == 'uk'">
            Provide files in JPG/JPEG/PNG format, 10 MB maximum.
          </p>
          <button
            class="btn btn-blue txt-bold"
            @click="onSubmitVerify"
            v-if="getLang == 'indo'"
          >
            Kirim Bukti
          </button>
          <button
            class="btn btn-blue txt-bold"
            @click="onSubmitVerify"
            v-if="getLang == 'uk'"
          >
            Send Proof
          </button>
          <div class="d-flex d-md-none justify-content-between mt-4">
            <router-link to="/dashboard">
              <button class="btn btn_border_primary txt-bold">Back</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import Swal from "sweetalert2";
import axios from "axios";
import UploadImages from "vue-upload-drop-images";
import Notiflix from "notiflix";

// const paymentID = JSON.parse(localStorage.getItem("historyPaymentID"));
// const userData = JSON.parse(localStorage.getItem("userData"));

const url_verify = process.env.VUE_APP_API_URL_VERIFY;
const api_token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "identity",
  components: {
    Navbar,
    UploadImages,
  },
  data() {
    return {
      historyPayment: null,
      timeVerication: "",
      timeNow: "",
      timeUpdate: "",
      input_file: "",
      expiredTime: "",
      paymentID: JSON.parse(localStorage.getItem("historyPaymentID")),
      login_id: JSON.parse(localStorage.getItem("login_id")),
      getLang: localStorage.getItem("setLang"),
      rateUsd: 0,
      rateUsdApi: 0,
      env: "",
      methodtype: false,
      bep2: "",
      erc20: "0x7A85A6334DBBA3bCD021630a47f6BDbB3468B882",
      trc20: "TRNhuFS9wZKHandY1Dv3JRT5FzkVb8dPhu",
      bep20: "",
      datamethod: JSON.parse(localStorage.getItem("historyPaymentID")),
      amount: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("saved_wallet")) {
      this.saved_wallet = localStorage.getItem("saved_wallet");
    }
    if (this.datamethod.method == "CRYPTO") {
      this.methodtype = true;
    }

    // check apakah token yang dipakai sekarang ini staging apa production
    const apitoken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUsImV4cGlyZWQiOjE2NDc3Njk3NjIuNjU4LCJpYXQiOjE2MTY2NjU3NjJ9.g8OjvuNsh-8bMuZBmAg7aU_oYcLIALbmLSMIhzbmMYY";
    if (api_token == apitoken) {
      this.env = "staging";
    } else {
      this.env = "production";
    }
    let self = this;
    // get rate
    var getsys = {
      method: "get",
      url: process.env.VUE_APP_API_URL_VERIFY + "/api/rate",
    };
    axios(getsys)
      .then(function (resp) {
        // MANUAL RATE
        // self.rateUsd = 16000;
        // AUTO RATE
        self.rateUsd = resp.data.data.rate;
        // self.rateUsd = Math.ceil(rateUsd[0].latestPrice / 100) * 100 + 400;
      })
      .catch(function (error) {
        console.log(error);
        alert("Something wrong, please try again later");
        // MANUAL RATE
        self.rateUsd = 0;
      });
    //
    console.log("rate usd: ", self.rateUsd);
    this.timeVerication = this.paymentID.created_at;
    console.log(this.paymentID);
    const expired = new Date(this.timeVerication).getTime() + 10800000;
    this.expiredTime = new Date(expired).toLocaleString();
    // const amount = `RP${Math.floor(this.paymentID.amount) * this.rateUsd}`;
    //   this.amount = amount;
  },
  created() {
    this.setTimerPayment();
    this.timeUpdate = setInterval(this.setTimerPayment, 1000);
    localStorage.setItem("pageNow", "veriftimer");
  },
  methods: {
    copyText(text) {
      var copyText = text;
      copyText.select();
      document.execCommand("copy");
      Notiflix.Notify.success("Copied Successfully");
      // Swal.fire({
      //   icon: "success",
      //   title: "Link Berhasil Disalin",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    },
    numberWithCommas: function (params) {
      return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onFileSelected(e) {
      this.input_file = e.target.files[0];
      console.log(e.target.files[0]);
    },
    handleImages(files) {
      this.input_file = files[0];
    },
    find_account_name(spec_id) {
      if (spec_id == process.env.VUE_APP_API_ECN) {
        return "ECN Raw";
      }
      if (spec_id == process.env.VUE_APP_API_STANDARD) {
        return "Standard";
      }
      if (spec_id == process.env.VUE_APP_API_SYARIAH) {
        return "Syariah";
      }
    },
    onSubmitVerify() {
      const amount = `RP${Math.floor(this.paymentID.amount) * this.rateUsd}($${
        this.paymentID.amount
      })`;
      var data = new FormData();
      if (this.datamethod.method == "CRYPTO") {
        data.append("user_id", this.paymentID.user_id);
        data.append("user_name", this.paymentID.user.name);
        data.append("account_id", this.paymentID.account_id);
        data.append("login_id", this.login_id);
        data.append("amount", amount);
        data.append("bank_name", null);
        data.append("bank_username", null);
        data.append("attachment", this.input_file);
        data.append("submited_time", this.paymentID.created_at);
        data.append("expired_time", this.expiredTime);
        data.append(
          "account",
          this.find_account_name(this.paymentID.account.spec_id)
        );
        data.append("bank_acc", "[CRYPTO] " + this.saved_wallet);
      } else {
        data.append("user_id", this.paymentID.user_id);
        data.append("user_name", this.paymentID.user.name);
        data.append("account_id", this.paymentID.account_id);
        data.append("login_id", this.login_id);
        data.append("amount", amount);
        data.append("bank_name", this.paymentID.bank.bank_name);
        data.append("bank_acc", this.paymentID.bank.bank_acc);
        data.append("bank_username", this.paymentID.bank.bank_username);
        data.append("attachment", this.input_file);
        data.append("submited_time", this.paymentID.created_at);
        data.append("expired_time", this.expiredTime);
        data.append(
          "account",
          this.find_account_name(this.paymentID.account.spec_id)
        );
      }
      data.append("app_env", this.env);
      console.log(amount);
      var config = {
        method: "post",
        url: `${url_verify}/api/v1/deposit/verification`,
        data: data,
      };
      if (this.getLang == "indo") {
        Swal.fire({
          title: "Verifikasi Deposit",
          text: "Apakah data sudah benar?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Benar",
        }).then((result) => {
          Notiflix.Loading.circle({
            className: "notiflix-loading",
            svgColor: "#c09a07",
          });
          if (result.isConfirmed) {
            axios(config)
              .then(function (response) {
                if (response.data.response == "success") {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Verifikasi berhasil, permintaan anda sedang diproses oleh admin!",
                    confirmButtonText: "Oke",
                  }).then(() => {
                    window.location.href = "/dashboard";
                  });
                } else {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: "error",
                    title: "Opss...",
                    text: "Verifikasi gagal, mohon upload bukti pembayaran!",
                    confirmButtonText: "Oke",
                  });
                }

                console.log(response);
              })
              .catch(function (error) {
                Notiflix.Loading.remove();
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message,
                });
                console.log(error.message);
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Notiflix.Loading.remove();
          }
        });
      } else {
        Swal.fire({
          title: "Deposit Verification",
          text: "Is the data correct?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          Notiflix.Loading.circle({
            className: "notiflix-loading",
            svgColor: "#c09a07",
          });
          if (result.isConfirmed) {
            axios(config)
              .then(function (response) {
                if (response.data.response == "success") {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Verification is successful, your request is being processed by admin!",
                    confirmButtonText: "Oke",
                  }).then(() => {
                    window.location.href = "/dashboard";
                  });
                } else {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: "error",
                    title: "Opss...",
                    text: "Verification failed, please upload proof of payment!",
                    confirmButtonText: "Oke",
                  });
                }

                console.log(response);
              })
              .catch(function (error) {
                Notiflix.Loading.remove();
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message,
                });
                console.log(error.message);
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Notiflix.Loading.remove();
          }
        });
      }
    },
    setTimerPayment: function (item) {
      // waktu dibuat
      const waktuDibuat = new Date(item).getTime() + 10800000;

      // waktu sekarang
      this.timeNow = new Date().getTime();
      const selisih = waktuDibuat - this.timeNow;
      var jam = Math.floor(
        (selisih % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var menit = Math.floor((selisih % (1000 * 60 * 60)) / (1000 * 60));
      var detik = Math.floor((selisih % (1000 * 60)) / 1000);

      if (selisih > 60000) {
        return `0${jam} : ${menit} : ${detik}`;
      } else {
        return "Expired";
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timeUpdate);
  },
};
</script>