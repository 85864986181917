<template>
  <div id="profile" class="page">
    <!-- navbar component -->
    <Navbar />
    <div class="container">
      <!-- <h2 class="txt-title fs-4 mb-4 text-md-left text-center">
        User Profile Detail
      </h2> -->
      <div class="row">
        <!-- profile detail -->
        <div class="col-lg-4 col-md-6">
          <h4
            class="txt-bold header-profile-detail fs-md-4 mb-3"
            v-if="getLang == 'indo'"
          >
            Profil Saya
          </h4>
          <h4
            class="txt-bold header-profile-detail fs-md-4 mb-3"
            v-if="getLang == 'uk'"
          >
            My Profile
          </h4>
          <div class="container-gray" v-for="item in account" :key="item._id">
            <div>
              <p class="fs-md-7 mb-1" v-if="getLang == 'indo'">Nama</p>
              <p class="fs-md-7 mb-1" v-if="getLang == 'uk'">Name</p>
              <p class="txt-bold fs-md-5">
                {{ item.name }}
              </p>
            </div>
            <div>
              <p class="fs-md-7 mb-1" v-if="getLang == 'indo'">Email</p>
              <p class="fs-md-7 mb-1" v-if="getLang == 'uk'">Email</p>
              <p class="txt-bold fs-md-5">
                {{ item.email }}
              </p>
            </div>
            <div>
              <p class="fs-md-7 mb-1" v-if="getLang == 'indo'">
                Nomor Handphone
              </p>
              <p class="fs-md-7 mb-1" v-if="getLang == 'uk'">
                Mobile phone number
              </p>
              <p class="txt-bold fs-md-5">
                {{ item.phone }}
              </p>
            </div>
          </div>
        </div>
        <!-- verification -->
        <!-- <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
          <h4 class="txt-bold header-verification fs-md-4 mb-3">Verifikasi</h4>
          <div class="container-card-verification">
            <div class="card-verification mb-lg-3 w-100">
              <img
                src="/assets/img/identitas-verifikasi.png"
                class="illus-verify"
                alt=""
              />
              <div class="content">
                <h3 class="txt-bold fs-md-4">Identitas</h3>
                <button
                      v-if="getVerifIdentity == 'pending'"
                      class="btn txt-bold disabled not-allowed rounded"
                    >
                      Sedang di proses
                    </button>
                    <button
                      v-if="getVerifIdentity == 'active'"
                      class="btn txt-bold disabled not-allowed rounded"
                    >
                      Terverifikasi
                    </button>
                    <a href="/verify-bank">
                      <button
                        v-if="getVerifIdentity == 'false'"
                        class="btn btn-blue txt-bold rounded"
                      >
                        Verifikasi
                      </button>
                    </a>
              </div>
            </div>
            <div class="card-verification">
              <img
                src="/assets/img/bank-verifikasi.png"
                class="illus-verify"
                alt=""
              />
              <div class="content">
                <h3 class="txt-bold fs-md-4">Bank</h3>
                <button
                      v-if="getVerifBank == 'pending'"
                      class="btn txt-bold disabled not-allowed rounded"
                    >
                      Sedang di proses
                    </button>
                    <button
                      v-if="getVerifBank == 'active'"
                      class="btn txt-bold disabled not-allowed rounded"
                    >
                      Terverifikasi
                    </button>
                    <a href="/verify-bank">
                      <button
                        v-if="getVerifBank == 'false'"
                        class="btn btn-blue txt-bold rounded"
                      >
                        Verifikasi
                      </button>
                    </a>
              </div>
            </div>
          </div>
        </div> -->
        <!-- bank detail -->
        <div class="col-lg-4 col-md-12 mt-lg-0 mt-5 bank-detail">
          <div class="d-flex align-items-center header-bank-detail mb-3">
            <h4 class="txt-bold fs-4 mb-0">Bank</h4>
            <!-- <button class="btn btn-blue txt-bold" v-if="getVerifBank != 'active' ">Tambahkan</button> -->
          </div>
          <a href="/verify-bank" v-if="getVerifBank != 'active'">
            <div
              class="
                container-gray
                py-5
                d-flex
                justify-content-center
                align-items-center
              "
              style="cursor: pointer"
            >
              <div class="circle">
                <img src="/assets/img/plus.svg" alt="" />
              </div>
            </div>
          </a>
          <div class="container-gray" v-else>
            <div>
              <p class="fs-md-7 mb-1">Bank</p>
              <p class="txt-bold fs-md-5">
                {{ dataVerifyBank.bank.bank_name }}
              </p>
            </div>
            <div>
              <p class="fs-md-7 mb-1" v-if="getLang == 'indo'">
                Nomor Rekening
              </p>
              <p class="fs-md-7 mb-1" v-if="getLang == 'uk'">Account number</p>
              <p class="txt-bold fs-md-5">
                {{ dataVerifyBank.bank.bank_acc }}
              </p>
            </div>
            <div>
              <p class="fs-md-7 mb-1" v-if="getLang == 'indo'">
                Pemilik Rekening
              </p>
              <p class="fs-md-7 mb-1" v-if="getLang == 'uk'">Account owner</p>
              <p class="txt-bold fs-md-5">
                {{ dataVerifyBank.bank.bank_username }}
              </p>
            </div>
          </div>
        </div>
        <!-- identitas detail -->
        <div class="col-lg-4 col-md-12 mt-lg-0 mt-5 bank-detail">
          <div class="d-flex align-items-center header-bank-detail mb-3">
            <h4 class="txt-bold fs-4 mb-0" v-if="getLang == 'indo'">
              Identitas
            </h4>
            <h4 class="txt-bold fs-4 mb-0" v-if="getLang == 'uk'">Identity</h4>
            <!-- <button class="btn btn-blue txt-bold" v-if="getVerifIdentity != 'active' ">Tambahkan</button> -->
          </div>
          <a href="/identity" v-if="getVerifIdentity != 'active'">
            <div
              class="
                container-gray
                py-5
                d-flex
                justify-content-center
                align-items-center
              "
              style="cursor: pointer"
            >
              <div class="circle">
                <img src="/assets/img/plus.svg" alt="" />
              </div>
            </div>
          </a>
          <div class="container-gray" v-else>
            <div>
              <p class="fs-md-7 mb-1" v-if="getLan == 'indo'">
                Jenis Identitas
              </p>
              <p class="fs-md-7 mb-1" v-if="getLan == 'uk'">Identity Type</p>
              <p class="txt-bold fs-md-5">
                {{ dataVerifyIdentity.type }}
              </p>
            </div>
            <div>
              <p class="fs-md-7 mb-1" v-if="getLan == 'indo'">
                Nomor Identitas
              </p>
              <p class="fs-md-7 mb-1" v-if="getLan == 'uk'">Identity number</p>
              <p class="txt-bold fs-md-5">
                {{ dataVerifyIdentity.idNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";

const userData = JSON.parse(localStorage.getItem("userData"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "profile",
  components: {
    Navbar,
  },
  data() {
    return {
      account: [],
      accountReal: [],
      accountDemo: [],
      getVerifBank: null,
      dataVerifyBank: null,
      getVerifIdentity: null,
      dataVerifyIdentity: null,
      getLang: localStorage.getItem("setLang"),
    };
  },
  mounted() {
    //   account
    var config = {
      method: "get",
      url: `${url}/me`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };
    let self = this;
    console.log("abc");
    axios(config)
      .then(function (response) {
        let arr = response.data.data;
        self.account = [arr];
        // console.log(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
    // verify identity
    var verifIdentity = {
      method: "get",
      url: `${url}/me/verifications?category=IDENTITY&sort[created_at]=desc`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };
    axios(verifIdentity)
      .then(function (res) {
        setTimeout(() => {
          self.loading = false;
        }, 3000);
        let obj = res.data.data.verification.reverse();
        obj.forEach(function (item) {
          if (item.length != 0) {
            if (item.status == "SUCCESS") {
              self.dataVerifyIdentity = item;
              self.getVerifIdentity = "active";
            } else if (
              item.status == "PENDING" &&
              self.getVerifIdentity != "active"
            ) {
              self.getVerifIdentity = "pending";
            }
          } else {
            self.getVerifIdentity = "false";
          }
        });
        console.log("verified success identity: ", obj);
      })
      .catch(function (error) {
        console.log(error);
      });
    // verifikasi Bank
    var verifBank = {
      method: "get",
      url: `${url}/me/verifications?category=PAYMENT&sort[created_at]=desc`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };
    axios(verifBank)
      .then(function (res) {
        setTimeout(() => {
          self.loading = false;
        }, 3000);
        let obj = res.data.data.verification.reverse();
        obj.forEach(function (item) {
          console.log("bank status", item.status);
          if (item.length != 0) {
            if (item.status == "SUCCESS") {
              self.dataVerifyBank = item;
              self.getVerifBank = "active";
            } else if (
              item.status == "PENDING" &&
              self.getVerifBank != "active"
            ) {
              self.getVerifBank = "pending";
            }
          } else {
            self.getVerifBank = "false";
          }
        });
        console.log("verified success Bank: ", self.getVerifBank);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  created() {
    localStorage.setItem("pageNow", "profile");
  },
};
</script>