<template>
  <div id="partnership" class="page">
    <!-- navbar -->
    <Navbar />
    <div class="container">
      <h1
        class="txt-title fs-md-3 fs-4 text-center mb-4"
        v-if="getLang == 'indo'"
      >
        Bergabung Menjadi IB
      </h1>
      <h1
        class="txt-title fs-md-3 fs-4 text-center mb-4"
        v-if="getLang == 'uk'"
      >
        Join IB
      </h1>

    <div class="join-ib-form d-flex justify-content-center mt-5" v-if="getLang == 'indo'">
        <div action="" style="max-width:420px">
            <div class="form-group">
                <label for="">Apakah Anda pernah menjadi IB sebelumnya ?</label>
                <select name="" id="" class="form-control" v-model="sudah_pernah_jadi_ib">
                    <option value="">Pilih salah satu</option>
                    <option value="Ya">Ya</option>
                    <option value="Tidak">Tidak</option>
                </select>
            </div>
            <div class="form-group" v-if="sudah_pernah_jadi_ib == 'Ya' || sudah_pernah_jadi_ib == 'Yes'">
                <label for="">Sudah berapa lama jadi IB?</label>
                <input type="text" class="form-control" v-model="sudah_berapa_lama_jadi_ib" placeholder="6 bulan"> 
            </div>

            <div class="form-group">
                <label for="">Sebutkan alasan Anda, Mengapa ingin bergabung bersama kami untuk menjadi IB di Socrates Legacy, Ltd. ?</label>
                <select name="" id="" class="form-control" v-model="alasan_join">
                    <option value="">Pilih salah satu</option>
                    <option value="Tertarik dengan rate yang tersedia di Socrates Legacy, Ltd. ">Tertarik dengan rate yang tersedia di Socrates Legacy, Ltd. </option>
                    <option value="Tertarik dengan berbagai event yang diadakan oleh Socrates Legacy, Ltd. ">Tertarik dengan berbagai event yang diadakan oleh Socrates Legacy, Ltd. </option>
                    <option value="Tertarik dengan penawaran rebate yang dimiliki Socrates Legacy, Ltd.">Tertarik dengan penawaran rebate yang dimiliki Socrates Legacy, Ltd.</option>
                    <option value="Saya adalah IB Multibroker ">Saya adalah IB Multibroker </option>
                    <option value="Diajak oleh rekan lainnya">Diajak oleh rekan lainnya</option>
                    <option value="Lainya">Lainya</option>
                </select>
            </div>
            <div class="form-group" v-if="alasan_join == 'Lainya' || alasan_join == 'Other'">
                <label for="">Alasan lainya</label>
                <input type="text" class="form-control" placeholder="Sebutkan alasan" v-model="alasan_lainya">
            </div>

             <div class="form-group">
                <label for="">Apakah Anda sudah memiliki followers di dalam sebuah komunitas sebelumnya ?</label>
                <select name="" id="" class="form-control" v-model="punya_komunitas">
                    <option value="">Pilih salah satu</option>
                    <option value="Ya">Ya</option>
                    <option value="Tidak">Tidak</option>
                </select>
            </div>
            <div class="form-group" v-if="punya_komunitas == 'Ya' || punya_komunitas == 'Yes'">
                <label for="">Nama komunitas dan jumlah followers</label>
                <input type="text" class="form-control" placeholder="Andy Trader, 999 followers" v-model="komunitas_saya">
            </div>

            <div class="form-group">
                <label for="">Apa tujuan Anda untuk bergabung bersama kami di Socrates Legacy, Ltd.?</label>
                <select name="" id="" class="form-control" v-model="tujuan_saya">
                    <option value="">Pilih salah satu</option>
                    <option value="Menjadi Master Trader">Menjadi Master Trader</option>
                    <option value="Bekerja sama sebagai EA">Bekerja sama sebagai EA</option>
                    <option value="Mempromosikan semua tipe account dan event di Socrates Legacy, Ltd. ( IB Only )">Mempromosikan semua tipe account dan event di Socrates Legacy, Ltd. ( IB Only )</option>
                </select>
            </div>

            <div class="form-group">
                <label for="">Apakah Anda ingin bergabung menjadi IB bersama kami di Socrates Legacy, Ltd. ?</label>
                <select name="" id="" class="form-control" v-model="ingin_bergabung">
                    <option value="">Pilih salah satu</option>
                    <option value="Ya">Ya</option>
                    <option value="Tidak">Tidak</option>
                </select>
            </div>

            <div class="w-100 mt-5">
                <a href="javascript:void(0)" v-if="ingin_bergabung != 'Ya'" class="btn btn-blue disabled w-100">Bergabung Sekarang</a>
                <a href="javascript:void(0)" v-else @click="requestJoin()" class="btn btn-blue w-100">Bergabung Sekarang</a>
            </div>

        </div>
    </div>
    <div class="join-ib-form d-flex justify-content-center mt-5" v-if="getLang == 'uk'">
        <div action="" style="max-width:420px">
            <div class="form-group">
                <label for="">Have you been an IB before?</label>
                <select name="" id="" class="form-control" v-model="sudah_pernah_jadi_ib">
                    <option value="">Choose one</option>
                    <option value="Ya">Yes</option>
                    <option value="Tidak">No</option>
                </select>
            </div>
            <div class="form-group" v-if="sudah_pernah_jadi_ib == 'Ya' || sudah_pernah_jadi_ib == 'Yes'">
                <label for="">How long have you been an IB?</label>
                <input type="text" class="form-control" v-model="sudah_berapa_lama_jadi_ib" placeholder="6 Month"> 
            </div>

            <div class="form-group">
                <label for="">State your reasons, Why want to join us to become an IB at Socrates Legacy, Ltd. ?</label>
                <select name="" id="" class="form-control" v-model="alasan_join">
                    <option value="">Choose one</option>
                    <option value="Tertarik dengan rate yang tersedia di Socrates Legacy, Ltd. ">Interested in the rates provided at Socrates Legacy, Ltd. </option>
                    <option value="Tertarik dengan berbagai event yang diadakan oleh Socrates Legacy, Ltd. ">Interested in various events held by Socrates Legacy, Ltd. </option>
                    <option value="Tertarik dengan penawaran rebate yang dimiliki Socrates Legacy, Ltd.">Interested in the rebate offer that Socrates Legacy, Ltd. has.</option>
                    <option value="Saya adalah IB Multibroker ">I am a Multibroker IB </option>
                    <option value="Diajak oleh rekan lainnya">Invited by other colleagues</option>
                    <option value="Lainya">Other</option>
                </select>
            </div>
            <div class="form-group" v-if="alasan_join == 'Lainya' || alasan_join == 'Other'">
                <label for="">Other reasons</label>
                <input type="text" class="form-control" placeholder="Other reasons" v-model="alasan_lainya">
            </div>

             <div class="form-group">
                <label for="">Do you already have followers in a community before ?</label>
                <select name="" id="" class="form-control" v-model="punya_komunitas">
                    <option value="">Choose one</option>
                    <option value="Ya">Yes</option>
                    <option value="Tidak">No</option>
                </select>
            </div>
            <div class="form-group" v-if="punya_komunitas == 'Ya' || punya_komunitas == 'Yes'">
                <label for="">Community name and number of followers</label>
                <input type="text" class="form-control" placeholder="Andy Trader, 999 followers" v-model="komunitas_saya">
            </div>

            <div class="form-group">
                <label for="">What is your goal in joining us at Socrates Legacy, Ltd.?</label>
                <select name="" id="" class="form-control" v-model="tujuan_saya">
                    <option value="">Choose one</option>
                    <option value="Menjadi Master Trader">Become a Master Trader</option>
                    <option value="Bekerja sama sebagai EA">Work together as EA</option>
                    <option value="Mempromosikan semua tipe account dan event di Socrates Legacy, Ltd. ( IB Only )">Promote all types of accounts and events in Socrates Legacy, Ltd. (IB Only)</option>
                </select>
            </div>

            <div class="form-group">
                <label for="">Would you like to become an IB with us at Socrates Legacy, Ltd. ?</label>
                <select name="" id="" class="form-control" v-model="ingin_bergabung">
                    <option value="">Choose one</option>
                    <option value="Ya">Yes</option>
                    <option value="Tidak">No</option>
                </select>
            </div>

            <div class="w-100 mt-5">
                <a href="javascript:void(0)" v-if="ingin_bergabung != 'Ya'" class="btn btn-blue disabled w-100">Join Now</a>
                <a href="javascript:void(0)" v-else @click="requestJoin()" class="btn btn-blue w-100">Join Now</a>
            </div>

        </div>
    </div>

    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import Notiflix from "notiflix";
Notiflix.Loading.init({ className:'notiflix-loading', zindex:4000, backgroundColor:'rgba(0,0,0,0.8)', rtl:false, useGoogleFont:false, fontFamily:'Quicksand', cssAnimation:true, cssAnimationDuration:400, clickToClose:false, customSvgUrl:null, svgSize:'80px', svgColor:'#c09a07', messageID:'NotiflixLoadingMessage', messageFontSize:'15px', messageMaxLength:34, messageColor:'#dcdcdc', }); 

const userData = JSON.parse(localStorage.getItem("userData"));

const url_commision = process.env.VUE_APP_API_URL_COMMISION;
const url = process.env.VUE_APP_API_URL;
const rebate_token = process.env.VUE_APP_API_URL_REBATE;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "joinIB",
  components: {
    Navbar,
  },
  data() {
    return {
      BASE_URL_REFERRAL: "https://socrateslegacy.com/register.php?referral=",
      name: userData.user.name,
      referralCode: userData.user.referral_code,
      start_date: "",
      end_date: "",
      quickPick: "",
      uid: userData.user._id,
      userRole: userData.user.role,
      timeNow: "",
      lastWeek: "",
      lastmonth: "",
      lastyear: "",
      dataRebates: [],
      search: false,
      totalUser: 0,
      totalVolume: 0,
      totalRebate: 0,
      uidsebelumnya: "",
      selectDate: false,
      rebateAccount: "",
      getLang: localStorage.getItem("setLang"),
      sudah_pernah_jadi_ib : "",
      sudah_berapa_lama_jadi_ib:"",
      alasan_join:"",
      alasan_lainya:"",
      punya_komunitas:"",
      komunitas_saya:"",
      tujuan_saya:"",
      ingin_bergabung:""
    };
  },
  created() {
    localStorage.setItem("pageNow", "partnership");
    // console.log(userData);
  },
  mounted() {
    window.scrollTo(0, 0);
    // time now
    const getTimeNow = new Date().getTime();
    const timestampNow = new Date(getTimeNow);
    this.timeNow = timestampNow.toISOString();
    // last week
    const getLastweek = getTimeNow - 604800000;
    const timestamplastweek = new Date(getLastweek);
    this.lastWeek = timestamplastweek.toISOString();
    // last month
    const getLastmonth = getTimeNow - 2629743000;
    const timestamplastmonth = new Date(getLastmonth);
    this.lastmonth = timestamplastmonth.toISOString();
    // last year
    const getLastyear = getTimeNow - 31556926000;
    const timestamplastyear = new Date(getLastyear);
    this.lastyear = timestamplastyear.toISOString();
    this.end_date = this.timeNow;
    const loadSatrtdate = getTimeNow - 315569260000;
    const loadtimestamp = new Date(loadSatrtdate);
    this.start_date = loadtimestamp.toISOString();

    let self = this;
    var config = {
      method: "get",
      url: `${url_commision}/v1/rebates?uid=${this.uid}&from=${this.start_date}&to=${this.end_date}&level[min]=1&level[max]=3`,
      headers: {
        "content-type": "application/json",
        "rebate-x-app-token": rebate_token,
        "monolith-x-app-token": token,
        "x-user-token": userData.token,
      },
    };
    axios(config)
      .then(function (res) {
        const respon = res.data.data;
        let _totalVolume = 0;
        let _totalRebate = 0;
        console.log("respon", res);
        if (respon != null) {
          console.log("respon data", respon);
          self.dataRebates = respon;
          let uid_sebelumnya = respon.map((item) => {
            return item.uid;
          });
          const findUid = [...new Set(uid_sebelumnya)];
          console.log("findUid", findUid);
          respon.forEach((item) => {
            _totalRebate += item.rebate;
            _totalVolume += item.volume;
          });

          self.totalUser = findUid.length;
          self.totalVolume = _totalVolume;
          self.totalRebate = _totalRebate;
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
    var configAccount = {
      method: "get",
      url: `${url}/me/accounts`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };

    axios(configAccount)
      .then(function (res) {
        let obj = res.data.data.account.reverse();
        self.rebateAccount = obj.forEach((item) => {
          if (
            item.type == "REBATE" &&
            item.spec.name == "ACCOUNT-REBATE" &&
            item.status != "PENDING"
          ) {
            // if (item.type == "REAL" && item.status != 'PENDING') {
            console.log("item", item);
            return item;
          }
          console.log("obj", obj);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    onresetselectDate() {
      this.selectDate = false;
    },
    formatTime(time) {
      const gettimestamp = new Date(time).getTime();
      const convertTime = new Date(gettimestamp).toLocaleDateString();
      return convertTime;
    },
    QuickPick(e) {
      this.selectDate = true;
      const value = e.target.value;
      console.log(value);
      console.log(this.quickPick);
      this.end_date = this.timeNow;
      if (this.quickPick == "last-week") {
        this.start_date = this.lastWeek;
      } else if (this.quickPick == "last-month") {
        this.start_date = this.lastmonth;
      } else if (this.quickPick == "last-year") {
        this.start_date = this.lastyear;
      }
    },
    onChangeEndDate(e) {
      const res = e.target.value;
      this.end_date = new Date(res).toISOString();
    },
    onChangeStartDate(e) {
      const res = e.target.value;
      this.start_date = new Date(res).toISOString();
    },
    selectRebates() {
      this.search = true;
      let self = this;
      var config = {
        method: "get",
        url: `${url_commision}/v1/rebates?uid=${this.uid}&from=${this.start_date}&to=${this.end_date}&level[min]=1&level[max]=3`,
        headers: {
          "content-type": "application/json",
          "rebate-x-app-token": rebate_token,
          "monolith-x-app-token": token,
          "x-user-token": userData.token,
        },
      };
      axios(config)
        .then(function (res) {
          const respon = res.data.data;
          let _totalVolume = 0;
          let _totalRebate = 0;
          console.log("respon", res);
          if (respon != null) {
            console.log("respon data", respon);
            self.dataRebates = respon;
            let uid_sebelumnya = respon.map((item) => {
              return item.uid;
            });
            const findUid = [...new Set(uid_sebelumnya)];
            console.log("findUid", findUid);
            respon.forEach((item) => {
              _totalRebate += item.rebate;
              _totalVolume += item.volume;
            });

            self.totalUser = findUid.length;
            self.totalVolume = _totalVolume;
            self.totalRebate = _totalRebate;
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    },
    copyText() {
      var copyText = this.$refs.referralCode;
      copyText.select();
      document.execCommand("copy");
      Swal.fire({
        icon: "success",
        title: "Link Berhasil Disalin",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    requestJoin(){
        // validate first
        // sudah pernah join ?
        {
            if(this.sudah_pernah_jadi_ib == 'Ya')
            {
                // cek berapa lama
                if(this.sudah_berapa_lama_jadi_ib == ""){
                    // say it required
                    if(this.getLang == "indo"){
                        return Swal.fire({
                            icon: "error",
                            title: "Semua pertanyaan wajib diisi",
                        })
                    }else{
                        return Swal.fire({
                            icon: "error",
                            title: "All questions are required",
                        })
                    }
                }
            }else if(this.sudah_pernah_jadi_ib == "")
            {
                // say it required
                if(this.getLang == "indo"){
                    return Swal.fire({
                        icon: "error",
                        title: "Semua pertanyaan wajib diisi",
                    })
                }else{
                    return Swal.fire({
                        icon: "error",
                        title: "All questions are required",
                    })
                }
            }
        }
        // alasan join?
        {
            if(this.alasan_join == 'Lainya')
            {
                // cek berapa lama
                if(this.alasan_lainya == ""){
                    // say it required
                    if(this.getLang == "indo"){
                        return Swal.fire({
                            icon: "error",
                            title: "Semua pertanyaan wajib diisi",
                        })
                    }else{
                        return Swal.fire({
                            icon: "error",
                            title: "All questions are required",
                        })
                    }
                }
            }else if(this.alasan_join == "")
            {
                // say it required
                if(this.getLang == "indo"){
                    return Swal.fire({
                        icon: "error",
                        title: "Semua pertanyaan wajib diisi",
                    })
                }else{
                    return Swal.fire({
                        icon: "error",
                        title: "All questions are required",
                    })
                }
            }
        }
        // punya komunitas?
        {
            if(this.punya_komunitas == 'Ya')
            {
                // cek berapa lama
                if(this.komunitas_saya == ""){
                    // say it required
                    if(this.getLang == "indo"){
                        return Swal.fire({
                            icon: "error",
                            title: "Semua pertanyaan wajib diisi",
                        })
                    }else{
                        return Swal.fire({
                            icon: "error",
                            title: "All questions are required",
                        })
                    }
                }
            }else if(this.punya_komunitas == "")
            {
                // say it required
                if(this.getLang == "indo"){
                    return Swal.fire({
                        icon: "error",
                        title: "Semua pertanyaan wajib diisi",
                    })
                }else{
                    return Swal.fire({
                        icon: "error",
                        title: "All questions are required",
                    })
                }
            }
        }
        //tujuan bergabung
        if(this.tujuan_saya == "")
        {
            // say it required
            if(this.getLang == "indo"){
                return Swal.fire({
                    icon: "error",
                    title: "Semua pertanyaan wajib diisi",
                })
            }else{
                return Swal.fire({
                    icon: "error",
                    title: "All questions are required",
                })
            }
        }
        // ingin bergabing
        if(this.ingin_bergabung != "Ya")
        {
            // say it required
            if(this.getLang == "indo"){
                return Swal.fire({
                    icon: "error",
                    title: "Anda harus setuju untuk melanjutkan",
                })
                // const errMessage = error.message;
                // console.log(errMessage);
            }else{
                // return Notiflix.Notify.failure("You must agree to continue")
                return Swal.fire({
                    icon: "error",
                    title: "You must agree to continue",
                })
            }
        }

        let data = {
            "Nama" : userData.user.name,
            "Email" : userData.user.email,
            "No Hp" : userData.user.phone,
            "Apakah sudah pernah jadi IB" : this.sudah_pernah_jadi_ib == 'Ya' ? this.sudah_berapa_lama_jadi_ib  : this.sudah_pernah_jadi_ib,
            "Alasan bergabung jadi IB" : this.alasan_join == "Lainya" ? this.alasan_lainya : this.alasan_join,
            "Komunitas yang dimiliki" : this.punya_komunitas == "Ya" ? this.komunitas_saya : this.punya_komunitas,
            "Tujuan bergabung" : this.tujuan_saya,
            "Ingin bergabung" : this.ingin_bergabung,
            "Language" : this.getLang == "indo" ? "Bahasa Indonesia" : "English"
        }

        // console.log(data);
        Notiflix.Loading.circle();
        let self = this;
        axios.post("https://sheetdb.io/api/v1/f1bsx0z77olr0",{
            "data" : data
        })
        .then(function(){
            // request to orbit
            var config = {
                method: "put",
                url: `${url}/me/upgrade-partner?email=${userData.user.email}`,
                headers: {
                "content-type": "application/json",
                "x-app-token": token,
                "x-user-token": userData.token,
                },
                data: JSON.stringify({ email: userData.user.email, }),
            };
            axios(config)
            .then(function (res) {
                // replace user data
                userData.user.role = res.data.data.role;
                localStorage.setItem("userData",JSON.stringify(userData));
                if(self.getLang == "indo"){
                    Swal.fire({
                        title: 'Selamat Anda telah bergabung menjadi IB Affiliate kami di Socrates Legacy, Ltd.',
                        icon:"success",
                        confirmButtonColor: '#3085d6',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/partnership"
                        } 
                    })
                }else{
                    Swal.fire({
                        title: 'Congratulations for joining our IB Affiliate at Socrates Legacy, Ltd.',
                        icon:"success",
                        confirmButtonColor: '#3085d6',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/partnership"
                        } 
                    })
                }
                console.log(res.data)
            })
            .catch(function (error) {
                const message = error.response.data.error.message;
                Swal.fire({
                    icon: "error",
                    title: "Failed..",
                    text: message,
                    }).then(()=>{
                    window.location.reload();
                    });
                const errMessage = error.message;
                console.log(errMessage);
            })
            .then(function(){
                Notiflix.Loading.remove();
            })
        })
        .catch(function(){
            Notiflix.Notify.failure("Server error");
        })
    }
  },
};
</script>