<template>
    <div id="info-account">
        <!-- navbar -->
        <Navbar/>
        <div class="container">
            <div class="akun-mt5">
                <div class="card-info">
                    <h1 class="txt-title text-center fs-md-4 fs-5 mb-4">
                        Informasi Akun MT5
                    </h1>
                    <div class="header-akun-mt5">
                        <h4 class="txt-title fs-md-6 fs-7">ID AKUN</h4>
                        <p class="fs-md-4 fs-5 txt-bold">14566</p>
                    </div>
                    <div class="detail-akun-mt5">
                        <div class="left">
                            <h4 class="fs-md-5 fs-6 txt-bold">
                                Login MetaTrader
                            </h4>
                            <p class="fs-5">
                                2908959
                            </p>
                        </div>
                        <button class="btn">
                            salin
                        </button>
                    </div>
                    <div class="detail-akun-mt5">
                        <div class="left">
                            <h4 class="fs-md-5 fs-6 txt-bold">
                                Kata Sandi MetaTrader
                            </h4>
                            <p class="fs-5">
                                oApdsfEd
                            </p>
                        </div>
                        <button class="btn">
                            salin
                        </button>
                    </div>
                    <div class="detail-akun-mt5">
                        <div class="left">
                            <h4 class="fs-md-5 fs-6 txt-bold">
                                Server MetaTrader
                            </h4>
                            <p class="fs-5">
                                Akun Real
                            </p>
                        </div>
                    </div>
                    <div class="detail-akun-mt5">
                        <div class="left">
                            <h4 class="fs-md-5 fs-6 txt-bold">
                                IP Server MetaTrader
                            </h4>
                            <p class="fs-5">
                                34.3.33.432:343
                            </p>
                        </div>
                    </div>
                    <div class="detail-akun-mt5">
                        <div class="left">
                            <h4 class="fs-md-5 fs-6 txt-bold">
                                Kata Sandi Investor MT
                            </h4>
                            <p class="fs-5">
                                34.3.33.432:343
                            </p>
                        </div>
                        <button class="btn">
                            salin
                        </button>
                    </div>
                    <div class="detail-akun-mt5">
                        <div class="left">
                            <h4 class="fs-md-5 fs-6 txt-bold">
                                kata Sandi Investor
                            </h4>
                            <p class="fs-5">
                                d2fdsfd
                            </p>
                        </div>
                        <button class="btn">
                            salin
                        </button>
                    </div>
                    <div class="d-flex justify-content-center">
                        <router-link to="/dashboard">
                            <button class="btn btn-blue txt-bold mt-4">
                                Ke Dashboard
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from './components/Navbar.vue'
export default {
    components: {
        Navbar
    }
}
</script>