<template>
  <div id="Dashboard">
    <!-- navbar -->
    <Navbar />
    <!-- header section -->
    <!-- indonesia -->
    <div class="header" v-if="getLang == 'indo'">
      <!-- header slider -->
      <div class="header-slider">
        <!-- div slide1 -->
        <div class="slide1">
          <div
            class="
              container
              d-flex
              flex-column
              h-100
              justify-content-sm-end justify-content-center
              pb-5
            "
          >
            <h1 class="txt-title txt-bold text-center text-lg-left">
              Selamat datang di Socrates Legacy
            </h1>
            <!-- <p class="fs-md-5 text-center text-lg-left">Unduh Sekarang</p>
            <div class="download-app d-flex align-items-center">
              <a href="#" target="_blank" class="mr-xl-5 mb-2 rounded">
                <div class="d-flex align-items-center">
                  <img
                    src="/assets/img/socrates-app.png"
                    class="socrates-app-img"
                    alt=""
                  />
                  <div>
                    <p class="txt-bold m-0">SOCRATES</p>
                    <p class="m-0">Dapatkan di Google Play</p>
                  </div>
                  <img
                    src="/assets/img/play-store.png"
                    class="store-img"
                    alt=""
                  />
                </div>
              </a>
              <a href="#" target="_blank" class="mr-xl-5 mb-2 rounded">
                <div class="d-flex align-items-center">
                  <img
                    src="/assets/img/socrates-app.png"
                    class="socrates-app-img"
                    alt=""
                  />
                  <div>
                    <p class="txt-bold m-0">SOCRATES</p>
                    <p class="m-0">Dapatkan di App Store</p>
                  </div>
                  <img
                    src="/assets/img/app-store.png"
                    class="store-img"
                    alt=""
                  />
                </div>
              </a>
            </div> -->
          </div>
        </div>
        <!-- div slide2 -->
        <!-- <div class="slide2"></div> -->
      </div>
    </div>
    <!-- main content dashboard -->
    <div class="main-content" v-if="getLang == 'indo'">
      <div class="container mt-5">
        <div class="row">
          <!-- left side panel -->
          <div class="col-lg-4">
            <!-- information -->
            <h2 class="txt-title fs-md-4 fs-4 mb-3 d-lg-block d-none">
              Informasi
            </h2>
            <div class="isi-info mb-5 d-lg-block d-none">
              <ul class="m-0">
                <li class="mb-2">
                  Harap tekan "Update" untuk saldo terbaru di Akun Riil sebelum
                  melanjutkan proses penarikan.
                </li>
                <li class="mb-2">
                  Jika akun idle, (tidak ada deposit atau penarikan), saldo Anda
                  akan diperbarui setiap 6 jam.
                </li>
                <li class="mb-2">
                  Setelah Anda melakukan deposit atau penarikan, saldo Anda akan
                  diperbarui setelah diproses oleh sistem kami.
                </li>
              </ul>
            </div>
            <!-- xppoint -->
            <xpPoint v-bind:getAccount="accountReal"></xpPoint>
            <!-- verification section -->
            <div class="verification mb-4 mt-4">
              <h2 class="txt-title mb-3 fs-md-4 fs-4">Verifikasi</h2>
              <div class="container-card-verification">
                <div class="card-verification mb-lg-3">
                  <img
                    src="/assets/img/identitas-verifikasi.png"
                    class="illus-verify"
                    alt=""
                  />
                  <div class="content">
                    <h3 class="txt-bold fs-md-4">Identitas</h3>
                    <button
                      v-if="getVerifIdentity == 'pending'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Sedang di proses
                    </button>
                    <button
                      v-if="getVerifIdentity == 'active'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Terverifikasi
                    </button>
                    <a href="/identity">
                      <button
                        v-if="getVerifIdentity == 'false'"
                        class="btn btn-sm btn-blue txt-bold rounded"
                      >
                        Verifikasi
                      </button>
                    </a>
                  </div>
                </div>
                <div class="card-verification">
                  <img
                    src="/assets/img/bank-verifikasi.png"
                    class="illus-verify"
                    alt=""
                  />
                  <div class="content">
                    <h3 class="txt-bold fs-md-4">Bank</h3>
                    <button
                      v-if="getVerifBank == 'pending'"
                      class="btn txt-bold disabled not-allowed rounded"
                    >
                      Sedang di proses
                    </button>
                    <button
                      v-if="getVerifBank == 'active'"
                      class="btn txt-bold disabled not-allowed rounded"
                    >
                      Terverifikasi
                    </button>
                    <a href="/verify-bank">
                      <button
                        v-if="getVerifBank == 'false'"
                        class="btn btn-blue txt-bold rounded"
                      >
                        Verifikasi
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- platform trading section -->
            <div class="platform-trading d-lg-block d-none">
              <h2 class="txt-title mb-3 fs-md-4 fs-4">Platform Trading</h2>
              <!-- download mt5 for windows -->
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/windows.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Windows</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkWindows"
                >
                  Unduh
                </button>
              </div>
              <!-- download mt5 for ios -->
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/ios.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">IOS</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkIOS"
                >
                  Unduh
                </button>
              </div>
              <!-- download mt5 for android -->
              <div class="card-download">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/android.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Android</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkAndroid"
                >
                  Unduh
                </button>
              </div>
            </div>
          </div>
          <!-- right side panel -->
          <div class="col-lg-8">
            <!-- account section -->
            <div class="acccount">
              <div
                class="
                  d-flex
                  flex-column flex-sm-row
                  align-items-sm-center
                  mb-3
                "
              >
                <h1 class="m-0 mr-4 fs-md-3 fs-4 txt-title">Akun Saya</h1>
                <div class="d-flex mt-2 mt-sm-0">
                  <!-- real -->
                  <router-link to="/create-account">
                    <button
                      v-if="accountReal.length < 5"
                      class="btn btn-blue txt-bold fs-md-5"
                    >
                      Buat Akun Riil
                    </button>
                  </router-link>
                  <button
                    v-if="accountReal.length >= 5"
                    disabled
                    class="btn txt-bold fs-md-5 disabled not-allowed"
                  >
                    Buat Akun Riil
                  </button>
                  <!-- demo -->
                  <router-link to="/create-account-demo">
                    <button
                      v-if="accountDemo.length < 5"
                      class="btn btn-blue txt-bold fs-md-5 ml-3"
                    >
                      Buat Akun Demo
                    </button>
                  </router-link>
                  <button
                    v-if="accountDemo.length >= 5"
                    disabled
                    class="btn txt-bold fs-md-5 ml-3 disabled not-allowed"
                  >
                    Buat Akun Demo
                  </button>
                </div>
              </div>
              <!-- akun asli -->
              <div class="real-account mb-3">
                <p class="fs-md-6 mb-2">Akun Asli</p>
                <!-- card account loading -->
                <div v-if="loading">
                  <div>
                    <div class="card-account mb-3 rounded-primary">
                      <div class="d-flex justify-content-between mb-4">
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader
                          ><VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                      </div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <VueSkeletonLoader :height="20" :width="60" rounded="">
                        </VueSkeletonLoader>
                        <VueSkeletonLoader :height="40" :width="100">
                        </VueSkeletonLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- not loading -->
                <div v-if="!loading">
                  <VueSlickCarousel v-bind="setting">
                    <div
                      v-for="item in accountReal"
                      v-bind:key="item._id"
                      class="pr-4"
                      s
                    >
                      <div
                        v-if="item.length != 0"
                        class="card-account mb-3 rounded-primary"
                      >
                        <div class="d-flex justify-content-between mb-4">
                          <div>
                            <p class="m-0 fs-md-6">ID Akun</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.login_id ? item.login_id : "Tertunda" }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Spesifikasi Akun</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.spec.name }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Saldo</p>
                            <p class="txt-bold m-0 fs-md-5">
                              ${{ item.last_balance }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <p
                            class="txt-blue mb-0 pointer"
                            @click="onDetailAccount(item._id)"
                          >
                            Detail
                          </p>
                          <button
                            class="btn btn-blue rounded"
                            @click="onUpdateBalance(item.login_id)"
                          >
                            Perbarui
                          </button>
                        </div>
                      </div>
                    </div>
                  </VueSlickCarousel>
                </div>
                <!-- kondisi jika akun tidak tersedia -->
                <router-link to="/create-account">
                  <div
                    v-if="accountReal.length == 0 && !loading"
                    class="
                      container-gray
                      py-5
                      d-flex
                      justify-content-center
                      align-items-center
                      card-account
                      rounded-primary
                    "
                    style="cursor: pointer"
                  >
                    <div class="circle-acc">
                      <img src="/assets/img/plus.svg" alt="" />
                    </div>
                  </div>
                </router-link>
                <!-- kondisi jika akun tidak tersedia -->
                <!-- card account -->
              </div>
              <!-- akun demo -->
              <div class="real-account mt-4">
                <p class="fs-md-6 mb-2">Akun Demo</p>
                <!-- card account -->
                <div v-if="loading">
                  <div>
                    <div class="card-account mb-3 rounded-primary">
                      <div class="d-flex justify-content-between mb-4">
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader
                          ><VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                      </div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <VueSkeletonLoader :height="20" :width="60" rounded="">
                        </VueSkeletonLoader>
                        <VueSkeletonLoader :height="40" :width="100">
                        </VueSkeletonLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- not loading -->
                <div>
                  <VueSlickCarousel v-bind="setting">
                    <div
                      v-for="item in accountDemo"
                      v-bind:key="item._id"
                      class="pr-4"
                    >
                      <div
                        v-if="item.length != 0"
                        class="card-account mb-3 rounded-primary"
                      >
                        <div class="d-flex justify-content-between mb-4">
                          <div>
                            <p class="m-0 fs-md-6">ID Akun</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.login_id ? item.login_id : "Tertunda" }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Spesifikasi Akun</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.spec.name }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Saldo</p>
                            <p class="txt-bold m-0 fs-md-5">
                              ${{ item.last_balance }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <p
                            class="txt-blue mb-0 pointer"
                            @click="onDetailAccount(item._id)"
                          >
                            Detail
                          </p>
                          <button
                            class="btn btn-blue rounded"
                            @click="onUpdateBalance(item.login_id)"
                          >
                            Perbarui
                          </button>
                        </div>
                      </div>
                    </div>
                  </VueSlickCarousel>
                </div>
                <!-- kondisi jika akun tidak tersedia -->
                <router-link to="/create-account-demo">
                  <div
                    v-if="accountDemo.length == 0 && !loading"
                    class="
                      container-gray
                      py-5
                      d-flex
                      justify-content-center
                      align-items-center
                      card-account
                      rounded-primary
                    "
                    style="cursor: pointer"
                  >
                    <div class="circle-acc">
                      <img src="/assets/img/plus.svg" alt="" />
                    </div>
                  </div>
                </router-link>
                <!-- kondisi jika akun tidak tersedia -->
              </div>
              <!-- detail account -->
              <div class="detail-account" v-if="detailAccount">
                <div
                  class="content-detail-account rounded-primary"
                  v-for="item in dataDetailAccount"
                  v-bind:key="item._id"
                >
                  <h2 class="txt-title text-center fs-md-3 fs-4 mb-3">
                    Account Detail
                  </h2>
                  <div class="header">
                    <h4 class="fs-md-5 fs-6 mb-1">AKUN ID</h4>
                    <p class="txt-bold fs-md-5 fs-6">
                      {{ item.login_id ? item.login_id : "Tertunda" }}
                    </p>
                  </div>
                  <div class="detail">
                    <h4>Account Type</h4>
                    <p>{{ item.type }}</p>
                  </div>
                  <div class="detail">
                    <h4>Leverage</h4>
                    <p>{{ item.leverage }}</p>
                  </div>
                  <div class="detail">
                    <h4>Saldo</h4>
                    <p>{{ item.last_balance }}</p>
                  </div>
                  <div class="detail">
                    <h4>Terakhir Diperbarui</h4>
                    <p>{{ timeStamp(item.updated_at) }}</p>
                  </div>
                  <div class="footer-detail-account">
                    <button
                      @click="onUpdateBalance(item.login_id)"
                      class="btn btn-blue txt-bold"
                    >
                      Perbarui Saldo
                    </button>
                  </div>
                  <div
                    class="close-btn"
                    @click="detailAccount = !detailAccount"
                  >
                    <img src="/assets/img/icon-close.svg" alt="close" />
                  </div>
                </div>
              </div>
              <!-- detail account -->
            </div>
            <!-- history payment section -->
            <div class="history-payment mt-5 mb-5">
              <h1 class="txt-title fs-md-3 fs-4 mb-4">Riwayat Transaksi</h1>
              <!-- loading -->
              <div class="table-history" v-if="loading">
                <table class="table">
                  <thead class="fs-md-7">
                    <tr>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="fs-md-7">
                    <!-- thisss -->
                    <tr class="justify-content-center">
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="40" :width="80">
                        </VueSkeletonLoader>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- not loading -->
              <div class="table-history" v-if="!loading">
                <table class="table text-center align-middle">
                  <thead class="bg_black fs-7">
                    <tr>
                      <th clasName="bg_black">ID akun</th>
                      <th clasName="bg_black">Tipe Akun</th>
                      <th clasName="bg_black">Status</th>
                      <th clasName="bg_black">Metode</th>
                      <th clasName="bg_black">Jumlah</th>
                      <th clasName="bg_black">Komentar</th>
                      <th clasName="bg_black">Waktu</th>
                      <th clasName="bg_black">Aksi</th>
                    </tr>
                  </thead>
                  <HistoryPayment
                    v-for="item in historyPaymentShow"
                    :key="item._id"
                    v-bind:item="item"
                  ></HistoryPayment>
                  <tr v-if="historyPaymentShow.length == 0">
                    <td colspan="8" class="text-center">Data tidak tersedia</td>
                  </tr>
                </table>
                <!-- thisss -->
                <!-- <div v-for="item in historyPaymentShow" :key="item.type"> -->
                <!-- </div> -->
                <table class="table text-center align-middle">
                  <tr v-if="historyPayment.length > 5">
                    <td colspan="8">
                      <div class="d-flex justify-content-between">
                        Menampilkan {{ (endPagination + 1) / 5 }} /
                        {{ Math.ceil(historyPayment.length / 5) }}
                        <div class="d-flex justify-content-end">
                          <button
                            href=""
                            v-if="startPagination != 0"
                            @click="prevPagination()"
                            class="fs-7 btn btn-white mr-1"
                          >
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-left"
                            ></span>
                            Sebelumya
                          </button>
                          <button
                            href=""
                            v-else
                            disabled
                            class="fs-7 btn btn-white mr-1"
                          >
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-left"
                            ></span>
                            Sebelumya
                          </button>
                          <button
                            href=""
                            v-if="historyPayment.length > endPagination"
                            @click="nextPagination()"
                            class="fs-7 btn btn-white"
                          >
                            Lanjut
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-right"
                            ></span>
                          </button>
                          <button
                            href=""
                            v-else
                            disabled
                            class="fs-7 btn btn-white"
                          >
                            Lanjut
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-right"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- coba -->

            <!-- information display when large display -->
            <h2 class="txt-title mb-3 fs-md-3 fs-4 d-lg-none d-block">
              Informasi
            </h2>
            <div class="isi-info mb-5 d-lg-none d-block rounded-primary">
              <ul class="m-0">
                <li class="mb-2">
                  Harap tekan "Update" untuk saldo terbaru di Akun Riil sebelum
                  melanjutkan proses penarikan.
                </li>
                <li class="mb-2">
                  Jika akun idle, (tidak ada deposit atau penarikan), saldo Anda
                  akan diperbarui setiap 6 jam.
                </li>
                <li class="mb-2">
                  Setelah Anda melakukan deposit atau penarikan, saldo Anda akan
                  diperbarui setelah diproses oleh sistem kami.
                </li>
              </ul>
            </div>
            <!-- platform trading section display when large display -->
            <div class="platform-trading d-lg-none d-block">
              <h2 class="txt-title fs-md-3 fs-4 mb-3">Platform Trading</h2>
              <!-- download mt5 for windows -->
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/windows.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Windows</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkWindows"
                >
                  Unduh
                </button>
              </div>
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/ios.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">IOS</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkIOS"
                >
                  Unduh
                </button>
              </div>
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/android.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Android</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkAndroid"
                >
                  Unduh
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- english -->
    <div class="header" v-if="getLang == 'uk'">
      <!-- header slider -->
      <div class="header-slider">
        <!-- div slide1 -->
        <div class="slide1">
          <div
            class="
              container
              d-flex
              flex-column
              h-100
              justify-content-sm-end justify-content-center
              pb-5
            "
          >
            <h1 class="txt-title txt-bold text-center text-lg-left">
              Welcome To Socrates Legacy
            </h1>
            <!-- <p class="fs-md-5 text-center text-lg-left">Download Now</p>
            <div class="download-app d-flex align-items-center">
              <a href="#" target="_blank" class="mr-xl-5 mb-2 rounded">
                <div class="d-flex align-items-center">
                  <img
                    src="/assets/img/socrates-app.png"
                    class="socrates-app-img"
                    alt=""
                  />
                  <div>
                    <p class="txt-bold m-0">SOCRATES</p>
                    <p class="m-0">Get now in Google Play</p>
                  </div>
                  <img
                    src="/assets/img/play-store.png"
                    class="store-img"
                    alt=""
                  />
                </div>
              </a>
              <a href="#" target="_blank" class="mr-xl-5 mb-2 rounded">
                <div class="d-flex align-items-center">
                  <img
                    src="/assets/img/socrates-app.png"
                    class="socrates-app-img"
                    alt=""
                  />
                  <div>
                    <p class="txt-bold m-0">SOCRATES</p>
                    <p class="m-0">Get now in App Store</p>
                  </div>
                  <img
                    src="/assets/img/app-store.png"
                    class="store-img"
                    alt=""
                  />
                </div>
              </a>
            </div> -->
          </div>
        </div>
        <!-- div slide2 -->
        <!-- <div class="slide2"></div> -->
      </div>
    </div>
    <!-- main content dashboard -->
    <div class="main-content" v-if="getLang == 'uk'">
      <div class="container mt-5">
        <div class="row">
          <!-- left side panel -->
          <div class="col-lg-4">
            <!-- information -->
            <h2 class="txt-title fs-md-4 fs-4 mb-3 d-lg-block d-none">
              Information
            </h2>
            <div class="isi-info mb-5 d-lg-block d-none">
              <ul class="m-0">
                <li class="mb-2">
                  Please update your balance in Real Account before proceeding
                  with balance withdrawal.
                </li>
                <li class="mb-2">
                  If the account is idle, (no deposit or withdrawal), your
                  balance will be updated every 6 hours.
                </li>
                <li class="mb-2">
                  After you make a deposit or withdrawal, your balance will be
                  updated once it is processed by our system.
                </li>
              </ul>
            </div>
            <!-- xppoint -->
            <xpPoint v-bind:getAccount="accountReal"></xpPoint>
            <!-- verification section -->
            <div class="verification mb-4 mt-4">
              <h2 class="txt-title mb-3 fs-md-4 fs-4">Verifications</h2>
              <div class="container-card-verification">
                <!-- profile -->
                <div class="card-verification mb-lg-3">
                  <img
                    src="/assets/img/identitas-verifikasi.png"
                    class="illus-verify"
                    alt=""
                  />
                  <div class="content">
                    <h3 class="txt-bold fs-md-4">Identity</h3>
                    <button
                      v-if="getVerifIdentity == 'pending'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Being processed
                    </button>
                    <button
                      v-if="getVerifIdentity == 'active'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Verified
                    </button>
                    <a href="/identity">
                      <button
                        v-if="getVerifIdentity == 'false'"
                        class="btn btn-sm btn-blue txt-bold rounded"
                      >
                        Verification
                      </button>
                    </a>
                  </div>
                </div>
                <!-- bank -->
                <div class="card-verification mb-lg-3">
                  <img
                    src="/assets/img/bank-verifikasi.png"
                    class="illus-verify"
                    alt=""
                  />
                  <div class="content">
                    <h3 class="txt-bold fs-md-4">Bank</h3>
                    <button
                      v-if="getVerifBank == 'pending'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Being processed
                    </button>
                    <button
                      v-if="getVerifBank == 'active'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Verified
                    </button>
                    <a href="/verify-bank">
                      <button
                        v-if="getVerifBank == 'false'"
                        class="btn btn-sm btn-blue txt-bold rounded"
                      >
                        Verification
                      </button>
                    </a>
                  </div>
                </div>
                <!-- address -->
                <div class="card-verification">
                  <img
                    src="/assets/img/address.jpeg"
                    class="illus-verify"
                    alt=""
                  />
                  <div class="content">
                    <h3 class="txt-bold fs-md-4">Proof of Address</h3>
                    <button
                      v-if="getVerifPoa == 'pending'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Being processed
                    </button>
                    <button
                      v-if="getVerifPoa == 'active'"
                      class="btn btn-sm txt-bold disabled not-allowed rounded"
                    >
                      Verified
                    </button>
                    <a href="/verify-poa">
                      <button
                        v-if="getVerifPoa == 'false'"
                        class="btn btn-sm btn-blue txt-bold rounded"
                      >
                        Verification
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- platform trading section -->
            <div class="platform-trading d-lg-block d-none">
              <h2 class="txt-title mb-3 fs-md-4 fs-4">Trading Platforms</h2>
              <!-- download mt5 for windows -->
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/windows.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Windows</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkWindows"
                >
                  Download
                </button>
              </div>
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/ios.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">IOS</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkIOS"
                >
                  Download
                </button>
              </div>
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/android.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Android</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkAndroid"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
          <!-- right side panel -->
          <div class="col-lg-8">
            <!-- account section -->
            <div class="acccount">
              <div
                class="
                  d-flex
                  flex-column flex-sm-row
                  align-items-sm-center
                  mb-3
                "
              >
                <h1 class="m-0 mr-4 fs-md-3 fs-4 txt-title">My Account</h1>
                <div class="d-flex mt-2 mt-sm-0">
                  <!-- real -->
                  <router-link to="/create-account">
                    <button
                      v-if="accountReal.length < 5"
                      class="btn btn-blue txt-bold fs-md-5"
                    >
                      Create Real Account
                    </button>
                  </router-link>
                  <button
                    v-if="accountReal.length >= 5"
                    disabled
                    class="btn txt-bold fs-md-5 disabled not-allowed"
                  >
                    Create Real Account
                  </button>
                  <!-- demo -->
                  <router-link to="/create-account-demo">
                    <button
                      v-if="accountDemo.length < 5"
                      class="btn btn-blue txt-bold fs-md-5 ml-3"
                    >
                      Create Demo Account
                    </button>
                  </router-link>
                  <button
                    v-if="accountDemo.length >= 5"
                    disabled
                    class="btn txt-bold fs-md-5 ml-3 disabled not-allowed"
                  >
                    Create Demo Account
                  </button>
                </div>
              </div>
              <!-- akun asli -->
              <div class="real-account mb-3">
                <p class="fs-md-6 mb-2">Real Account</p>
                <!-- card account loading -->
                <div v-if="loading">
                  <div>
                    <div class="card-account mb-3 rounded-primary">
                      <div class="d-flex justify-content-between mb-4">
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader
                          ><VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                      </div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <VueSkeletonLoader :height="20" :width="60" rounded="">
                        </VueSkeletonLoader>
                        <VueSkeletonLoader :height="40" :width="100">
                        </VueSkeletonLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- not loading -->
                <div v-if="!loading">
                  <VueSlickCarousel v-bind="setting">
                    <div
                      v-for="item in accountReal"
                      v-bind:key="item._id"
                      class="pr-4"
                      s
                    >
                      <div
                        v-if="item.length != 0"
                        class="card-account mb-3 rounded-primary"
                      >
                        <div class="d-flex justify-content-between mb-4">
                          <div>
                            <p class="m-0 fs-md-6">Account ID</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.login_id ? item.login_id : "Pending" }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Account Specification</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.spec.name }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Balance</p>
                            <p class="txt-bold m-0 fs-md-5">
                              ${{ item.last_balance }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <p
                            class="txt-blue mb-0 pointer"
                            @click="onDetailAccount(item._id)"
                          >
                            Detail
                          </p>
                          <button
                            class="btn btn-blue rounded"
                            @click="onUpdateBalance(item.login_id)"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </VueSlickCarousel>
                </div>
                <!-- kondisi jika akun tidak tersedia -->
                <router-link to="/create-account">
                  <div
                    v-if="accountReal.length == 0 && !loading"
                    class="
                      container-gray
                      py-5
                      d-flex
                      justify-content-center
                      align-items-center
                      card-account
                      rounded-primary
                    "
                    style="cursor: pointer"
                  >
                    <div class="circle-acc">
                      <img src="/assets/img/plus.svg" alt="" />
                    </div>
                  </div>
                </router-link>
                <!-- kondisi jika akun tidak tersedia -->
                <!-- card account -->
              </div>
              <!-- akun demo -->
              <div class="real-account mt-4">
                <p class="fs-md-6 mb-2">Demo Account</p>
                <!-- card account -->
                <div v-if="loading">
                  <div>
                    <div class="card-account mb-3 rounded-primary">
                      <div class="d-flex justify-content-between mb-4">
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                          <VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                        <div>
                          <VueSkeletonLoader
                            :height="20"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader
                          ><VueSkeletonLoader
                            class="mt-2"
                            :height="15"
                            :width="80"
                            rounded=""
                          >
                          </VueSkeletonLoader>
                        </div>
                      </div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <VueSkeletonLoader :height="20" :width="60" rounded="">
                        </VueSkeletonLoader>
                        <VueSkeletonLoader :height="40" :width="100">
                        </VueSkeletonLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- not loading -->
                <div v-if="!loading">
                  <VueSlickCarousel v-bind="setting">
                    <div
                      v-for="item in accountDemo"
                      v-bind:key="item._id"
                      class="pr-4"
                    >
                      <div
                        v-if="item.length != 0"
                        class="card-account mb-3 rounded-primary"
                      >
                        <div class="d-flex justify-content-between mb-4">
                          <div>
                            <p class="m-0 fs-md-6">Account ID</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.login_id ? item.login_id : "Pending" }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Account Specification</p>
                            <p class="txt-bold m-0 fs-md-5">
                              {{ item.spec.name }}
                            </p>
                          </div>
                          <div>
                            <p class="m-0 fs-md-6">Balance</p>
                            <p class="txt-bold m-0 fs-md-5">
                              ${{ item.last_balance }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <p
                            class="txt-blue mb-0 pointer"
                            @click="onDetailAccount(item._id)"
                          >
                            Detail
                          </p>
                          <button
                            class="btn btn-blue rounded"
                            @click="onUpdateBalance(item.login_id)"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </VueSlickCarousel>
                </div>
                <!-- kondisi jika akun tidak tersedia -->
                <router-link to="/create-account-demo">
                  <div
                    v-if="accountDemo.length == 0 && !loading"
                    class="
                      container-gray
                      py-5
                      d-flex
                      justify-content-center
                      align-items-center
                      card-account
                      rounded-primary
                    "
                    style="cursor: pointer"
                  >
                    <div class="circle-acc">
                      <img src="/assets/img/plus.svg" alt="" />
                    </div>
                  </div>
                </router-link>
                <!-- kondisi jika akun tidak tersedia -->
              </div>
              <!-- detail account -->
              <div class="detail-account" v-if="detailAccount">
                <div
                  class="content-detail-account rounded-primary"
                  v-for="item in dataDetailAccount"
                  v-bind:key="item._id"
                >
                  <h2 class="txt-title text-center fs-md-3 fs-4 mb-3">
                    Detail Account
                  </h2>
                  <div class="header">
                    <h4 class="fs-md-5 fs-6 mb-1">Account ID</h4>
                    <p class="txt-bold fs-md-5 fs-6">
                      {{ item.login_id ? item.login_id : "Pending" }}
                    </p>
                  </div>
                  <div class="detail">
                    <h4>Account Type</h4>
                    <p>{{ item.type }}</p>
                  </div>
                  <div class="detail">
                    <h4>Leverage</h4>
                    <p>{{ item.leverage }}</p>
                  </div>
                  <div class="detail">
                    <h4>Balance</h4>
                    <p>{{ item.last_balance }}</p>
                  </div>
                  <div class="detail">
                    <h4>Last Update</h4>
                    <p>{{ timeStamp(item.updated_at) }}</p>
                  </div>
                  <div class="footer-detail-account">
                    <button
                      @click="onUpdateBalance(item.login_id)"
                      class="btn btn-blue txt-bold"
                    >
                      Update Balance
                    </button>
                  </div>
                  <div
                    class="close-btn"
                    @click="detailAccount = !detailAccount"
                  >
                    <img src="/assets/img/icon-close.svg" alt="close" />
                  </div>
                </div>
              </div>
              <!-- detail account -->
            </div>
            <!-- history payment section -->
            <div class="history-payment mt-5 mb-5">
              <h1 class="txt-title fs-md-3 fs-4 mb-4">History Payment</h1>
              <!-- loading -->
              <div class="table-history" v-if="loading">
                <table class="table">
                  <thead class="fs-md-7">
                    <tr>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="fs-md-7">
                    <!-- thisss -->
                    <tr class="justify-content-center">
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="20" :width="80" rounded="">
                        </VueSkeletonLoader>
                      </th>
                      <th>
                        <VueSkeletonLoader :height="40" :width="80">
                        </VueSkeletonLoader>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- not loading -->
              <div class="table-history" v-if="!loading">
                <table class="table text-center align-middle">
                  <thead class="bg_black fs-7">
                    <tr>
                      <th clasName="bg_black">Account ID</th>
                      <th clasName="bg_black">Account type</th>
                      <th clasName="bg_black">Status</th>
                      <th clasName="bg_black">Method</th>
                      <th clasName="bg_black">Total</th>
                      <th clasName="bg_black">Comment</th>
                      <th clasName="bg_black">Timer</th>
                      <th clasName="bg_black">Action</th>
                    </tr>
                  </thead>
                  <HistoryPayment
                    v-for="item in historyPaymentShow"
                    :key="item._id"
                    v-bind:item="item"
                  ></HistoryPayment>
                  <tr v-if="historyPaymentShow.length == 0">
                    <td colspan="8" class="text-center">Data not available</td>
                  </tr>
                </table>
                <!-- thisss -->
                <!-- <div v-for="item in historyPaymentShow" :key="item.type"> -->
                <!-- </div> -->
                <table class="table text-center align-middle">
                  <tr v-if="historyPayment.length > 5">
                    <td colspan="8">
                      <div class="d-flex justify-content-between">
                        <div>
                          Showing {{ (endPagination + 1) / 5 }} /
                          {{ Math.ceil(historyPayment.length / 5) }}
                        </div>
                        <div class="d-flex justify-content-end">
                          <button
                            href=""
                            v-if="startPagination != 0"
                            @click="prevPagination()"
                            class="fs-7 btn btn-white mr-1"
                          >
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-left"
                            ></span>
                            Prev
                          </button>
                          <button
                            href=""
                            v-else
                            disabled
                            class="fs-7 btn btn-white mr-1"
                          >
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-left"
                            ></span>
                            Prev
                          </button>
                          <button
                            href=""
                            v-if="historyPayment.length > endPagination"
                            @click="nextPagination()"
                            class="fs-7 btn btn-white"
                          >
                            Next
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-right"
                            ></span>
                          </button>
                          <button
                            href=""
                            v-else
                            disabled
                            class="fs-7 btn btn-white"
                          >
                            Next
                            <span
                              class="iconify"
                              data-icon="bytesize:arrow-right"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- coba -->

            <!-- information display when large display
            <h2 class="txt-title fs-md-4 fs-4 mb-3 d-lg-block d-none">
              Information
            </h2>
            <div class="isi-info mb-5 d-lg-block d-none">
              <ul class="m-0">
                <li class="mb-2">
                  Please update your balance in Real Account before proceeding with balance withdrawal.
                </li>
                <li class="mb-2">
                  If the account is idle, (no deposit or withdrawal), your balance will be updated every 6 hours.
                </li>
                <li class="mb-2">
                  After you make a deposit or withdrawal, your balance will be updated once it is processed by our system.
                </li>
              </ul>
            </div> -->
            <!-- Trading Platforms section display when large display -->
            <div class="platform-trading d-lg-none d-block">
              <h2 class="txt-title fs-md-3 fs-4 mb-3">Trading Platforms</h2>
              <!-- download mt5 for windows -->
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/windows.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Windows</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkWindows"
                >
                  Download
                </button>
              </div>
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/ios.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">IOS</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkIOS"
                >
                  Download
                </button>
              </div>
              <div class="card-download mb-3">
                <div class="d-flex justify-content-center">
                  <img
                    src="/assets/img/android.png"
                    class="img-platform mr-2"
                    alt=""
                  />
                  <p class="fs-md-5 m-0 mt-2">Android</p>
                </div>
                <button
                  class="btn-download txt-bold text-center"
                  @click="LinkAndroid"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Vue from "vue";
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import VueSkeletonLoader from "skeleton-loader-vue";
import VueSlickCarousel from "vue-slick-carousel";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import xpPoint from "./components/xpPoint";
import HistoryPayment from "./HistoryPayment";
import Notiflix from "notiflix";
Notiflix.Loading.init({
  className: "notiflix-loading",
  zindex: 4000,
  backgroundColor: "rgba(0,0,0,0.8)",
  rtl: false,
  useGoogleFont: false,
  fontFamily: "Quicksand",
  cssAnimation: true,
  cssAnimationDuration: 400,
  clickToClose: false,
  customSvgUrl: null,
  svgSize: "80px",
  svgColor: "#c09a07",
  messageID: "NotiflixLoadingMessage",
  messageFontSize: "15px",
  messageMaxLength: 34,
  messageColor: "#dcdcdc",
});

const userData = JSON.parse(localStorage.getItem("userData"));
const isLogin = JSON.parse(localStorage.getItem("isLogin"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  components: {
    HistoryPayment,
    Navbar,
    xpPoint,
    VueSkeletonLoader,
    VueSlickCarousel,
  },
  data() {
    return {
      account: [],
      accountReal: [],
      accountDemo: [],
      historyPayment: [],
      historyPaymentShow: [],
      startPagination: 0,
      endPagination: 4,
      paginationMultiply: 5,
      detailAccount: false,
      dataDetailAccount: [],
      getVerifBank: "",
      getVerifPoa: "",
      getVerifIdentity: "",
      TransaksiExpired: false,
      timeNow: null,
      timeUpdate: "",
      loading: true,
      setting: {
        dots: true,
        slidesToShow: 1,
        variableWidth: true,
        arrows: false,
      },
      getLang: localStorage.getItem("setLang"),
    };
  },
  created() {
    window.scrollTo(0, 0);
    localStorage.setItem("pageNow", "dashboard");
  },
  methods: {
    prevPagination: function () {
      this.startPagination = this.startPagination - this.paginationMultiply;
      this.endPagination = this.endPagination - this.paginationMultiply;
      this.updateHistoryShow(
        this.historyPayment,
        this.startPagination,
        this.endPagination
      );
    },
    nextPagination: function () {
      this.startPagination = this.startPagination + this.paginationMultiply;
      this.endPagination = this.endPagination + this.paginationMultiply;
      this.updateHistoryShow(
        this.historyPayment,
        this.startPagination,
        this.endPagination
      );
    },
    updateHistoryShow: function (argument, start, end) {
      var theArray = [];
      argument.forEach((item, index) => {
        if (index >= start && index <= end) {
          theArray.push(item);
        }
      });
      this.historyPaymentShow = theArray;
    },
    timeStamp(lastTime) {
      const lastTimer = new Date(lastTime).getTime();
      return new Date(lastTimer).toLocaleString();
    },
    // setTimerPayment: function () {
    setTimerPayment: function (item) {
      // waktu dibuat
      const waktuDibuat = new Date(item).getTime() + 10800000;

      // waktu sekarang
      this.timeNow = new Date().getTime();
      const selisih = waktuDibuat - this.timeNow;
      var jam = Math.floor(
        (selisih % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var menit = Math.floor((selisih % (1000 * 60 * 60)) / (1000 * 60));
      var detik = Math.floor((selisih % (1000 * 60)) / 1000);

      if (selisih > 60000) {
        return `0${jam} : ${menit} : ${detik}`;
      } else {
        this.TransaksiExpired == true;
        return "Expired";
      }
    },
    // detail account methods
    onDetailAccount: function (myid) {
      this.detailAccount = !this.detailAccount;
      Notiflix.Loading.circle();
      var config = {
        method: "get",
        url: `${url}/account/${myid}`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };
      let self = this;
      axios(config)
        .then(function (res) {
          let obj = res.data.data.account;
          self.dataDetailAccount = [obj];
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          Notiflix.Loading.remove();
        });
    },
    // moment: function (date) {
    //   const fdate = new Date(date).getTime();
    //   moment(fdate).format("MMMM Do YYYY [at] h:mm:ss a");
    // },
    onUpdateBalance(item) {
      // console.log(item)
      var config = {
        method: "get",
        url: `${url}/account/sync-balance?login_id=${item}`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };
      console.log(item);
      // let self = this;
      Notiflix.Loading.circle({
        className: "notiflix-loading",
        svgColor: "#c09a07",
      });
      axios(config)
        .then(function (res) {
          console.log("update balance", res.data);
          window.location.reload();
          // Notiflix.Notify.success("hello");
        })
        .catch(function (error) {
          console.log(error.response.data);
        })
        .then(function () {
          Notiflix.Loading.remove();
        });
    },

    LinkWindows() {
      window.open(
        "https://download.mql5.com/cdn/web/18426/mt5/socratesmt5.exe"
      );
    },
    LinkAndroid() {
      window.open(
        "https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5"
      );
    },
    LinkIOS() {
      window.open("https://apps.apple.com/us/app/metatrader-5/id413251709");
    },
    onResetPassword() {
      window.location.href = "/reset-password";
    },
  },
  // beforeDestroy () {
  //   if(this.TransaksiExpired == true ){
  //     clearInterval(this.timeUpdate);
  //   }
  // },
  mounted() {
    const getLang = localStorage.getItem("setLang");
    if (!getLang) {
      localStorage.setItem("setLang", "uk");
    }
    let self = this;
    this.loading = true;
    console.log(isLogin);
    if (isLogin == true || userData) {
      var getHistoryPayments = {
        method: "get",
        url: `${url}/me/payments`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };

      axios(getHistoryPayments)
        .then(function (response) {
          setTimeout(() => {
            self.loading = false;
          }, 3000);
          let arr = response.data.data.payment.reverse();
          self.historyPayment = arr;
          console.log("historyPaymentlength", arr.length);
          // update historyPaymentShow
          self.updateHistoryShow(arr, self.startPagination, self.endPagination);
        })
        .catch(function (error) {
          console.log(error);
        });

      var configAccount = {
        method: "get",
        url: `${url}/me/accounts`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };

      axios(configAccount)
        .then(function (res) {
          setTimeout(() => {
            self.loading = false;
          }, 3000);
          let obj = res.data.data.account.reverse();
          self.account = obj;
          self.accountDemo = obj.filter((item) => {
            if (item.type == "DEMO") {
              return item;
            }
          });
          self.accountReal = obj.filter((item) => {
            if (item.type != "DEMO") {
              return item;
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      // verify identity
      var verifIdentity = {
        method: "get",
        url: `${url}/me/verifications?category=IDENTITY&sort[created_at]=desc`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };
      axios(verifIdentity)
        .then(function (res) {
          setTimeout(() => {
            self.loading = false;
          }, 3000);
          let obj = res.data.data.verification;
          if (obj.length == 0) {
            self.getVerifIdentity = "false";
          } else {
            obj.forEach(function (item) {
              if (item.status == "SUCCESS") {
                self.getVerifIdentity = "active";
              } else if (
                item.status == "PENDING" &&
                self.getVerifIdentity != "active"
              ) {
                self.getVerifIdentity = "pending";
              } else if (
                item.status != "PENDING" ||
                (item.status != "SUCCESS" &&
                  self.getVerifIdentity != "active" &&
                  self.getVerifIdentity != "pending")
              ) {
                self.getVerifIdentity = "false";
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // verifikasi Bank
      var verifBank = {
        method: "get",
        url: `${url}/me/verifications?category=PAYMENT&sort[created_at]=desc`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };
      axios(verifBank)
        .then(function (res) {
          setTimeout(() => {
            self.loading = false;
          }, 3000);
          let obj = res.data.data.verification.reverse();
          if (obj.length == 0) {
            self.getVerifBank = "false";
            localStorage.setItem("verifbank", false);
          } else {
            console.log("verifBank:", obj);
            obj.forEach(function (item) {
              if (item.status == "SUCCESS") {
                const getdatabank = obj[0].bank;
                const dataBank = {
                  bank_name: getdatabank.bank_name,
                  bank_acc: getdatabank.bank_acc,
                  bank_username: getdatabank.bank_username,
                };
                localStorage.setItem("databank", dataBank);
                self.getVerifBank = "active";
                localStorage.setItem("verifbank", true);
              } else if (
                item.status == "PENDING" &&
                self.getVerifBank != "active"
              ) {
                self.getVerifBank = "pending";
                localStorage.setItem("verifbank", false);
              } else if (
                item.status != "PENDING" ||
                (item.status != "SUCCESS" &&
                  self.getVerifBank != "active" &&
                  self.getVerifBank != "pending")
              ) {
                self.getVerifBank = "false";
                localStorage.setItem("verifbank", true);
              }
              console.log("verifBank:", localStorage.getItem("verifbank"));
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // verify poa
      var verifPoA = {
        method: "get",
        url: `${url}/me/verifications?category=PROOF_ADDRESS&sort[created_at]=desc`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };
      axios(verifPoA)
        .then(function (res) {
          setTimeout(() => {
            self.loading = false;
          }, 3000);
          let obj = res.data.data.verification.reverse();
          if (obj.length == 0) {
            self.getVerifPoa = "false";
            localStorage.setItem("verifpoa", false);
          } else {
            console.log("verifpoa:", obj);
            obj.forEach(function (item) {
              if (item.status == "SUCCESS") {
                self.getVerifPoa = "active";
                localStorage.setItem("verifpoa", true);
              } else if (
                item.status == "PENDING" &&
                self.getVerifPoa != "active"
              ) {
                self.getVerifPoa = "pending";
                localStorage.setItem("verifpoa", false);
              } else if (
                item.status != "PENDING" ||
                (item.status != "SUCCESS" &&
                  self.getVerifPoa != "active" &&
                  self.getVerifPoa != "pending")
              ) {
                self.getVerifPoa = "false";
                localStorage.setItem("verifpoa", true);
              }
              console.log("verifpoa:", localStorage.getItem("verifpoa"));
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      window.location.href = "/";
    }
  },
};
</script>