var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"fs-7"},[_vm._v(" "+_vm._s(_vm.item.account.login_id ? _vm.item.account.login_id : "-")+" ")]),_c('td',{staticClass:"fs-7"},[_vm._v(_vm._s(_vm.item.type))]),_c('td',{staticClass:"fs-7"},[_vm._v(_vm._s(_vm.item.status))]),_c('td',{staticClass:"fs-7"},[_vm._v(_vm._s(_vm.item.method))]),_c('td',{staticClass:"fs-7"},[_vm._v("$"+_vm._s(_vm.item.amount))]),_c('td',{staticClass:"fs-7"},[_vm._v(" "+_vm._s(_vm.item.comment ? _vm.item.comment : "-")+" ")]),(_vm.item.type == 'DEPOSIT')?_c('td',{staticClass:"fs-7",staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(_vm.item.status == "PENDING" ? _vm.setTimerPayment(_vm.item.created_at) : "Expired")+" ")]):_vm._e(),(_vm.item.type == 'WITHDRAW')?_c('td',{staticClass:"fs-7"},[_vm._v("-")]):_vm._e(),_c('td',{staticClass:"m-o justify-content-center fs-7"},[(
        _vm.item.type == 'DEPOSIT' &&
        _vm.setTimerPayment(_vm.item.created_at) != 'Expired' &&
        _vm.item.status == 'PENDING' &&
        _vm.getLang == 'indo'
      )?_c('button',{staticClass:"btn btn-blue",on:{"click":function($event){return _vm.onVerificationPayment(_vm.item)}}},[_vm._v(" Verifikasi ")]):_vm._e(),(
        _vm.item.type == 'DEPOSIT' &&
        _vm.setTimerPayment(_vm.item.created_at) != 'Expired' &&
        _vm.item.status == 'PENDING' &&
        _vm.getLang == 'uk'
      )?_c('button',{staticClass:"btn btn-blue",on:{"click":function($event){return _vm.onVerificationPayment(_vm.item)}}},[_vm._v(" Verification ")]):_vm._e(),(
        _vm.item.type == 'DEPOSIT' &&
        _vm.item.status == 'PENDING' &&
        _vm.setTimerPayment(_vm.item.created_at) == 'Expired'
      )?_c('button',{staticClass:"btn-expired"},[_vm._v(" Expired ")]):_vm._e(),(_vm.item.type == 'DEPOSIT' && _vm.item.status == 'PENDING')?_c('button',{staticClass:"btn btn-sm btn-danger text-white fs-8 mt-1",on:{"click":function($event){return _vm.cancelDeposit(_vm.item)}}},[_vm._v(" Cancel ")]):_vm._e(),(_vm.item.status == 'SUCCESS')?_c('div',{staticClass:"btn-success"},[_vm._v("Success")]):_vm._e(),(_vm.item.status == 'FAILED' || _vm.item.status == 'REJECTED')?_c('div',{staticClass:"btn-failed"},[_vm._v(" Rejected ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }