<template>
  <div id="Login" class="d-flex justify-content-center align-items-center">
    <div class="container-login text-center">
      <img src="/assets/img/logo.png" alt="" class="mb--lg-5 mb-4 logo-login" />
      <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4">Reset Password</h1>
      <!-- <p class="fs-sm-5 fs-6">Enter your email and we'll send you a link to repeat your password.</p> -->
      <form @submit.prevent="onSubmitResetPassword" class="form mb-4">
        <div class="email mb-4 d-flex flex-column align-items-start">
          <label for="password">Password</label>
          <input v-model="password" type="password" id="password" required />
        </div>
        <div class="email mb-4 d-flex flex-column align-items-start">
          <label for="password-confirm">Confirm Password</label>
          <input
            v-model="passwordConfirm"
            type="password"
            id="password-confirm"
            required
          />
        </div>
        <button type="submit" class="btn btn-blue txt-bold">
          Reset my Password
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      passwordConfirm: "",
      getLang: localStorage.getItem("setLang"),
    };
  },
  methods: {
    onSubmitResetPassword() {
      var data = JSON.stringify({
        password: this.password,
        confirm_password: this.passwordConfirm,
        "token" :  this.$route.params.utoken,
      });

      var config = {
        method: "post",
        url: `${url}/auth/change-pass`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
        },
        data: data,
      };
      if (this.password != this.passwordConfirm) {
        if(this.getLang == "indo"){
          Swal.fire({
          icon: "error",
          title: "Password Harus Sama",
          text: "Cek Password Anda ",
          confirmButtonText: "Ok",
        });
        }else{
          Swal.fire({
          icon: "error",
          title: "Password Must Be Same",
          text: "Check your password again ",
          confirmButtonText: "Ok",
        });
        }
      } else {
        axios(config)
          .then(function () {
            if(self.getLang == "indo"){
              Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda berhasil mengubah password.",
              showConfirmButton: false,
              timer: 2000,
            });
            }else{
              Swal.fire({
              icon: "success",
              title: "Success",
              text: "You have successfully changed your password.",
              showConfirmButton: false,
              timer: 2000,
            });
            }
            window.location.href = "/";
          })
          .catch(function (error) {
            // console.log(error.response);
            if (error.response.status == 500) {
              Swal.fire({
                icon: "error",
                title: "Opss...",
                text: error.response.data.message,
                confirmButtonText: "Coba Lagi",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Opss...",
                text: error.message,
                confirmButtonText: "Coba Lagi",
              });
            }
            // console.log(error);
          });
      }
    },
  },
};
</script>