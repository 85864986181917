<template>
  <!-- indonesia -->
  <div id="navbar">
    <!-- navbar desktop -->
    <div class="popBgLang" v-if="language" @click="onClickLang"></div>
    <div class="navbar-desktop" v-if="getLang == 'indo'">
      <div class="container">
        <!-- logo -->
        <div class="nav logo d-flex">
          <a href="/dashboard" class="mr-3">
            <img src="/assets/img/logo.png" alt="" />
          </a>
          <p class="m-0">Area Pribadi</p>
        </div>
        <!-- navigation -->
        <div class="nav menu">
          <div class="wrapper-menu d-flex justify-content-between">
            <a
              href="/deposit"
              :class="deposit ? 'txt-bold txt-blue' : null"
              class="m-0 mx-4"
            >
              Deposit
            </a>
            <a
              href="/withdraw"
              :class="withdraw ? 'txt-bold txt-blue' : null"
              class="m-0 mx-4"
            >
              Penarikan
            </a>
            <a
              v-if="userRole == 'USER_PARTNER' || userRole == 'USER_SUB_PARTNER'"
              :class="partnership ? 'txt-bold txt-blue' : null"
              href="/partnership"
              class="m-0 mx-4"
            >
              Kemitraan
            </a>
            <a
              v-else
              :class="partnership ? 'txt-bold txt-blue' : null"
              href="/join-ib"
              class="m-0 mx-4"
            >
              Kemitraan
            </a>
            <a
              href="/promotion"
              :class="promotion ? 'txt-bold txt-blue' : null"
              class="m-0 mx-4"
            >
              Promosi
            </a>
          </div>
        </div>
        <!-- profile -->
        <div class="nav profile pointer">
          <!-- language -->
          <div class="switch-language">
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'indo'"
            >
              <img src="/assets/img/indonesia.png" alt="" />
            </button>
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'uk'"
            >
              <img src="/assets/img/uk.png" alt="" />
            </button>
            <div v-if="language" class="dropdown">
              <img
                src="/assets/img/indonesia.png"
                alt=""
                @click="setLanguageindo"
              />
              <img
                src="/assets/img/uk.png"
                class="mt-3"
                alt=""
                @click="setLanguageuk"
              />
            </div>
          </div>
          <!-- language -->
          <div
            class="circle-profile txt-bold text-white"
            @click="fotoProfile = !fotoProfile"
          >
            {{ initialName }}
          </div>
        </div>
        <!-- user profile -->
        <div
          v-if="fotoProfile"
          class="user-profile-container"
          @click="fotoProfile = !fotoProfile"
        ></div>
        <!-- user profile -->
        <div v-if="fotoProfile" class="user-profile">
          <div class="container">
            <div class="wrapper-profile">
              <h2 class="txt-title fs-md-5 fs-6 mb-4">Profil Pengguna</h2>
              <div v-for="item in account" :key="item._id">
                <p class="txt-bold fs-md-5 fs-6 mb-0">
                  {{ item.name }}
                </p>
                <p class="fs-md-6 fs-7 mb-0">{{ item.email }}</p>
                <p class="fs-md-6 fs-7">{{ item.phone }}</p>
              </div>
              <div class="d-flex flex-column flex-start">
                <a href="/profile">
                  <button class="btn btn-blue txt-bold btn-profile">
                    Profile
                  </button>
                </a>
                <div class="line-profile"></div>
                <button @click="logOutBtn" class="btn btn-black txt-bold">
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- navbar mobile -->
    <div class="navbar-mobile" v-if="getLang == 'indo'">
      <div class="container d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div
            class="circle-profile txt-bold text-white pointer"
            @click="fotoProfile = !fotoProfile"
          >
            {{ initialName }}
          </div>
          <div class="switch-language ml-3">
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'indo'"
            >
              <img src="/assets/img/indonesia.png" alt="" />
            </button>
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'uk'"
            >
              <img src="/assets/img/uk.png" alt="" />
            </button>
            <div v-if="language" class="dropdown">
              <img
                src="/assets/img/indonesia.png"
                alt=""
                @click="setLanguageindo"
              />
              <img
                src="/assets/img/uk.png"
                class="mt-3"
                alt=""
                @click="setLanguageuk"
              />
            </div>
          </div>
          <div
            v-on:click="toggleOpen = !toggleOpen"
            v-bind:class="{ 'toggle-btn': true, 'toggle-open': toggleOpen }"
            class="ml-1"
          >
            <div class="hamburger"></div>
          </div>
        </div>
        <a href="/dashboard" class="logo-navbar-mobile">
          <img src="/assets/img/logo.png" alt="" />
        </a>
      </div>
      <!-- user profile -->
      <div
        v-if="fotoProfile"
        class="user-profile-container"
        @click="fotoProfile = !fotoProfile"
      ></div>
      <div v-if="fotoProfile" class="user-profile user-profile-mobile">
        <div class="container">
          <div class="wrapper-profile">
            <h2
              class="txt-title fs-md-5 fs-6 mb-4 mt-2 ml-5 pl-5"
              style="margin-left: 40px"
            >
              Profil Pengguna
            </h2>
            <div v-for="item in account" :key="item._id">
              <p class="txt-bold fs-md-5 fs-6 mb-0">
                {{ item.name }}
              </p>
              <p class="fs-md-6 fs-7 mb-3">{{ item.role }}</p>
              <p class="fs-md-6 fs-7 mb-0">{{ item.email }}</p>
              <p class="fs-md-6 fs-7">{{ item.phone }}</p>
            </div>
            <div class="d-flex flex-column flex-start">
              <a href="/profile">
                <button class="btn btn-blue txt-bold btn-profile">
                  Profile
                </button>
              </a>
              <div class="line-profile"></div>
              <button @click="logOutBtn" class="btn btn-black txt-bold">
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-bind:class="{ 'container-menus': true, active: !toggleOpen }">
        <div class="header-menu">
          <div class="container d-flex justify-content-end align-items-center">
            <a href="/dashboard">
              <img
                src="/assets/img/logo.png"
                alt=""
                class="logo-navbar-mobile"
              />
            </a>
          </div>
        </div>
        <div class="menus">
          <div class="container gap-16">
            <a href="/deposit">
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="deposit ? 'txt-blue' : null">
                  Deposit
                </p>
              </div>
            </a>
            <a href="/withdraw">
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="withdraw ? 'txt-blue' : null">
                  Penarikan
                </p>
              </div>
            </a>
            <a href="/partnership" v-if="userRole == 'USER_PARTNER'  || userRole == 'USER_SUB_PARTNER'">
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="partnership ? 'txt-blue' : null">
                  Kemitraan
                </p>
              </div>
            </a>
            <a href="/join-ib" v-else>
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="partnership ? 'txt-blue' : null">
                  Kemitraan
                </p>
              </div>
            </a>
            <a href="/promotion">
              <div class="container-gray">
                <p class="txt-bold" :class="promotion ? 'txt-blue' : null">
                  Promosi
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- english -->
    <!-- navbar desktop -->
    <div class="navbar-desktop" v-if="getLang === 'uk'">
      <div class="container">
        <!-- logo -->
        <div class="nav logo d-flex">
          <a href="/dashboard" class="mr-3">
            <img src="/assets/img/logo.png" alt="" />
          </a>
          <p class="m-0">Private Area</p>
        </div>
        <!-- navigation -->
        <div class="nav menu">
          <div class="wrapper-menu d-flex justify-content-between">
            <a
              href="/deposit"
              :class="deposit ? 'txt-bold txt-blue' : null"
              class="m-0 mx-4"
            >
              Deposit
            </a>
            <a
              href="/withdraw"
              :class="withdraw ? 'txt-bold txt-blue' : null"
              class="m-0 mx-4"
            >
              Withdraw
            </a>
            <a
              v-if="userRole == 'USER_PARTNER'  || userRole == 'USER_SUB_PARTNER'"
              :class="partnership ? 'txt-bold txt-blue' : null"
              href="/partnership"
              class="m-0 mx-4"
            >
              Partnership
            </a>
            <a
              v-else
              :class="partnership ? 'txt-bold txt-blue' : null"
              href="/join-ib"
              class="m-0 mx-4"
            >
              Partnership
            </a>
            <a
              href="/promotion"
              :class="promotion ? 'txt-bold txt-blue' : null"
              class="m-0 mx-4"
            >
              Promotion
            </a>
          </div>
        </div>
        <!-- profile -->
        <div class="nav profile pointer">
          <!-- language -->
          <div class="switch-language">
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'indo'"
            >
              <img src="/assets/img/indonesia.png" alt="indonesia" />
            </button>
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'uk'"
            >
              <img src="/assets/img/uk.png" alt="english" />
            </button>
            <div v-if="language" class="dropdown">
              <img
                src="/assets/img/indonesia.png"
                alt=""
                @click="setLanguageindo"
              />
              <img
                src="/assets/img/uk.png"
                class="mt-3"
                alt=""
                @click="setLanguageuk"
              />
            </div>
          </div>
          <!-- language -->
          <div
            class="circle-profile txt-bold text-white"
            @click="fotoProfile = !fotoProfile"
          >
            {{ initialName }}
          </div>
        </div>
        <!-- user profile -->
        <div
          v-if="fotoProfile"
          class="user-profile-container"
          @click="fotoProfile = !fotoProfile"
        ></div>
        <!-- user profile -->
        <div v-if="fotoProfile" class="user-profile">
          <div class="container">
            <div class="wrapper-profile">
              <h2 class="txt-title fs-md-5 fs-6 mb-4">User Profile</h2>
              <div v-for="item in account" :key="item._id">
                <p class="txt-bold fs-md-5 fs-6 mb-0">
                  {{ item.name }}
                </p>
                <p class="fs-md-6 fs-7 mb-0">{{ item.email }}</p>
                <p class="fs-md-6 fs-7">{{ item.phone }}</p>
              </div>
              <div class="d-flex flex-column flex-start">
                <a href="/profile">
                  <button class="btn btn-blue txt-bold btn-profile">
                    Profile
                  </button>
                </a>
                <div class="line-profile"></div>
                <button @click="logOutBtn" class="btn btn-black txt-bold">
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- navbar mobile -->
    <div class="navbar-mobile" v-if="getLang === 'uk'">
      <div class="container d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div
            class="circle-profile txt-bold text-white pointer"
            @click="fotoProfile = !fotoProfile"
          >
            {{ initialName }}
          </div>
          <div class="switch-language ml-3">
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'indo'"
            >
              <img src="/assets/img/indonesia.png" alt="" />
            </button>
            <button
              class="btn-language"
              @click="onClickLang"
              v-if="getLang == 'uk'"
            >
              <img src="/assets/img/uk.png" alt="" />
            </button>
            <div v-if="language" class="dropdown">
              <img
                src="/assets/img/indonesia.png"
                alt=""
                @click="setLanguageindo"
              />
              <img
                src="/assets/img/uk.png"
                class="mt-3"
                alt=""
                @click="setLanguageuk"
              />
            </div>
          </div>
          <div
            v-on:click="toggleOpen = !toggleOpen"
            v-bind:class="{ 'toggle-btn': true, 'toggle-open': toggleOpen }"
            class="ml-1"
          >
            <div class="hamburger"></div>
          </div>
        </div>
        <a href="/dashboard" class="logo-navbar-mobile">
          <img src="/assets/img/logo.png" alt="" />
        </a>
      </div>
      <!-- user profile -->
      <div
        v-if="fotoProfile"
        class="user-profile-container"
        @click="fotoProfile = !fotoProfile"
      ></div>
      <div v-if="fotoProfile" class="user-profile user-profile-mobile">
        <div class="container">
          <div class="wrapper-profile">
            <h2
              class="txt-title fs-md-5 fs-6 mb-4 mt-2 ml-5 pl-5"
              style="margin-left: 40px"
            >
              User Profile
            </h2>
            <div v-for="item in account" :key="item._id">
              <p class="txt-bold fs-md-5 fs-6 mb-0">
                {{ item.name }}
              </p>
              <p class="fs-md-6 fs-7 mb-3">{{ item.role }}</p>
              <p class="fs-md-6 fs-7 mb-0">{{ item.email }}</p>
              <p class="fs-md-6 fs-7">{{ item.phone }}</p>
            </div>
            <div class="d-flex flex-column flex-start">
              <a href="/profile">
                <button class="btn btn-blue txt-bold btn-profile">
                  Profile
                </button>
              </a>
              <div class="line-profile"></div>
              <button @click="logOutBtn" class="btn btn-black txt-bold">
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-bind:class="{ 'container-menus': true, active: !toggleOpen }">
        <div class="header-menu">
          <div class="container d-flex justify-content-end align-items-center">
            <a href="/dashboard">
              <img
                src="/assets/img/logo.png"
                alt=""
                class="logo-navbar-mobile"
              />
            </a>
          </div>
        </div>
        <div class="menus">
          <div class="container">
            <a href="/deposit">
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="deposit ? 'txt-blue' : null">
                  Deposit
                </p>
              </div>
            </a>
            <a href="/withdraw">
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="withdraw ? 'txt-blue' : null">
                  Withdraw
                </p>
              </div>
            </a>
            <a href="/partnership" v-if="userRole == 'USER_PARTNER'  || userRole == 'USER_SUB_PARTNER'">
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="partnership ? 'txt-blue' : null">
                  Partnership
                </p>
              </div>
            </a>
            <a href="/join-ib" v-else>
              <div class="container-gray mb-3">
                <p class="txt-bold" :class="partnership ? 'txt-blue' : null">
                  Partnership
                </p>
              </div>
            </a>
            <a href="/promotion">
              <div class="container-gray">
                <p class="txt-bold" :class="promotion ? 'txt-blue' : null">
                  Promotion
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

const userData = JSON.parse(localStorage.getItem("userData"));
const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  data: () => {
    return {
      toggleOpen: false,
      fotoProfile: false,
      detailAkun: false,
      account: [],
      initialName: null,
      userRole: userData.user.role,
      deposit: false,
      withdraw: false,
      promotion: false,
      partnership: false,
      pageNow: localStorage.getItem("pageNow"),
      checked: "",
      language: false,
      getLang: localStorage.getItem("setLang"),
    };
  },
  methods: {
    Reload() {
      location.reload();
    },
    onClickLang() {
      this.language = !this.language;
    },
    setLanguageuk() {
      this.language = !this.language;
      localStorage.setItem("setLang", "uk");
      this.getLang = "uk";
      location.reload();
    },
    setLanguageindo() {
      this.language = !this.language;
      localStorage.setItem("setLang", "indo");
      this.getLang = "indo";
      location.reload();
    },
    logOutBtn: function () {
      if (this.getLang == "indo") {
        Swal.fire({
          title: "Apakah anda yakin untuk logout?",
          text: "Anda akan keluar dari halaman ini.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya, Keluar sekarang",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire("Logout Berhasil!", "Anda berhasil Logout.", "success");
            localStorage.clear("userData");
            localStorage.setItem("isLogin",false);
            window.location.href = "/";
          }
        });
      } else {
        Swal.fire({
          title: "Are you sure to log out?",
          text: "You will exit this page.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              "Logout Successful!",
              "You have successfully logged out.",
              "success"
            );
            localStorage.clear("userData");
            localStorage.setItem("isLogin", JSON.stringify({ isLogin: false }));
            window.location.href = "/";
          }
        });
      }
    },
  },
  mounted() {
    if (this.pageNow == "deposit") {
      this.deposit = true;
      this.promotion = false;
      this.partnership = false;
      this.withdraw = false;
    }
    if (this.pageNow == "withdraw") {
      this.deposit = false;
      this.promotion = false;
      this.partnership = false;
      this.withdraw = true;
    }
    if (this.pageNow == "partnership") {
      this.deposit = false;
      this.promotion = false;
      this.partnership = true;
      this.withdraw = false;
    }
    if (this.pageNow == "promotion") {
      this.deposit = false;
      this.promotion = true;
      this.partnership = false;
      this.withdraw = false;
    }
    var config = {
      method: "get",
      url: `${url}/me`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };
    let self = this;
    axios(config)
      .then(function (res) {
        let arr = [res.data.data];
        self.account = arr;
        self.initialName = res.data.data.name.toUpperCase().substring(0, 1);
      })
      .catch(function (error) {
        console.log(error);
        // remove localstorage
        localStorage.removeItem("isLogin");
        localStorage.removeItem("userData");
        window.location.href = "/";
      });
  },
};
</script>
