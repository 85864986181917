<template>
  <div id="xppoint">
    <p class="text_1">XP POINT TRADING</p>
    <!-- <img src="/assets/img/xppoint.png" alt="" /> -->
    <img src="/assets/img/trophy.png" alt="" />
    <p class="text_2">{{ Math.round(mypoint) }} point</p>
    <!-- progress stepper -->
    <div class="xppoint-stepper">
      <span></span>
      <div class="block wrapper-progressbar">
        <div class="">
          <ul class="progressBar">
            <li :class="mypoint > 1000 ? 'active' : null">1000</li>
            <li :class="mypoint > 2000 ? 'active' : null">2000</li>
            <li :class="mypoint > 3000 ? 'active' : null">3000</li>
            <li :class="mypoint > 40000 ? 'active' : null">4000</li>
            <li :class="mypoint > 5000 ? 'active' : null">5000</li>
          </ul>
        </div>
      </div>
    </div>
    <p class="text_3" v-if="getLang == 'uk'">Big Point is A Big Reward</p>
    <p class="text_3" v-if="getLang == 'indo'">Poin Besar adalah Hadiah Besar</p>
  </div>
</template>

<script>
import axios from "axios";

const userData = JSON.parse(localStorage.getItem("userData"));

const url = process.env.VUE_APP_API_URL;
const xppoint_url = process.env.VUE_APP_API_URL_VERIFY;
const token = process.env.VUE_APP_API_TOKEN;
export default {
  data() {
    return {
      mypoint: 0,
      accountReal: [],
      volume: 0,
      getLang: localStorage.getItem("setLang"),
    };
  },
  mounted() {
    let self = this;
    var getAccount = {
      method: "get",
      url: `${url}/me/accounts`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };

    axios(getAccount)
      .then(function (res) {
        let obj = res.data.data.account;
        obj.filter((item) => {
            const timeNow = new Date().getTime();
            const time = Math.round(timeNow / 1000);
            console.log(time)
          if (item.type == "REAL" && item.status == "ACTIVE") {
            self.accountReal = item;
            var configget = {
              method: "get",
              // url: `http://localhost:8080/v1/users/${8754}/deals?from=1625097600&to=${time}`,
              // url: `${xppoint_url}/v1/users/${item.login_id}/deals?from=971326728&to=${time}`,
              url: `${xppoint_url}/api/v1/xp/${item.login_id}`,
              headers: {
                "content-type": "application/json",
                "x-app-token": token,
                "Access-Control-Allow-Origin": "*",
              },
            };
            // start
            axios(configget)
              .then(function (res) {
                let obj = res.data.data;
                obj.filter((item) => {
                  if (item.volume) {
                    self.volume = self.volume + (item.volume_ext / 100000000);
                  }
                });
                console.log("xp-point", self.volume);
                self.mypoint = self.volume * 100;
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        });
        // end

        console.log("acccount: ", self.accountReal);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>