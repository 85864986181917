<template>
  <div id="Login" class="d-flex justify-content-center align-items-center">
    <div class="container-login text-center">
      <img src="/assets/img/logo.png" alt="" class="mb--lg-5 mb-4 logo-login" />
      <h1 class="txt-title mb-sm-3 mb-2 fs-sm-3 fs-4">Login Account</h1>
      <p class="fs-sm-5 fs-6">Enter your email and password</p>
      <form @submit.prevent="onSubmitLogin" class="form mb-4">
        <div class="email mb-4 d-flex flex-column align-items-start">
          <label for="email">Email</label>
          <input
            v-model="email"
            type="text"
            placeholder="Email"
            id="email"
            required
          />
        </div>
        <div class="password mb-4 d-flex flex-column align-items-start">
          <label for="password">Password</label>
          <input
            v-model="password"
            type="password"
            placeholder="password"
            id="password"
            required
          />
        </div>
        <!-- <router-link to="/dashboard"> -->
        <button type="submit" class="btn btn-blue txt-bold">Login</button>
        <!-- </router-link> -->
      </form>
      <div class="extra">
        <router-link to="/forgot-password"> Forgot password </router-link>
        <p>or</p>
        <p>
          Don't have an account yet?
          <a
            href="https://socrateslegacy.com/register"
            class="txt-blue txt-bold"
            >Sign Up</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

// console.log("set env: ", process.env.VUE_APP_API_URL);

const userData = JSON.parse(localStorage.getItem("userData"));
// const isLogin = JSON.parse(localStorage.getItem("isLogin"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "Activate",
  data() {
    return {
      email: "",
      password: "",
      isLogin: false,
      getLang: localStorage.getItem("setLang"),
    };
  },
  mounted() {
    // console.log("userKey: ", this.$route.query.key);
    let self = this;
    var postActivate = {
      method: "post",
      url: `${url}/auth/user/activate`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
      },
      data: {
        userkey: this.$route.query.key,
      },
    };

    axios(postActivate)
      .then(function (res) {
        // console.log("success get data: ", res.data);
        if(self.getLang == "indo"){
          if (res.data.statusCode == 200) {
          Swal.fire({
            icon: "success",
            title: "Activasi Berhasil",
            text: "Silahkan login untuk masuk ke dashboard!",
            confirmButtonText: "Oke",
          }).then(() => {
            window.location.href = "/";
          });
        }
        }else{
          if (res.data.statusCode == 200) {
          Swal.fire({
            icon: "success",
            title: "Activation Successful",
            text: "Please login to enter the dashboard!",
            confirmButtonText: "Oke",
          }).then(() => {
            window.location.href = "/";
          });
        }
        }
      })
      .catch(function (error) {
        // console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
          confirmButtonText: "Oke",
        });
      });
    if (userData != "") {
      var config = {
        method: "get",
        url: `${url}/me/accounts`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
      };

      axios(config)
        .then(function (res) {
          // console.log("success get data: ", res.data.data);
          if (res.data.statusCode == 200) {
            window.location.href = "/dashboard";
          }
        })
        .catch(function () {
          // console.log(error);
        });
    }
  },
  methods: {
    onSubmitLogin() {
      var dataUser = JSON.stringify({
        email: this.email,
        password: this.password,
      });

      var config = {
        method: "post",
        url: `${url}/auth/login`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
        },
        data: dataUser,
      };

      let self = this;

      axios(config)
        .then(function (res) {
          self.isLogin = true;
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          localStorage.setItem( "isLogin",self.isLogin );
          // console.log(res.data);
          window.location.href = "/dashboard";
        })
        .catch(function (error) {
          // const message = error.response.data.message.toLowerCase();
          const errMessage = error.message;
          // console.log(error.code);
          if(self.getLang == "indo"){
            if (errMessage == "Network Error") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errMessage,
            });
          } else if (
            error.response.data.message.toLowerCase() ==
            "wrong password. please check your password then try again!"
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Kata sandi salah. silakan periksa kata sandi Anda lalu coba lagi!",
            });
          } else if (
            error.response.data.message.toLowerCase() ==
            "user not found. please register or try again"
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Pengguna tidak ditemukan. silahkan daftar atau coba lagi!",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errMessage,
            });
          }
          }else{
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errMessage,
            });
          }
          self.isLogin = false;
          localStorage.setItem("isLogin", false );
        });
    },
  },
};
</script>