<template>
  <div id="identity" class="page">
    <!-- navbar component -->
    <Navbar />
    <div
      class="
        container
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
    >
      <h1 class="txt-title fs-md-3 fs-4 text-center pt-4 mb-4" v-if="getLang == 'indo'">
        Permintaan Verifikasi Identitas
      </h1>
      <h1 class="txt-title fs-md-3 fs-4 text-center pt-4 mb-4" v-if="getLang == 'uk'">
        Identity Verification Request
      </h1>
      <div>
        <div class="step select-type d-flex flex-column">
          <label for="type" class="txt-bold" v-if="getLang == 'indo'"> 1. Pilih Jenis ID Anda </label>
          <label for="type" class="txt-bold" v-if="getLang == 'uk'"> 1. Select Your ID Type </label>
          <div class="select-custom w-100">
            <select
              name="type"
              id="type"
              class="w-100"
              v-model="select_id_type"
            >
              <option value="" hidden>Select your option</option>
              <option value="" hidden>Select your option</option>
              <option value="ID CARD">ID Card</option>
              <option value="PASSPORT">Passport</option>
              <option value="DRIVING LICENSE">Driving License</option>
            </select>
            <span></span>
          </div>
        </div>
        <div class="step id-number d-flex flex-column">
          <div class="input">
            <label for="id-number" class="txt-bold" v-if="getLang == 'indo'"> 2. Nomor ID </label>
            <label for="id-number" class="txt-bold" v-if="getLang == 'uk'"> 2. ID Number </label>
            <input
              class="w-100 input-text"
              type="text"
              name="id-number"
              id="id-number"
              placeholder="Enter your ID Number"
              v-model="input_id_num"
            />
          </div>
        </div>
        <div class="step upload-file d-flex flex-column">
          <label for="upload-file" class="txt-bold" v-if="getLang == 'indo'">
            3. Unggah File Anda
          </label>
          <label for="upload-file" class="txt-bold" v-if="getLang == 'uk'">
            3. Upload Your File
          </label>
          <!-- old code -->
          <!-- <div class="upload-here mb-md-3 mb-2">
            <img src="/assets/img/image-upload.svg" alt="upload here" />
            <h4 class="txt-bold fs-lg-4 fs-5 choose-file">
              Drop your image here or <label for="input-file">Browse</label>
              <input
                id="input-file"
                style="display: none"
                type="file"
                @change="onFileSelected"
              />
            </h4>
            <p>Provide files in JPG/JPEG/PNG format, 10 MB maximum.</p>
          </div> -->
          <!-- new one -->
          <!-- <div class="drop-zone" v-show="false">
            <span class="drop-zone__prompt d-flex flex-column">
              <img src="/assets/img/image-upload.svg" alt="upload here" />
              <p class="mb-0" v-if="getLang == 'indo'">
                <b>Letakan</b> file disini atau <b>klik</b> untuk mengunggah.
              </p>
              <p class="mb-0" v-if="getLang == 'uk'">
                <b>Drop</b> file here or <b>click</b> to upload
              </p>
            </span>
            <input
              type="file"
              name="Identity"
              class="drop-zone__input"
              @change="onFileSelected"
            />
          </div> -->
          <UploadImages :max="1" maxError="Maximal Upload file 1 gambar" @changed="handleImages"/>
          <p class="mt-2" v-if="getLang == 'indo'">Sediakan file dalam format JPG/JPEG/PNG, maksimum 10 MB.</p>
          <p class="mt-2" v-if="getLang == 'uk'">Provide files in JPG/JPEG/PNG format, 10 MB maximum.</p>
        </div>
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-blue txt-bold text-white"
            @click="onSelectRequest" v-if="getLang == 'indo'"
          >
            Kirim
          </button>
          <button
            class="btn btn-blue txt-bold text-white"
            @click="onSelectRequest" v-if="getLang == 'uk'"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import Swal from "sweetalert2";
const userData = JSON.parse(localStorage.getItem("userData"));
import UploadImages from "vue-upload-drop-images";
import Notiflix from 'notiflix';
const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "identity",
  components: {
    Navbar,
    UploadImages
  },
  data() {
    return {
      select_id_type: "",
      input_id_num: "",
      input_file: null,
      getLang: localStorage.getItem("setLang"),
    };
  },
  created(){
    localStorage.setItem("pageNow", "verif-identity");
  },
  methods: {
    // onFileSelected(e) {
    //   this.input_file = e.target.files[0];
    //   console.log(e.target.files[0])
    // },    
    handleImages(files){
      this.input_file = files[0];
    },
    onSelectRequest() {
      var data = new FormData();
      data.append("user_id", userData.user._id);
      data.append("idNumber", this.input_id_num);
      data.append("type", this.select_id_type);
      data.append("file", this.input_file);
      data.append("category", "IDENTITY");
      data.append("bank_name", null);
      data.append("bank_account_number", null);
      data.append("bank_account_name", null);

      var config = {
        method: "post",
        url: `${url}/verification`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
        data: data,
      };
      if (
        this.getLang == "indo" &&
        (!this.input_id_num ||
        !this.select_id_type ||
        !this.input_file)
      ) {
        Swal.fire({
          icon: "question",
          title: "Data Belum Lengkap!",
          text: "Lengkapi data Anda untuk melakukan verifikasi!",
          confirmButtonText: "Oke",
        });
      } else if (
        this.getLang == "uk" && 
        (!this.input_id_num ||
        !this.select_id_type ||
        !this.input_file)
      ) {
        Swal.fire({
          icon: "question",
          title: "Incomplete Data!",
          text: "Complete your data to verify identity!",
          confirmButtonText: "Oke",
        });
      }else{
        if (this.getLang == "indo") {
        Swal.fire({
          title: "Verifikasi Identitas",
          text: "Apakah data sudah benar?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Benar",
        }).then((result) => {
          Notiflix.Loading.circle({
            className: "notiflix-loading",
            svgColor: "#c09a07",
          });
          if (result.isConfirmed) {
            axios(config)
              .then(function (response) {
                Notiflix.Loading.remove();
                Swal.fire({
                  icon: "success",
                  title: "Anda Berhasil verifikasi Identitas",
                  showConfirmButton: false,
                  timer: 2000,
                });
                window.location.href = "/verify-pending";
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                Notiflix.Loading.remove();
                if (error.response.status == 500) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.response.data.message,
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                  });
                }
                console.log(error);
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Notiflix.Loading.remove();
          }
        });
      } else {
        Swal.fire({
          title: "Verify Identity",
          text: "Is the data correct?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          Notiflix.Loading.circle({
            className: "notiflix-loading",
            svgColor: "#c09a07",
          });
          if (result.isConfirmed) {
            axios(config)
              .then(function (response) {
                Notiflix.Loading.remove();
                Swal.fire({
                  icon: "success",
                  title: "You Successfully Verify Identity",
                  showConfirmButton: false,
                  timer: 2000,
                });
                window.location.href = "/verify-pending";
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                Notiflix.Loading.remove();
                if (error.response.status == 500) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.response.data.message,
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                  });
                }
                console.log(error);
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Notiflix.Loading.remove();
          }
        });
      }
      }
    },
  },
};
</script>