<template>
  <div id="withdraw">
    <!-- navbar -->
    <Navbar />
    <h1
      class="txt-title text-center fs-md-3 mb-4"
      v-if="getLang == 'indo' && verified"
    >
      Penarikan
    </h1>
    <h1
      class="txt-title text-center fs-md-3 mb-4"
      v-if="getLang == 'uk' && verified"
    >
      Withdraw
    </h1>
    <!-- navigation deposit -->
    <div
      class="nav-depo d-flex justify-content-center txt-bold fs-md-4 fs-4"
      v-if="verified"
    >
      <router-link to="/withdraw" class="active-nav"> Local </router-link>
      <router-link to="/withdraw-crypto"> Crypto </router-link>
    </div>
    <!-- navigation deposit -->
    <div
      class="
        nav-depo
        d-flex
        justify-content-center
        txt-bold
        fs-md-4 fs-4
        mb-md-5 mb-4
      "
    ></div>
    <!-- local deposit -->
    <div v-if="loading">
      <div class="d-flex justify-content-center">
        <h1>Loading</h1>
      </div>
    </div>
    <div v-else>
      <div class="local-depo" v-if="true">
        <div class="container">
          <div class="row">
            <!-- left side -->
            <div class="col-md-6 mb-4">

              <div class="container align-items-start border border-light d-flex flex-column mb-2 p-3">
                <h3 class="txt-bold fs-md-4">Attention</h3>
                Please take a moment to review the terms and conditions regarding withdrawals from your trading account. It's important to understand the following details to ensure a smooth and efficient withdrawal process:
                <br>
                1. Please ensure that your withdrawal request does not exceed the available balance in your account at the time of the request at least it should be less or equal to the maximum of the current balance
                <br>
                2. Transfers of funds to your chosen bank account or cryptocurrency wallet will usually be processed within 48 hours maximum during regular business hours.
                <br>
                This timeframe may be necessary to comply with our policy for withdrawals from our Liquidity Provider.
                <br>
                Thank you for taking the time to review our terms and conditions. We value your business and strive to ensure your trading experience is both smooth and fulfilling.
              </div>

              <!-- select account -->
              <div
                class="select-account mb-4 d-flex flex-column align-items-start"
              >
                <h3 class="txt-title fs-md-4" v-if="getLang == 'indo'">
                  Pilih Akun
                </h3>
                <h3 class="txt-title fs-md-4" v-if="getLang == 'uk'">
                  Select Account
                </h3>
                <div class="select-custom mb-3">
                  <select
                    v-model="selectedAccount"
                    name="select-account"
                    id="select-account"
                    @change="onChangeAccount($event)"
                    required
                  >
                    <option value="" disabled selected v-if="getLang == 'indo'">
                      Pilih Akun
                    </option>
                    <option value="" disabled selected v-if="getLang == 'uk'">
                      Select your option
                    </option>
                    <option
                      v-for="item in getAccount"
                      :value="item._id"
                      v-bind:key="item._id_num"
                      name="select-account"
                      id="select-account"
                    >
                      {{ item.spec.name }} - {{ item.login_id }}
                    </option>
                  </select>
                  <span></span>
                </div>
                <!-- ngga perlu button select account -->
                <!-- <button class="txt-bold btn btn-blue fs-md-6 mb-4">
                  Select Account
                </button> -->
                <!-- trading account information -->
                <div v-if="selected">
                  <div v-for="item in getDataAccount" v-bind:key="item._id">
                    <div class="account-info">
                      <div class="w-100 header-info">
                        <h4
                          class="text-center m-0 txt-title fs-md-5 fs-6"
                          v-if="getLang == 'indo'"
                        >
                          Informasi Akun Trading
                        </h4>
                        <h4
                          class="text-center m-0 txt-title fs-md-5 fs-6"
                          v-if="getLang == 'uk'"
                        >
                          Trading Account Information
                        </h4>
                      </div>
                      <div
                        class="content-info fs-md-5"
                        v-if="getLang == 'indo'"
                      >
                        <p>ID Akun: {{ item.login_id }}</p>
                        <p>Tipe Akun: REAL</p>
                        <p>Akun Leverage: {{ item.leverage }}</p>
                        <p>Saldo Akun: $ {{ item.last_balance }}</p>
                      </div>
                      <div class="content-info fs-md-5" v-if="getLang == 'uk'">
                        <p>Account ID: {{ item.login_id }}</p>
                        <p>Account Type: REAL</p>
                        <p>Account Leverage: {{ item.leverage }}</p>
                        <p>Account Balance: $ {{ item.last_balance }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- trading account information -->
              </div>
              <!-- info bank socrates -->
              <div class="bank-socrates">
                <div class="mb-3">
                  <p class="fs-md-7 m-0" v-if="getLang == 'indo'">Bank</p>
                  <p class="fs-md-7 m-0" v-if="getLang == 'uk'">Bank</p>
                  <p class="txt-bold fs-md-5">
                    {{ selectBank }}
                  </p>
                </div>
                <div class="mb-3">
                  <p class="fs-md-7 m-0" v-if="getLang == 'indo'">Nomor Akun</p>
                  <p class="fs-md-7 m-0" v-if="getLang == 'uk'">
                    Account Number
                  </p>
                  <p class="txt-bold fs-md-5">
                    {{ input_nomor_akun }}
                  </p>
                </div>
                <div class="mb-3">
                  <p class="fs-md-7 m-0" v-if="getLang == 'indo'">Nama Akun</p>
                  <p class="fs-md-7 m-0" v-if="getLang == 'uk'">Account Name</p>
                  <p class="txt-bold fs-md-5">
                    {{ input_akun_pemilik }}
                  </p>
                </div>
              </div>
            </div>
            <!-- right side -->
            <div class="col-md-6 right-side">
              <h2 class="txt-title fs-md-4 mb-4" v-if="getLang == 'indo'">
                Masukan jumlah deposit
              </h2>
              <h2 class="txt-title fs-md-4 mb-4" v-if="getLang == 'uk'">
                Enter Withdraw Amount
              </h2>
              <div class="input">
                <label for="amount" v-if="getLang == 'indo'">
                  Jumlah dalam US Dollar ($)
                </label>
                <label for="amount" v-if="getLang == 'uk'">
                  Amount in us dollars ($)
                </label>
                <div class="input-amount w-100">
                  <img src="/assets/img/dollar.svg" alt="" />
                  <input
                    class="w-100"
                    type="text"
                    name="amount"
                    id="amount"
                    placeholder="0"
                    v-model="input_amount"
                    required
                  />
                </div>
              </div>
              <div class="input">
                <label for="akun-pemilik" v-if="getLang == 'indo'">
                  Akun Pemilik Bank (*)
                </label>
                <label for="akun-pemilik" v-if="getLang == 'uk'">
                  Bank Account Name (*)
                </label>
                <input
                  type="text"
                  name="akun-pemilik"
                  for="akun-pemilik"
                  placeholder="John doe"
                  v-model="input_akun_pemilik"
                  required
                />
              </div>
              <div class="input">
                <label for="nomor-akun" v-if="getLang == 'indo'">
                  Nomor akun Bank (*)
                </label>
                <label for="nomor-akun" v-if="getLang == 'uk'">
                  Bank Account Number (*)
                </label>
                <input
                  name="nomor-akun"
                  id="nomor-akun"
                  placeholder="654321"
                  v-model="input_nomor_akun"
                  required
                />
              </div>
              <div class="input">
                <label for="nama-bank" v-if="getLang == 'indo'">
                  Nama Bank (*)
                </label>
                <label for="nama-bank" v-if="getLang == 'uk'">
                  Bank Name (*)
                </label>
                <model-select
                  class="select-bank w-100"
                  :options="bankOptions"
                  v-model="selectBank"
                  BANK
                  placeholder="select bank"
                >
                </model-select>
              </div>
              <!-- <div class="input mb-2">
                <label for="nama-bank"> Nama Bank (*) </label>
                <div class="select-custom mb-3">
                  <select
                    v-model="selectBank"
                    name="select-account"
                    id="select-account"
                    class="w-100"
                    @change="onChangeBank($event)"
                    required
                  >
                    <option value="" disabled selected hidden>
                      Select your option
                    </option>
                    <option
                      v-for="item in bank"
                      :key="item.name"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <span></span>
                </div> -->
              <!-- </div> -->
              <div class="input">
                <label for="email" v-if="getLang == 'indo'">
                  Email Terdaftar
                </label>
                <label for="email" v-if="getLang == 'uk'">
                  Registered Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  :value="email"
                  readonly
                />
              </div>
              <!-- <div class="input">
                <label for="rate-usd" v-if="getLang == 'indo'"> Nilai USD = IDR </label>
                <label for="rate-usd" v-if="getLang == 'uk'"> Rate USD = IDR </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  :value="rateUsd"
                  readonly
                />
              </div> -->
              <!-- <div class="input">
                <label for="transfer" v-if="getLang == 'indo'"> Jumlah Penarikan (Rp) </label>
                <label for="transfer" v-if="getLang == 'uk'"> Total Withdraw(Rp) </label>
                <input
                  type="text"
                  name="transfer"
                  id="transfer"
                  :value="rateUsd * input_amount"
                  readonly
                />
                <small v-if="getLang == 'indo'">Floating rate menyesuaikan harga pasar</small>
                <small v-if="getLang == 'uk'">Floating rates adjust to market prices</small>
              </div> -->
              <div class="term-condition mb-4">
                <input
                  type="checkbox"
                  name="term"
                  id="term"
                  class="mr-3"
                  v-model="checked"
                  value="checked"
                  required
                />
                <label for="term" class="txt-bold fs-md-7">
                  <p class="m-0" v-if="getLang == 'indo'">
                    Saya setuju dengan &nbsp;<a
                      href="https://secure.socrateslegacy.com/assets/legal/Socrates-Legal-Documents.pdf"
                      target="_blank"
                      >Syarat dan Ketentuan.</a
                    >
                  </p>
                  <p class="m-0" v-if="getLang == 'uk'">
                    I Agree With &nbsp;<a
                      href="https://secure.socrateslegacy.com/assets/legal/Socrates-Legal-Documents.pdf"
                      target="_blank"
                      >Terms and Conditions</a
                    >
                  </p>
                </label>
              </div>
              <button
                class="btn btn-blue txt-bold"
                @click="confirmSubmit"
                v-if="getLang == 'indo'"
              >
                Minta penarikan
              </button>
              <button
                class="btn btn-blue txt-bold"
                @click="confirmSubmit"
                v-if="getLang == 'uk'"
              >
                Request Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="belum-verifikasi" v-if="false">
        <div class="container mx-auto">
          <div class="row align-items-center justify-content-center wrap-verif">
            <div class="col-sm-5 col-12 flex-wrap order-2 order-sm-1">
              <p class="fs-sm-3 fs-4 txt-bold" v-if="getLang == 'indo'">
                Maaf,
              </p>
              <p class="fs-sm-3 fs-4 txt-bold" v-if="getLang == 'uk'">Sorry,</p>
              <p class="fs-sm-4 fs-5" v-if="getLang == 'indo'">
                Agar bisa melakukan withdrawal, silahkan verifikasi bank
                terlebih dahulu.
              </p>
              <p class="fs-sm-4 fs-5" v-if="getLang == 'uk'">
                In order to make a withdrawal, please verify the bank first
                formerly.
              </p>
              <a href="/verify-bank">
                <button
                  class="btn btn-blue txt-bold rounded"
                  v-if="getLang == 'indo'"
                >
                  Verifikasi
                </button>
                <button
                  class="btn btn-blue txt-bold rounded"
                  v-if="getLang == 'uk'"
                >
                  Verification
                </button>
              </a>
            </div>
            <div
              class="
                img-wrapper
                col-sm-7 col-12
                flex-wrap
                p-5
                order-1 order-sm-2
              "
            >
              <img
                src="/assets/img/verified-false.svg"
                class="p-sm-5 p-0"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { bank } from "./bank";
import Notiflix from "notiflix";
import { bankOptions } from "./bankOptions";
import { ModelSelect } from "vue-search-select";

const userData = JSON.parse(localStorage.getItem("userData"));

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: "deposit",
  components: {
    Navbar,
    ModelSelect,
  },
  data() {
    return {
      selectedAccount: "",
      selected: false,
      getAccount: [],
      getDataAccount: [],
      input_amount: null,
      input_akun_pemilik: null,
      input_nomor_akun: null,
      input_bank_name: null,
      selectBank: null,
      noBank: "",
      email: userData.user.email,
      rateUsd: 0,
      bank: bank,
      checked: "",
      getVerifBank: "",
      verified: false,
      dataBank: localStorage.getItem("databank"),
      getLang: localStorage.getItem("setLang"),
      loading: true,
      bankOptions,
    };
  },
  methods: {
    onChangeAccount(e) {
      this.selected = true;
      this.selectedAccount = e.target.value;
      this.getDataAccount = this.getAccount.filter((data) => {
        if (data._id == e.target.value) {
          return data;
        } else {
          return null;
        }
      });
    },
    confirmSubmit() {
      const self = this;
      if (this.getLang == "indo") {
        if (!this.checked || !this.getDataAccount || !this.input_akun_pemilik) {
          Swal.fire({
            icon: "question",
            title: "Data Belum Lengkap!",
            text: "Lengkapi data anda untuk melakukan Withdraw!",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Sudah Yakin Dengan Data Anda?",
            text: "Cek data sekali lagi jika belum yakin",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yakin",
            cancelButtonText: "Batal",
          }).then((result) => {
            if (result.isConfirmed) {
              self.onSubmitWithdraw();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Notiflix.Loading.remove();
            }
          });
        }
      } else {
        if (!this.checked || !this.getDataAccount || !this.input_akun_pemilik) {
          Swal.fire({
            icon: "question",
            title: "Incomplete Data!",
            text: "Complete your data to make a withdrawal!",
            confirmButtonText: "Oke",
          });
        } else {
          Swal.fire({
            title: "Are You Confident With Your Data?",
            text: "Check the data again if you are not sure",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.isConfirmed) {
              self.onSubmitWithdraw();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Notiflix.Loading.remove();
            }
          });
        }
      }
    },
    onSubmitWithdraw() {
      Notiflix.Loading.circle({
        className: "notiflix-loading",
        svgColor: "#c09a07",
      });
      let self = this;
      if(!this.input_amount){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "amount undefined!",
        });
        throw new Error("amount undefined")
      }
      var data = JSON.stringify({
        account_id: this.getDataAccount[0]._id,
        user_id: this.getDataAccount[0].user_id,
        amount: Number(this.input_amount??0),
        method: "BANK",
        type: "WITHDRAW",
        bank_acc: this.input_nomor_akun,
        bank_name: this.selectBank,
        bank_username: this.input_akun_pemilik,
      });

      var config = {
        method: "post",
        url: `${url}/payment/withdraw`,
        headers: {
          "content-type": "application/json",
          "x-app-token": token,
          "x-user-token": userData.token,
        },
        data: data,
      };

      axios(config)
        .then(function () {
          Notiflix.Loading.remove();
          if (self.getLang == "indo") {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Withdraw berhasil, permintaan anda sedang diproses oleh admin!",
              confirmButtonText: "Oke",
            }).then(() => {
              window.location.href = "/dashboard";
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Withdraw successful, your request is being processed by admin!",
              confirmButtonText: "Oke",
            }).then(() => {
              window.location.href = "/dashboard";
            });
          }
        })
        .catch(function (error) {
          Notiflix.Loading.remove();
          if (error.response.status == 500) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
            });
          }
          console.log(error);
        });
    },
  },

  created() {
    localStorage.setItem("pageNow", "withdraw");
  },
  mounted() {
    window.scrollTo(0, 0);
    // const verifbank = localStorage.getItem("verifbank");
    // if (verifbank === false) {
    //   this.verified = false;
    // } else if (verifbank === false) {
    //   this.verified = true;
    // }
    // console.log("verifBank", this.verified)
    var config = {
      method: "get",
      url: `${url}/me/accounts`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };

    let self = this;

    axios(config)
      .then(function (res) {
        let obj = res.data.data.account;
        self.getAccount = obj.filter((item) => {
          if (item.login_id && item.status === "ACTIVE" && item.type !== "DEMO") {
            console.log("statuse: ", item);
            return item;
          } else {
            return null;
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    // get rate
    var getsys = {
      method: "get",
      url: process.env.VUE_APP_API_URL_VERIFY + "/api/rate",
    };
    axios(getsys)
      .then(function (resp) {
        // MANUAL RATE
        // self.rateUsd = 16000;
        // AUTO RATE
        self.rateUsd = resp.data.data.rate;
        // self.rateUsd = Math.ceil(rateUsd[0].latestPrice / 100) * 100 + 400;
      })
      .catch(function (error) {
        console.log(error);
        alert("Something wrong, please try again later");
        // MANUAL RATE
        self.rateUsd = 0;
      });
    //
    var verifBank = {
      method: "get",
      url: `${url}/me/verifications?category=PAYMENT&sort[created_at]=desc`,
      headers: {
        "content-type": "application/json",
        "x-app-token": token,
        "x-user-token": userData.token,
      },
    };
    axios(verifBank)
      .then(function (res) {
        let obj = res.data.data.verification.reverse();
        // const getdatabank = obj[0].bank;
        obj.forEach(function (item) {
          if (item.status == "SUCCESS") {
            const getdatabank = item.bank;
            const dataBank = {
              bank_name: getdatabank.bank_name,
              bank_acc: getdatabank.bank_acc,
              bank_username: getdatabank.bank_username,
            };
            self.input_akun_pemilik = getdatabank.bank_username;
            self.input_nomor_akun = getdatabank.bank_acc;
            self.selectBank = getdatabank.bank_name;
            self.input_bank_name = getdatabank.bank_name;
            localStorage.setItem("databank", dataBank);
            self.getVerifBank = "active";
            self.verified = true;
            localStorage.setItem("verifbank", true);
          } else if (self.getVerifBank != "active") {
            self.verified = false;
            self.getVerifBank = "pending";
            localStorage.setItem("verifbank", false);
          }
          console.log("verifBank:", localStorage.getItem("verifbank"));
        });
      })
      .catch(function (error) {
        self.verified = false;
        console.log(error);
      })
      .then(function () {
        // disable loading on last request
        self.loading = false;
      });
  },
};
</script>