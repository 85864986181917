<template>
  <div id="verify-poa" class="page">
    <!-- navbar component -->
    <Navbar />
    <div class="container">
      <!-- main content -->
      <h1
        class="fs-4 text-center text-center txt-title py-4"
        v-if="getLang == 'indo'"
      >
        Permintaan Verifikasi Bukti Tinggal
      </h1>
      <h1
        class="fs-4 text-center text-center txt-title py-4"
        v-if="getLang == 'uk'"
      >
        Proof of Address Request
      </h1>
      <div class="row">
        <div class="col-lg-5 col-md-6 mb-4">
          <!-- information -->
          <div class="container-gray warning d-flex rounded-primary">
            <img src="/assets/img/warning.svg" alt="" class="mr-4" />
            <div>
              <h4 class="txt-bold fs-6 mb-3" v-if="getLang == 'uk'">
                The document you are providing must be valid at least 30 days
                and contain all of the following details:
              </h4>
              <p class="fs-6 mb-2" v-if="getLang == 'uk'">
                Provide correct information include your name and address.
              </p>
              <p class="fs-6" v-if="getLang == 'uk'">
                Any failures on transactions caused by wrong information cannot
                be held on Socrateslegacy responsibility.
              </p>
            </div>
          </div>
          <div
            class="
              container-gray
              warning
              d-flex
              flex-column
              rounded-primary
              mt-3
            "
          >
            <div class="d-flex align-items-center justify-content-center mb-3">
              <!-- <img src="/assets/img/warning.svg" alt="" class="mr-4" /> -->
              <h4 class="txt-bold fs-6 mb-3" v-if="getLang == 'uk'">
                Example of Proof Address format
              </h4>
            </div>
            <b>A. Bank Statement</b>
            <div class="rounded-primary border shadow-sm">
              <img
                src="/assets/img/img_bankverify.jpg"
                alt=""
                width="100%"
                height="auto"
              />
            </div>
            <br />
            <b>B. Utility Bill</b>
            <div class="rounded-primary border shadow-sm">
              <img
                src="/assets/img/img_utility_bill.jpg"
                alt=""
                width="100%"
                height="auto"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-6 bg_secondary p-5 rounded-primary">
          <!-- form verify -->

          <div class="step bank-name d-flex flex-column">
            <label for="bank-name" class="txt-bold" v-if="getLang == 'indo'">
              1. Tipe Bukti
            </label>
            <label for="bank-name" class="txt-bold" v-if="getLang == 'uk'">
              1. Proof Type
            </label>
            <model-select
              class="select-bank w-100"
              :options="proofOptions"
              v-model="select_proof_type"
              @change="onSelectBank"
              placeholder="select proof type"
            >
            </model-select>
            <input
              v-if="select_proof_type == 'Other'"
              v-model="select_proof_type_other"
              type="text"
              class="input-text mt-2"
              name="select-bank"
              id="select-bank"
              placeholder="Explain..."
            />
          </div>
          <div v-if="select_proof_type == 'BANK'">
            <div class="step bank-number d-flex flex-column">
              <label
                for="bank-number"
                class="txt-bold"
                v-if="getLang == 'indo'"
              >
                Rekening Bank
              </label>
              <label for="bank-number" class="txt-bold" v-if="getLang == 'uk'">
                Bank Account Number
              </label>
              <input
                v-model="input_bank_num"
                type="text"
                class="input-text"
                name="bank-number"
                id="bank-number"
                placeholder="123123"
              />
            </div>
            <div class="step account-name d-flex flex-column">
              <label
                for="account-name"
                class="txt-bold"
                v-if="getLang == 'indo'"
              >
                Nama Pemilik Rekening
              </label>
              <label for="account-name" class="txt-bold" v-if="getLang == 'uk'">
                Bank Account Name
              </label>
              <input
                v-model="input_bank_name"
                type="text"
                class="input-text"
                name="account-name"
                id="account-name"
                placeholder="Jimmy"
                required="false"
              />
            </div>
          </div>
          <div class="step account-name d-flex flex-column mt-2">
            <label for="account-name" class="txt-bold" v-if="getLang == 'indo'">
              2. Catatan
            </label>
            <label for="account-name" class="txt-bold" v-if="getLang == 'uk'">
              2. Notes (optional)
            </label>
            <input
              v-model="input_proof_notes"
              type="text"
              class="input-text"
              name="account-name"
              id="account-name"
              placeholder="..."
              required="false"
            />
          </div>
          <div class="step upload-file d-flex flex-column mt-2">
            <label for="upload-file" class="txt-bold" v-if="getLang == 'indo'">
              3. Unggah Bukti Anda
            </label>
            <label for="upload-file" class="txt-bold" v-if="getLang == 'uk'">
              3. Upload Your File
            </label>
            <UploadImages
              :max="1"
              maxError="Unggah file maksimal 1 gambar"
              @changed="handleImages"
              v-if="getLang == 'indo'"
            />
            <UploadImages
              :max="1"
              maxError="Maximal Upload file 1 gambar"
              @changed="handleImages"
              v-if="getLang == 'uk'"
              class="upload-images"
            />
            <p class="mt-2" v-if="getLang == 'indo'">
              Sediakan file dalam format JPG/JPEG/PNG, maksimum 10 MB.
            </p>
            <p class="mt-2" v-if="getLang == 'uk'">
              Provide files in JPG/JPEG/PNG format, 10 MB maximum.
            </p>
          </div>
          <div class="d-flex w-100">
            <button
              class="btn btn-blue txt-bold"
              @click="onSelectRequest"
              v-if="getLang == 'indo'"
            >
              Kirim
            </button>
            <button
              class="btn btn-blue txt-bold"
              @click="onSelectRequest"
              v-if="getLang == 'uk'"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { bank } from "./bank";
import { ModelSelect } from "vue-search-select";
import UploadImages from "vue-upload-drop-images";
import Notiflix from "notiflix";
const userData = JSON.parse(localStorage.getItem(`userData`));
const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;

export default {
  name: `verifyPoA`,
  components: {
    Navbar,
    UploadImages,
    ModelSelect,
  },
  data() {
    return {
      select_proof_type: ``,
      select_proof_type_other: ``,
      input_bank_num: ``,
      input_bank_name: ``,
      input_proof_notes: ``,
      input_file: null,
      bank: bank,
      proofOptions: [
        {
          value: `DRIVER_LICENSE`,
          text: `Driving License`,
        },
        {
          value: `TAX_RECEIPT`,
          text: `Property Tax Receipt`,
        },
        {
          value: `UTILITY_BILL`,
          text: `Utility Bill`,
        },
        {
          value: `BANK`,
          text: `Bank or Credit Card Statement`,
        },
        {
          value: `Other`,
          text: `Other`,
        },
      ],
      getLang: localStorage.getItem(`setLang`),
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    // let arr = [];
    // // const fetch_bank = () => {
    //   bank.map((item)=>{
    //     arr.push(
    //       {
    //       text: item.name,
    //       value: item.name,
    //     }
    //     )
    //   })
    // // }
    // this.bankOptions = arr;
    // console.log(arr)
  },
  created() {
    localStorage.setItem(`pageNow`, `VerifPoA`);
  },
  methods: {
    onSelectBank() {
      // console.log(`Lainnya`)
      // if(this.select_proof_type == "Lainnya"){
      // }
    },
    handleImages(files) {
      // console.log(files)
      this.input_file = files[0];
    },
    onFileSelected() {
      //   this.input_file = e.target.files[0];
      //  // console.log(e.target.files[0]);
      // console.log(alert(`uyyy`))
    },
    onSelectRequest() {
      var data = new FormData();
      data.append(`user_id`, userData.user.id ?? userData.user._id);
      data.append(`idNumber`, this.input_bank_num ?? null);
      data.append(`type`, this.select_proof_type);
      data.append(`file`, this.input_file);

      if (this.select_proof_type === `BANK`) data.append(`category`, `PAYMENT`);
      else data.append(`category`, `PROOF_ADDRESS`);

      data.append(
        `bank_name`,
        this.select_proof_type == `Other`
          ? `[Other] ${this.select_proof_type_other}`
          : this.select_proof_type
      );
      if (this.select_proof_type === `BANK`) {
        data.append(`bank_account_number`, this.input_bank_num);
        data.append(`bank_account_name`, this.input_bank_name);
      }

      data.append(`comment`, this.input_proof_notes);

      var config = {
        method: `post`,
        url: `${url}/verification`,
        headers: {
          "content-type": `application/json`,
          "x-app-token": token,
          "x-user-token": userData.token,
        },
        data: data,
      };
      if (
        this.getLang == `indo` &&
        (!this.input_file || !this.select_proof_type)
      ) {
        Swal.fire({
          icon: `question`,
          title: `Data Belum Lengkap!`,
          text: `Lengkapi data Anda untuk melakukan verifikasi!`,
          confirmButtonText: `Oke`,
        });
      } else if (
        this.getLang == `uk` &&
        (!this.input_file || !this.select_proof_type)
      ) {
        Swal.fire({
          icon: `question`,
          title: `Incomplete Data!`,
          text: `Complete your data to perform verification!`,
          confirmButtonText: `Oke`,
        });
      } else {
        if (this.getLang == `indo`) {
          Swal.fire({
            title: `Verifikasi Bukti Tinggal`,
            text: `Apakah data sudah benar?`,
            icon: `question`,
            showCancelButton: true,
            confirmButtonColor: `#3085d6`,
            cancelButtonColor: `#d33`,
            confirmButtonText: `Benar`,
          }).then((result) => {
            Notiflix.Loading.circle({
              className: "notiflix-loading",
              svgColor: "#c09a07",
            });
            if (result.isConfirmed) {
              axios(config)
                .then(function () {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: `success`,
                    title: `Anda Berhasil verifikasi bukti tinggal`,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  window.location.href = `/verify-pending`;
                  // console.log(JSON.stringify(response.data))
                })
                .catch(function (error) {
                  Notiflix.Loading.remove();
                  if (error.response.status == 500) {
                    Swal.fire({
                      icon: `error`,
                      title: `We are sorry...`,
                      text: error.response.data.message,
                    });
                  } else {
                    Swal.fire({
                      icon: `error`,
                      title: `We are sorry...`,
                      text: error.message,
                    });
                  }
                  console.error(error);
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Notiflix.Loading.remove();
            }
          });
        } else {
          Swal.fire({
            title: `Verify PoA`,
            text: `Is the data correct?`,
            icon: `question`,
            showCancelButton: true,
            confirmButtonColor: `#3085d6`,
            cancelButtonColor: `#d33`,
            confirmButtonText: `Yes`,
          }).then((result) => {
            Notiflix.Loading.circle({
              className: "notiflix-loading",
              svgColor: "#c09a07",
            });
            if (result.isConfirmed) {
              axios(config)
                .then(function () {
                  Notiflix.Loading.remove();
                  Swal.fire({
                    icon: `success`,
                    title: `You Successfully Verify PoA`,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  window.location.href = `/verify-pending`;
                  // console.log(JSON.stringify(response.data))
                })
                .catch(function (error) {
                  Notiflix.Loading.remove();
                  if (error.response.status == 500) {
                    Swal.fire({
                      icon: `error`,
                      title: `We are sorry...`,
                      text: error.response.data.message,
                    });
                  } else {
                    Swal.fire({
                      icon: `error`,
                      title: `We are sorry...`,
                      text: error.message,
                    });
                  }
                  console.error(error);
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Notiflix.Loading.remove();
            }
          });
        }
      }
    },
  },
};
</script>